import React, { useEffect } from "react";
import { Formik } from "formik";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { FormattedMessage, injectIntl } from "react-intl";
import { Checkbox, FormControlLabel, TextField } from "@material-ui/core";
import * as auth from "../../store/ducks/auth.duck";
import Button from "@material-ui/core/Button";

var firebase = require("firebase");
let onsybmitclicked = false;
function Registration(props) {
  const { intl } = props;

  useEffect(() => {
    firebase.auth().onAuthStateChanged((user) => {
      if (user && !onsybmitclicked) {
        props.history.push("/profile");
      }
    });
  }, []);

  return (
    <div className="kt-login__body" style={{ height: "calc(100vh - 160px)" }}>
      <div className="kt-login__form">
        <div className="kt-login__title">
          <h3>
            <FormattedMessage id="AUTH.REGISTER.TITLE" />
          </h3>
        </div>

        <div style={{ marginBottom: 10 }}>
          <Link to="auth/login"> 
          <Button style={{width: '100%',  padding: 20, backgroundColor: 'white'}} variant='contained'> Social Login </Button> 
          </Link>
        </div>  
        <p style={{textAlign: 'center', paddingTop: 15}}> OR </p>
            

        <Formik
          initialValues={{
            email: "",
            password: "",
            name: "",
            acceptTerms: false,
            confirmPassword: "",
          }}
          validate={(values) => {
            const errors = {};

            if (!values.email) {
              errors.email = intl.formatMessage({
                id: "AUTH.VALIDATION.REQUIRED_FIELD",
              });
            } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
              errors.email = intl.formatMessage({
                id: "AUTH.VALIDATION.INVALID_FIELD",
              });
            }

            if (!values.name) {
              errors.name = intl.formatMessage({
                id: "AUTH.VALIDATION.REQUIRED_FIELD",
              });
            }

            if (!values.password) {
              errors.password = intl.formatMessage({
                id: "AUTH.VALIDATION.REQUIRED_FIELD",
              });
            }

            if (!values.confirmPassword) {
              errors.confirmPassword = intl.formatMessage({
                id: "AUTH.VALIDATION.REQUIRED_FIELD",
              });
            } else if (values.password !== values.confirmPassword) {
              errors.confirmPassword = "Password and Confirm Password didn't match.";
            }

            if (!values.acceptTerms) {
              errors.acceptTerms = "Accept Terms";
            }

            return errors;
          }}
          onSubmit={(values, { setStatus, setSubmitting }) => {
            onsybmitclicked = true;
            firebase
              .auth()
              .createUserWithEmailAndPassword(values.email, values.password)
              .then(function() {
                var user = firebase.auth().currentUser;
                user
                  .updateProfile({
                    displayName: values.name,
                  })
                  .then(function() {
                    //creating score instance
                    let updates = {};
                    updates["score/" + user.uid] = { score: 0 };
                    firebase
                      .database()
                      .ref()
                      .update(updates);

                    setTimeout(() => {
                      props.history.push("/introduction");
                    }, 1000);
                  })
                  .catch(function(error) {});
              })
              .catch(function(error) {
                // eslint-disable-next-line
                var errorCode = error.code;
                // eslint-disable-next-line
                var errorMessage = error.message;
              });
          }}
        >
          {({ values, status, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
            <form onSubmit={handleSubmit} noValidate autoComplete="off">
              {status && (
                <div role="alert" className="alert alert-danger">
                  <div className="alert-text">{status}</div>
                </div>
              )}

              <div className="form-group mb-0">
                <TextField
                  label="Email"
                  margin="normal"
                  className="kt-width-full"
                  name="email"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.email}
                  helperText={touched.email && errors.email}
                  error={Boolean(touched.email && errors.email)}
                />
              </div>

              <div className="form-group mb-0">
                <TextField
                  label="Display Name"
                  margin="normal"
                  className="kt-width-full"
                  name="name"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.name}
                  helperText={touched.name && errors.name}
                  error={Boolean(touched.name && errors.name)}
                />
              </div>

              <div className="form-group mb-0">
                <TextField
                  type="password"
                  margin="normal"
                  label="Password"
                  className="kt-width-full"
                  name="password"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.password}
                  helperText={touched.password && errors.password}
                  error={Boolean(touched.password && errors.password)}
                />
              </div>

              <div className="form-group">
                <TextField
                  type="password"
                  margin="normal"
                  label="Confirm Password"
                  className="kt-width-full"
                  name="confirmPassword"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.confirmPassword}
                  helperText={touched.confirmPassword && errors.confirmPassword}
                  error={Boolean(touched.confirmPassword && errors.confirmPassword)}
                />
              </div>

              <div className="form-group mb-0">
                <FormControlLabel
                  label={
                    <>
                      I agree to the
                      <Link to="/terms" target="_blank" rel="noopener noreferrer">
                        Terms & Conditions
                      </Link>
                    </>
                  }
                  control={<Checkbox color="primary" name="acceptTerms" onBlur={handleBlur} onChange={handleChange} checked={values.acceptTerms} />}
                />
              </div>

              <div className="kt-login__actions">
                <Link to="/auth/forgot-password" className="kt-link kt-login__link-forgot">
                  <FormattedMessage id="AUTH.GENERAL.FORGOT_BUTTON" />
                </Link>

                <Link to="/auth">
                  <button type="button" className="btn btn-secondary btn-elevate kt-login__btn-secondary">
                    Back
                  </button>
                </Link>

                <button disabled={isSubmitting || !values.acceptTerms} className="btn btn-primary btn-elevate kt-login__btn-primary">
                  Submit
                </button>
              </div>
            </form>
          )}
        </Formik>
      </div>
    </div>
  );
}

export default injectIntl(connect(null, auth.actions)(Registration));
