/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,no-undef */
import React from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import { Nav, Tab } from "react-bootstrap";
import NotificationPannel from "./NotificationsPannel";
import { Link } from "react-router-dom";
import PublicSharpIcon from "@material-ui/icons/PublicSharp";
import HttpsSharpIcon from "@material-ui/icons/HttpsSharp";
import LockOpenRoundedIcon from "@material-ui/icons/LockOpenRounded";
import moment from "moment";
import BubbleChartIcon from "@material-ui/icons/BubbleChart";
import BookIcon from "@material-ui/icons/Book";
import NotificationsActiveIcon from "@material-ui/icons/NotificationsActive";
import RecentActorsIcon from "@material-ui/icons/RecentActors";

var firebase = require("firebase");

const perfectScrollbarOptions = {
  wheelSpeed: 2,
  wheelPropagation: false,
};

export default class QuickPanel extends React.Component {
  state = {
    selectedTab: "Notifications",
    latestActivities: {},
  };
  setTab = (_tabName) => {
    this.setState({ selectedTab: _tabName });
  };

  componentDidMount() {
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        var userID = user.uid;

        firebase
          .database()
          .ref("activities/" + userID)
          .on("value", (snapshot) => {
            let localLatestActivities = snapshot.val();
            if (localLatestActivities) {
              Object.keys(localLatestActivities)
                .reverse()
                .map(function(key, index) {
                  let currentType = localLatestActivities[key].type;
                  if (currentType === "pulse" && localLatestActivities[key].pulseId) {
                    firebase
                      .database()
                      .ref("/pulses/" + localLatestActivities[key].pulseId)
                      .once("value")
                      .then(function(snapshot) {
                        let pulseData = snapshot.val();
                        if (pulseData) {
                          localLatestActivities[key].active = pulseData.active;
                          localLatestActivities[key].remaining = pulseData.remaining;
                          localLatestActivities[key].value = pulseData.value;
                        }
                      });
                  }
                  if (currentType === "hive" && localLatestActivities[key].userID) {
                    firebase
                      .database()
                      .ref("/hiveCards/" + localLatestActivities[key].hiveID + "/type")
                      .once("value")
                      .then(function(snapshot) {
                        let hiveType = snapshot.val();
                        if (hiveType) {
                          localLatestActivities[key].hiveType = hiveType;
                        }
                      });
                  }
                  return null;
                });
              this.setState({ latestActivities: localLatestActivities });
            }
          });
      }
    });
  }

  render() {
    const { latestActivities } = this.state;

    const latestActivityTab = (
      <div className="kt-notification">
        {Object.keys(latestActivities)
          .reverse()
          .map(function(key, index) {
            let currentType = latestActivities[key].type;
            let activityText = "";
            let iconType = "flaticon2-rocket kt-font-success";
            let linkTo = undefined;

            if (currentType === "like") {
              activityText = `You liked node #${key.substring(5, 10)}`;
              iconType = "flaticon-like kt-font-success";
              if (latestActivities[key].hiveID) {
                linkTo = `/hive/${latestActivities[key].hiveID}`;
              }
              if (latestActivities[key].nodeID) {
                linkTo = linkTo + "&id=" + latestActivities[key].nodeID;
              }
            } else if (currentType === "comment") {
              activityText = `You commented on #${key.substring(5, 10)}`;
              iconType = "flaticon2-chat kt-font-focus";
              if (latestActivities[key].hiveID) {
                linkTo = `/hive/${latestActivities[key].hiveID}`;
              }
              if (latestActivities[key].nodeID) {
                linkTo = linkTo + "&id=" + latestActivities[key].nodeID;
              }
            } else if (currentType === "email") {
              activityText = `Email Address Verified`;
              iconType = "flaticon-safe-shield-protection kt-font-success";
            }
            if (currentType === "like" || currentType === "comment" || currentType === "email") {
              return (
                <Link to={linkTo} className="kt-notification__item" key={`${key}-activity.`}>
                  <div className="kt-notification__item-icon">
                    <i className={iconType} />
                  </div>
                  <div className="kt-notification__item-details">
                    <div className="kt-notification__item-title">{activityText}</div>
                    <div className="kt-notification__item-time">{moment(latestActivities[key].timeStamp).fromNow()}</div>
                  </div>
                </Link>
              );
            } else {
              return null;
            }
          })}
      </div>
    );

    const NodesTab = (
      <div className="kt-notification">
        {Object.keys(latestActivities)
          .reverse()
          .map(function(key, index) {
            let currentType = latestActivities[key].type;
            let activityText = "";
            let iconType;
            let linkTo = "";

            if (currentType === "node") {
              activityText = `You created a new node`;
              iconType = "flaticon2-website kt-font-brand";
              if (latestActivities[key].hiveID) {
                linkTo = `/hive/${latestActivities[key].hiveID}`;
              }
              if (latestActivities[key].nodeID) {
                linkTo = linkTo + "&id=" + latestActivities[key].nodeID;
                activityText = activityText + " #" + latestActivities[key].nodeID.substring(5, 10);
              }
              return (
                <Link to={linkTo} className="kt-notification__item" key={`${key}-activity.`}>
                  <div className="kt-notification__item-icon">
                    <i className={iconType} />
                  </div>
                  <div className="kt-notification__item-details">
                    <div className="kt-notification__item-title">{activityText}</div>
                    <div className="kt-notification__item-time">{moment(latestActivities[key].timeStamp).fromNow()}</div>
                  </div>
                </Link>
              );
            } else {
              return null;
            }
          })}
      </div>
    );

    const HivesTab = (
      <div className="kt-notification">
        {Object.keys(latestActivities)
          .reverse()
          .map(function(key, index) {
            let currentType = latestActivities[key].type;
            let activityText = "";
            let linkTo = "";
            let hiveType = undefined;

            if (currentType === "hive") {
              activityText = `You created a new hive`;
              if (latestActivities[key].hiveID) {
                linkTo = `/hive/${latestActivities[key].hiveID}`;
                activityText = activityText + " #" + latestActivities[key].hiveID.substring(5, 10);
              }
              if (latestActivities[key].hiveType) {
                hiveType = latestActivities[key].hiveType;
              }

              return (
                <Link to={linkTo} className="kt-notification__item" key={`${key}-hive`}>
                  <div className="kt-notification__item-icon">
                    <span className="kt-widget4__icon">
                      {(() => {
                        if (hiveType === "Open") {
                          return <PublicSharpIcon color="secondary" />;
                        } else if (hiveType === "Team") {
                          return <LockOpenRoundedIcon color="primary" />;
                        } else if (hiveType === "Private") {
                          return <HttpsSharpIcon color="primary" />;
                        }
                      })()}
                    </span>
                  </div>
                  <div className="kt-notification__item-details">
                    <div className="kt-notification__item-title">{activityText}</div>
                    <div className="kt-notification__item-time">{moment(latestActivities[key].timeStamp).fromNow()}</div>
                  </div>
                </Link>
              );
            } else {
              return null;
            }
          })}
      </div>
    );

    const PulsesTab = (
      <div className="kt-notification">
        {Object.keys(latestActivities)
          .reverse()
          .map(function(key, index) {
            let currentType = latestActivities[key].type;
            let activityText = "";
            let iconType;
            let linkTo = "";

            if (currentType === "pulse") {
              activityText = `You created a new pulse`;
              if (latestActivities[key].hiveID) {
                linkTo = `/hive/${latestActivities[key].hiveID}`;
              }
              if (latestActivities[key].nodeID) {
                linkTo = linkTo + "&id=" + latestActivities[key].nodeID;
              }
              return (
                <Link to={linkTo} className="kt-notification__item" key={`${key}-pulse`}>
                  <div className="kt-notification__item-icon">
                    <span className="kt-widget4__icon">
                      <i className={"flaticon-stopwatch kt-font-success"} />
                    </span>
                  </div>
                  <div className="kt-notification__item-details">
                    <div className="kt-notification__item-title">{activityText}</div>
                    <div className="kt-notification__item-time">{moment(latestActivities[key].timeStamp).fromNow()}</div>
                  </div>
                </Link>
              );
            } else {
              return null;
            }
          })}
      </div>
    );

    const ReadTab = (
      <div className="kt-notification">
        {Object.keys(latestActivities)
          .reverse()
          .map(function(key, index) {
            let currentType = latestActivities[key].type;
            let activityText = "";
            let iconType;
            let linkTo = "";

            if (currentType === "read") {
              activityText = `You read node #`;
              iconType = "flaticon2-open-text-book kt-font-success";

              if (latestActivities[key].hiveID) {
                linkTo = `/hive/${latestActivities[key].hiveID}`;
              }
              if (latestActivities[key].nodeID) {
                linkTo = linkTo + "&id=" + latestActivities[key].nodeID;
              }
              return (
                <Link to={linkTo} className="kt-notification__item" key={`${key}-read`}>
                  <div className="kt-notification__item-icon">
                    <span className="kt-widget4__icon">
                      <i className={iconType} />
                    </span>
                  </div>
                  <div className="kt-notification__item-details">
                    <div className="kt-notification__item-title">{activityText + latestActivities[key].nodeID.substring(5, 10)}</div>
                    <div className="kt-notification__item-time">{moment(latestActivities[key].timeStamp).fromNow()}</div>
                  </div>
                </Link>
              );
            } else {
              return null;
            }
          })}
      </div>
    );

    return (
      <div id="kt_quick_panel" className="kt-quick-panel">
        <a href="#" className="kt-quick-panel__close" id="kt_quick_panel_close_btn">
          <i className="flaticon2-delete" />
        </a>
        <Tab.Container defaultActiveKey={this.state.selectedTab}>
          <div className="kt-quick-panel__nav">
            <Nav onSelect={this.setTab} className="nav nav-tabs nav-tabs-line nav-tabs-bold nav-tabs-line-3x nav-tabs-line-brand kt-notification-item-padding-x">
              <Nav.Item className="nav-item">
                <Nav.Link eventKey="Notifications" className="nav-link show">
                  <NotificationsActiveIcon style={{ width: 38 }} />
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="Likes" className="nav-link show">
                  <RecentActorsIcon style={{ width: 38 }} />
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="Read" className="nav-link show">
                  <BookIcon style={{ width: 38 }} />
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="Pulse" className="nav-link show">
                  <BubbleChartIcon style={{ width: 38 }} />
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="Nodes" className="nav-link show">
                  Nodes
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="Hives" className="nav-link show">
                  Hives
                </Nav.Link>
              </Nav.Item>
            </Nav>
          </div>
          <div>
            <Tab.Content>
              <Tab.Pane eventKey="Notifications">
                <PerfectScrollbar
                  options={perfectScrollbarOptions}
                  style={{
                    maxHeight: "calc(100vh - 100px)",
                    position: "relative",
                  }}
                >
                  <NotificationPannel />
                </PerfectScrollbar>
              </Tab.Pane>
              <Tab.Pane eventKey="Likes">
                <PerfectScrollbar
                  options={perfectScrollbarOptions}
                  style={{
                    maxHeight: "calc(100vh - 100px)",
                    position: "relative",
                  }}
                >
                  {latestActivityTab}
                </PerfectScrollbar>
              </Tab.Pane>
              <Tab.Pane eventKey="Nodes">
                <PerfectScrollbar
                  options={perfectScrollbarOptions}
                  style={{
                    maxHeight: "calc(100vh - 100px)",
                    position: "relative",
                  }}
                >
                  {NodesTab}
                </PerfectScrollbar>
              </Tab.Pane>
              <Tab.Pane eventKey="Read">
                <PerfectScrollbar
                  options={perfectScrollbarOptions}
                  style={{
                    maxHeight: "calc(100vh - 100px)",
                    position: "relative",
                  }}
                >
                  {ReadTab}
                </PerfectScrollbar>
              </Tab.Pane>
              <Tab.Pane eventKey="Pulse">
                <PerfectScrollbar
                  options={perfectScrollbarOptions}
                  style={{
                    maxHeight: "calc(100vh - 100px)",
                    position: "relative",
                  }}
                >
                  {PulsesTab}
                </PerfectScrollbar>
              </Tab.Pane>
              <Tab.Pane eventKey="Hives">
                <PerfectScrollbar
                  options={perfectScrollbarOptions}
                  style={{
                    maxHeight: "calc(100vh - 100px)",
                    position: "relative",
                  }}
                >
                  {HivesTab}
                </PerfectScrollbar>
              </Tab.Pane>
            </Tab.Content>
          </div>
        </Tab.Container>
      </div>
    );
  }
}
