import React, { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import Fade from "@material-ui/core/Fade";
import LeftCard from "./LeftCard.js";
import RightCard from "./RightCard.js";
import { makeStyles } from "@material-ui/core/styles";
import Skeleton from "@material-ui/lab/Skeleton";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";

const useStyles = makeStyles((theme) => ({
  gridCon: {
    [theme.breakpoints.down("md")]: {
      maxHeight: "none !important",
    },
  },
}));

function CardGrid(props) {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));

  const classes = useStyles();

  const [display, setdisplay] = useState("");
  const [user, setuser] = useState(props.user);

  const handleDisplay = (type) => {
    let { user } = props;
    if (user) {
      setdisplay(type);
    } else {
      props.handleLoginPopup(true);
    }
  };

  useEffect(() => {
    if (matches === true) {
      setdisplay("");
    } else {
      setdisplay("comments");
    }
  }, [matches]);
  if (props.loadingInHome === true) {
    return (
      <div
        style={{
          marginBottom: 2,
          marginTop: 0,
          maxHeight: 253,
        }}
      >
        <Fade in={true}>
          <Grid container spacing={0}>
            <Grid item xs={12}>
              <div style={{ backgroundColor: "#FFFFFF" }}>
                <div
                  style={{
                    maxWidth: "100%",
                    height: 253,
                  }}
                >
                  <CardActionArea>
                    <Skeleton animation="wave" variant="rect" style={{ height: 142, maxWidth: "100%" }} />
                    <CardContent>
                      <React.Fragment>
                        <Skeleton animation="wave" height={20} style={{ marginBottom: 6 }} />
                        <Skeleton animation="wave" height={12} width="80%" />
                        <Skeleton animation="wave" height={12} width="80%" />
                        <Skeleton animation="wave" height={12} width="80%" />
                      </React.Fragment>
                    </CardContent>
                  </CardActionArea>
                </div>
              </div>
            </Grid>
          </Grid>
        </Fade>
      </div> 
    );
  } else {
    let margintop = props.explore ? 0 : 3;
    if (props.index === 0) {
      margintop = 0;
    }
    return (
      <div>
        <div
          style={{
            marginBottom: 2,
            marginTop: margintop,
            backgroundColor: "#FFFFFF",
            gridAutoRows: "1fr",
            maxHeight: props.explore ? 253 : 313,
          }}
          className={classes.gridCon}
        >
          <Fade in={true}>
            <Grid container spacing={0}>
              <Grid item xs={12} lg={props.explore ? 12 : 5}>
                <div style={{ backgroundColor: "#F5F5F7" }}>
                  <LeftCard
                    liked={props.liked}
                    explore={props.explore}
                    title={props.title}
                    description={props.description}
                    image={props.image}
                    link={props.link}
                    handleLoginPopup={props.handleLoginPopup}
                    handleDisplay={handleDisplay}
                    display={display}
                    user={props.user}
                    hiveID={props.hiveID}
                    hiveNodes={props.hiveNodes}
                    hdPrivateKey={props.hdPrivateKey}
                    hdPublicKey={props.hdPublicKey}
                    id={props.id}
                    loading={props.loading}
                    likeClicked={props.likeClicked}
                    clickedStatus={props.clickedStatus}
                    canModerate={props.canModerate}
                    moderateStatus={props.moderateStatus}
                    archived={props.archived}
                    checkAvalibilityOfNewTxoset={props.checkAvalibilityOfNewTxoset}
                  />
                </div>
              </Grid>
              {!props.explore && (
                <Grid item xs={12} lg={7}>
                  <RightCard
                    user={props.user}
                    image={props.image}
                    id={props.id}
                    comments={props.comments}
                    handleLoginPopup={props.handleLoginPopup}
                    handleDisplay={handleDisplay}
                    display={display}
                    link={props.link}
                    nodeComment={props.nodeComment}
                    hiveID={props.hiveID}
                    hdPrivateKey={props.hdPrivateKey}
                    hdPublicKey={props.hdPublicKey}
                    nodeCreatorUserID={props.nodeCreatorUserID}
                    hiveCreatorID={props.hiveCreatorID}
                    canModerate={props.canModerate}
                    archived={props.archived}
                  />
                </Grid>
              )}
            </Grid>
          </Fade>
        </div>
      </div>
    );
  }
}

export default CardGrid;
