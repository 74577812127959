/**
 * Create React App entry point. This and `public/index.html` files can not be
 * changed or moved.
 */
import React from "react";
import ReactDOM from "react-dom";
import store, { persistor } from "./app/store/store";
import App from './App';
import "./index.scss"; // Standard version
// import "./sass/style.react.rtl.css"; // RTL version
// IE 11 polyfills
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
// Fonts
import "socicon/css/socicon.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "./_metronic/_assets/plugins/carnas/stylesheet.css"
import "./_metronic/_assets/plugins/line-awesome/css/line-awesome.css";
import "./_metronic/_assets/plugins/flaticon/flaticon.css";
import "./_metronic/_assets/plugins/flaticon2/flaticon.css";
import "./_metronic/_assets/sass/pages/explore/explore.scss";

var firebase = require('firebase/app');
require('firebase/auth');
require('firebase/database');

const { PUBLIC_URL } = process.env;

var config = {
    apiKey: "AIzaSyCiSQJwksuuGNMPq3GjkY_ZM1MTBsDzZrk",
    authDomain: "hivedb-cdbf7.firebaseapp.com",
    databaseURL: "https://hivedb-cdbf7.firebaseio.com",
    projectId: "hivedb-cdbf7",
    storageBucket: "hivedb-cdbf7.appspot.com",
    messagingSenderId: "882176606224",
    appId: "1:882176606224:web:1d8167f258eb2e92680a95",
    measurementId: "G-BR9BQ9KP55"
};
firebase.initializeApp(config);

//hide warns #only for development
console.warn = console.error = () => {};

ReactDOM.render(
    <App
      store={store}
      persistor={persistor}
      basename={PUBLIC_URL}
    />,
  document.getElementById("root")
);
