import React from "react";
import energy from '../../../../_metronic/layout/assets/Logo/energy.png'

class ImgSlider3 extends React.Component {
  render() {
    return (
        <div className="img-slider-container">
            <div>
              <img src={energy}  alt="John" Doe className="rounded-circle"/>
            </div>
            <h3><b>Configuration</b></h3>
            <h6>Now ou have to create a destination and create the reasources and use it using our own methods</h6>
            <div  className='content'>
                <h6><b>What do I Need?</b></h6>
                <h6>List of entite that you might need your wharehouse</h6>
            </div>
        </div>
    )
  }
}

export default ImgSlider3;