import React from "react";

export default function WalletStats(props) {
  return (
    <div className="kt-widget1">
      {/* <div className="kt-widget1__item ng-star-inserted">
        <div className="kt-widget1__info">
          <h3 className="kt-widget1__title">Total Income</h3>
        </div>
        <span className="kt-widget1__number kt-font-brand">${props.totalIncome}</span>
      </div> */}

      <div className="kt-widget1__item ng-star-inserted">
        <div className="kt-widget1__info">
          <h3 className="kt-widget1__title">Nodes</h3>
        </div>
        <span className="kt-widget1__number kt-font-brand">{props.userNodesLength}</span>
      </div>

      <div className="kt-widget1__item ng-star-inserted">
        <div className="kt-widget1__info">
          <h3 className="kt-widget1__title">Likes</h3>
        </div>
        <span className="kt-widget1__number kt-font-brand">{props.userLikesLength}</span>
      </div>

      <div className="kt-widget1__item ng-star-inserted">
        <div className="kt-widget1__info">
          <h3 className="kt-widget1__title">Recent Transactions</h3>
          <span className="kt-widget1__desc"></span>
        </div>

        <span className="kt-widget1__number kt-font-danger">{props.nTransactions}</span>
      </div>

      <div className="kt-widget1__item ng-star-inserted">
        <div className="kt-widget1__info">
          <h3 className="kt-widget1__title">Wallet Balance</h3>
        </div>
        <span className="kt-widget1__number kt-font-success">{((props.balance * props.bsvPrice) / 100000000).toFixed(2)}$</span>
      </div>
    </div>
  );
}
