import React from "react";
import "../../../../_metronic/_assets/sass/pages/introduction/introduction.scss"
import energy from '../../../../_metronic/layout/assets/Logo/energy.png'

class ImgSlider1 extends React.Component {
  render() {
    return (
        <div className='img-slider-container'>
          <div>
            <img src={energy}  alt="John" Doe className="rounded-circle"/>
          </div>
          <h3><b>Pick a reasources</b></h3>
            <h6>Now ou have to create a destination and create the reasources and use it using our own methods</h6>
        </div>
    )
  }
}

export default ImgSlider1;
