import React from "react";
import { withRouter } from "react-router-dom";

import GDPR from "../../partials/layout/GDPR";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import HiveCard from "./Hive/HiveCards.js";
import LoginPopup from "../auth/LoginPopup.js";
import ExploreGrid from "./Hive/ExploreGrid.js";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Divider from "@material-ui/core/Divider";
import CardGrid from "./Hive/CardGrid/CardGrid";
import { TitleComponent } from "../../partials/content/helmetComponent";

var firebase = require("firebase");

class Explore extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      tabValue: 1,
      hiveTitle: "-",
      hiveImage: "-",
      hiveDescription: "-",
      hiveCategory: "-",
      hiveCards: [],
      hiveNodes: [],
      categoryFilterArray: [],
      user: "",
      openLoginPopup: false,
      filterTag: "",
    };
  }

  componentDidMount() {
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        this.setState({ user });
      }
    });

    firebase
      .database()
      .ref("hives")
      .once("value")
      .then((snapshot) => {
        var hiveCards = [];
        snapshot.val() &&
          Object.keys(snapshot.val()).forEach((key) => {
            hiveCards.push({
              description: snapshot.val()[key].description,
              imageURL: snapshot.val()[key].imageURL,
              title: snapshot.val()[key].title,
              userID: snapshot.val()[key].userID,
              category: snapshot.val()[key].category,
              id: key,
              nLikes: snapshot.val()[key].nLikes,
              nPulses: snapshot.val()[key].nPulses,
              nNodes: snapshot.val()[key].nNodes,
              nOnchained: snapshot.val()[key].nOnchained,
              nPopularity: snapshot.val()[key].nPopularity,
              timeStamp: snapshot.val()[key].timeStamp,
            });
          });
        hiveCards.sort(function(a, b) {
          return b.nLikes + b.nNodes - (a.nLikes + a.nNodes);
        });
        this.setState({
          hiveCards,
          categoryFilterArray: hiveCards,
          loading: false,
        });
      });
  }

  handleLoginPopup = (state) => {
    this.setState({ openLoginPopup: state });
  };

  handleCreateNewHive = () => {
    let { user } = this.state;
    if (user) {
      this.props.history.push("/create");
    } else {
      this.handleLoginPopup(true);
    }
  };

  categorySelect = (e) => {
    const { hiveCards } = this.state;
    const categoryFilterName = e.target.textContent;
    if (categoryFilterName === this.state.filterTag) {
      this.setState({
        categoryFilterArray: hiveCards,
        filterTag: "",
      });
    } else {
      const categoryFilterResult = hiveCards.filter((item) => item.category === categoryFilterName);

      this.setState({
        categoryFilterArray: categoryFilterResult,
        filterTag: categoryFilterName,
      });
    }
  };

  sortHiveCardList = (param) => {
    const { hiveCards } = this.state;
    if (param === "Latest") {
      const sortedHiveLatest = hiveCards.sort(function(a, b) {
        return b.timeStamp - a.timeStamp;
      });

      this.setState({
        categoryFilterArray: sortedHiveLatest,
      });
    } else {
      const sortedHivePopular = hiveCards.sort(function(a, b) {
        return b.nLikes + b.nNodes - (a.nLikes + a.nNodes);
      });

      this.setState({
        categoryFilterArray: sortedHivePopular,
        loading: false,
      });
    }
  };

  render() {
    const { categoryFilterArray } = this.state;
    const hiveCardsLoading = [...Array(6)].map((entry, index) => {
      return (
        <Grid container direction="row" style={{ marginTop: "0px" }} key={"loadingCard_" + index}>
          <Grid
            item
            xs={12}
            sm={7}
            lg={8}
            style={{
              marginTop: "0px",
              marginBottom: "1px",
              paddingRight: "3px",
              height: "254px",
              overflow: "hidden",
            }}
          >
            <HiveCard loading={true} />
          </Grid>
          <Grid
            item
            xs={12}
            sm={5}
            lg={4}
            style={{
              height: "254px",
              marginBottom: "1px",
              marginTop: "0px",
              overflow: "hidden",
            }}
          >
            <Grid container direction="row" spacing={0} style={{ height: "100%" }}>
              <Grid item xs={10}>
                <CardGrid loadingInHome={true} />
              </Grid>
              <Grid item xs={2} style={{ background: "#00284c", height: "100%" }} />
            </Grid>
          </Grid>
        </Grid>
      );
    });
    console.log("categoryFilterArray", categoryFilterArray);
    const hiveCardsActive = categoryFilterArray.map((entry, index) => (
      entry.nNodes > 2 ? 
      <ExploreGrid
        data={categoryFilterArray}
        title={entry.title}
        nLikes={entry.nLikes}
        nNodes={entry.nNodes}
        nOnchained={entry.nOnchained}
        nPulses={entry.nPulses}
        loading={false}
        keyIndex={index}
        key={index}
        image={entry.imageURL}
        description={entry.description}
        hiveID={entry.id}
      /> : null 
    ));

    const showHiveGrid = this.state.loading ? hiveCardsLoading : hiveCardsActive;

    return (
      <div style={{ margin: "auto", paddingTop: 3 }}>
        <TitleComponent title="Explore Hives" />

        <div style={{ margin: "auto" }}>
          <Grid container spacing={0} style={{ maxWidth: 1380, margin: "auto" }}>
            <Grid item xs={12} md={2} style={{ paddingRight: 5 }}>
              <div
                style={{
                  height: "100%",
                  width: "100%",
                  backgroundColor: "#1F1E2E",
                  margin: 2,
                  marginTop: 0,
                  padding: 10,
                  position: "relative",
                }}
              >
                <p style={{ color: "#545454" }}> Ranked by</p>
                <ButtonGroup style={{ padding: 0, width: "100%" }} variant="text" color="primary" aria-label="outlined secondary button group">
                  <Button onClick={() => this.sortHiveCardList("Latest")} style={{ width: "50%", padding: 0 }}>
                    Last Update
                  </Button>
                  <Button onClick={() => this.sortHiveCardList("popularity")} style={{ paddingLeft: 15 }}>
                    Popularity
                  </Button>
                </ButtonGroup>
                <Divider light={true} variant="middle" />

                <p style={{ paddingTop: 15, color: "#545454" }}> Category </p>
                <div style={{ marginTop: 0, marginBottom: 5 }}>
                  <Grid container spacing={0}>
                    <grid item xs={4}>
                      <Button
                        style={{
                          float: "left",
                          backgroundColor: this.state.filterTag === "Politics" ? "rgba(255,255,255,0.1)" : null,
                        }}
                        fullWidth={true}
                        color="primary"
                        label="Politics"
                        onClick={(e) => this.categorySelect(e)}
                      >
                        Politics
                      </Button>
                    </grid>
                    <grid item xs={4}>
                      <Button
                        style={{
                          float: "left",
                          backgroundColor: this.state.filterTag === "Crypto" ? "rgba(255,255,255,0.1)" : null,
                        }}
                        fullWidth={true}
                        color="primary"
                        label="Crypto"
                        onClick={(e) => this.categorySelect(e)}
                      >
                        Crypto
                      </Button>
                    </grid>
                    <grid item xs={4}>
                      <Button
                        style={{
                          float: "left",
                          backgroundColor: this.state.filterTag === "Sports" ? "rgba(255,255,255,0.1)" : null,
                        }}
                        fullWidth={true}
                        color="primary"
                        label="Sports"
                        onClick={(e) => this.categorySelect(e)}
                      >
                        Sports
                      </Button>
                    </grid>
                    <grid item xs={4}>
                      <Button
                        style={{
                          float: "left",
                          backgroundColor: this.state.filterTag === "Business" ? "rgba(255,255,255,0.1)" : null,
                        }}
                        fullWidth={true}
                        color="primary"
                        label="Business"
                        onClick={(e) => this.categorySelect(e)}
                      >
                        Business
                      </Button>
                    </grid>
                    <grid item xs={4}>
                      <Button
                        style={{
                          float: "left",
                          backgroundColor: this.state.filterTag === "Health" ? "rgba(255,255,255,0.1)" : null,
                        }}
                        fullWidth={true}
                        color="primary"
                        label="Health"
                        onClick={(e) => this.categorySelect(e)}
                      >
                        Health
                      </Button>
                    </grid>
                    <grid item xs={4}>
                      <Button
                        style={{
                          float: "left",
                          backgroundColor: this.state.filterTag === "Tech" ? "rgba(255,255,255,0.1)" : null,
                        }}
                        fullWidth={true}
                        color="primary"
                        label="Tech"
                        onClick={(e) => this.categorySelect(e)}
                      >
                        Tech
                      </Button>
                    </grid>
                  </Grid>
                </div>
                <Divider light={true} variant="middle" />
                <Button
                  style={{
                    height: 48,
                    width: "100%",
                    paddingLeft: 25,
                    paddingRight: 25,
                    color: "#fff",
                  }}
                  onClick={this.handleCreateNewHive}
                >
                  Create New Hive
                </Button>
                <img className="blogo" src={"https://developer.chrome.com/webstore/images/ChromeWebStore_BadgeWBorder_v2_496x150.png"} alt="" />
                
                <GDPR user={this.state.user} />
              </div>
            </Grid>
            <Grid
              item
              xs={12}
              md={10}
              lg={10}
              style={
                {
                  //  paddingLeft: "5px"
                }
              }
            >
              <LoginPopup openLoginPopup={this.state.openLoginPopup} setLoginPopup={this.handleLoginPopup} goTo={"/create"} />
              {showHiveGrid}
            </Grid>
          </Grid>
        </div>
      </div>
    );
  }
}

export default withRouter(Explore);
