import React from "react";
import { Grid } from "@material-ui/core";
import { toAbsoluteUrl } from "../../../../_metronic/utils/utils";
import "./about.css";
import Button from "@material-ui/core/Button";
import { Link } from "react-router-dom";

const scrollToRef = (ref) =>
  window.scrollTo({
    top: ref.current.offsetTop,
    left: 0,
    behavior: "smooth",
  });

export default function About() {
  const myRef = React.useRef(null);
  const executeScroll = () => scrollToRef(myRef);

  return (
    <div>
      <div style={{ margin: "auto", backgroundColor: "#111021" }}>
        <div style={{ maxWidth: 1400, margin: "auto" }}>
          <Grid container direction="row" justify="center" style={{ height: "95vh", paddingTop: 80, paddingLeft: 15 }}>
            <Grid item sm={5} style={{ color: "#FEFEFE", maxWidth: 500, paddingTop: 140 }}>
              <h1 style={{ fontSize: 35, fontWeight: 700, color: "#E2A2A5", marginBottom: 20 }}>Escaping The Bubble</h1>

              <p style={{ fontSize: 20 }}>
                Media content is scattered across the web with few clear connections. Inspired by nature, Raspora is a decentralized platform that seeks to interrelate many of
                these lost dots. Raspora allows us to find, share, and connect what really matters to us.
              </p>
              <Button
                variant="contained"
                style={{
                  color: "white",
                  backgroundColor: "#314A73",
                  marginTop: 12,
                  fontSize: 13,
                  padding: 8,
                }}
                onClick={executeScroll}
              >
                Learn More
              </Button>
            </Grid>
            <Grid item sm={7}>
              <video autoPlay loop muted style={{ backgroundColor: "#111021", padding: 30, width: "100%" }}>
                <source src={toAbsoluteUrl("/media/images/video.m4v")} type="video/mp4" />
              </video>
            </Grid>
          </Grid>
        </div>
      </div>
      <div style={{ margin: "auto" }} className="aboutSecond">
        <div style={{ maxWidth: 1400, margin: "auto" }}>
          <Grid container direction="row" justify="center" spacing={5} style={{ paddingTop: 80, paddingBottom: 80, paddingLeft: 5 }}>
            <Grid item sm={6}>
              <img style={{ paddingTop: 10, marginLeft: -20, width: "100%" }} src={toAbsoluteUrl("/media/images/vr_guy.jpg")} alt="" />
            </Grid>
            <Grid item sm={6} style={{ color: "#FEFEFE", maxWidth: 500, paddingTop: 30 }}>
              <div ref={myRef} style={{ paddingTop: 30 }}>
                <h1 style={{ fontSize: 25, fontWeight: 500, color: "#E2A2A5", marginBottom: 20 }}>The Problem</h1>

                <p style={{ fontSize: 16 }}>
                  In a world of social divide and rapid cultural shifts, it is more important than ever to get access to connected but unbiased information. Online media often
                  entraps us in confirmation bubbles without carefully analysing all perspectives of an issue.
                  <br />
                  <br />
                  “Personalization filters serve a kind of invisible autopropaganda, indoctrinating us with our own ideas, amplifying our desire for things that are familiar in
                  leaving us oblivious to the dangers lurking in the dark territory of the unknown.”{" "}
                  <span style={{ color: "grey", fontSize: 12 }}>
                    <br /> ― Eli Pariser, The Filter Bubble: What the Internet is Hiding From You{" "}
                  </span>
                  <br />
                  <br />
                  With Raspora, we try to break the familiar by economically incentivizing discussing all perspectives of each idea.
                </p>
              </div>
            </Grid>
            <Grid item sm={6} style={{ color: "#FEFEFE", paddingTop: 120, maxWidth: 500 }}>
              <h1 style={{ fontSize: 25, fontWeight: 500, color: "#E2A2A5", marginBottom: 20 }}>Contribute</h1>

              <p style={{ fontSize: 16 }}>
                Since our daily news consumption is often unrelated, we lose depth and detail in our understanding of these topics. With Raspora, we not only make news
                interconnected again, but we create entropy, and (thus) knowledge. <br /> <br /> While we embrace connections, we also value specificity. Everyone can create and
                fund a new Hive with the topic and description of one's choosing. Therefore we create local spaces for local connections. Ever wanted to gather all information
                about a specific movie or book? Do it! <br /> <br /> With Hive, we therefore collaboratively create networks of information that might significantly benefit those
                investigating the topics more deeply, compared to the endless outside perspectives.
              </p>
            </Grid>
            <Grid item sm={6}>
              <img style={{ he: "50%", paddingTop: 10 }} src={toAbsoluteUrl("/media/images/blocks_ltr.svg")} alt="blocks" />
            </Grid>
          </Grid>
        </div>
      </div>

      <div style={{ margin: "auto" }} className="aboutThird">
        <div style={{ maxWidth: 1400, margin: "auto" }}>
          <Grid container direction="row" justify="center" style={{ paddingTop: 80, paddingLeft: 5 }}>
            <Grid item sm={6}>
              <img style={{ width: "70%", paddingTop: 10 }} src={toAbsoluteUrl("/media/images/nodes.svg")} alt="nodepic" />
            </Grid>
            <Grid item sm={6} style={{ color: "#FEFEFE", maxWidth: 500, paddingTop: 40 }}>
              <h1 style={{ fontSize: 25, fontWeight: 500, color: "#E2A2A5", marginBottom: 20 }}>The Hive Network</h1>

              <p style={{ fontSize: 16 }}>
                Due to the nature of microtransactions on our platform, each user has their own wallet on Raspora. The Raspora wallet keys are created client-side and stored on the
                security hardened Google Firebase Realtime Database.
                <br />
                <br />
                We have ensured a seamless integration and sense of accomplishment with every earned Satoshi. To facilitate the growth of your own Hive, or connections to your
                content of interest within the Hive, you can (in later versions) automatically distribute funds to those creating content around you
                <br />
                <br />
                Raspora's Hive system rewards you for helping others and provides you with the funds to direct the content discovery in the direction of your liking.
              </p>
            </Grid>
            <Grid item sm={6} style={{ color: "#FEFEFE", maxWidth: 500, paddingTop: 60 }}>
              <h1 style={{ fontSize: 25, fontWeight: 500, color: "#E2A2A5", marginBottom: 20 }}>Observe</h1>

              <p style={{ fontSize: 16 }}>
                Our aim is to facilitate hundreds of Hives with millions of nodes fetched by various Apps and Platforms that all collaboratively create an ever-expanding network of
                context and knowledge.
                <br /> <br />
                While traditional communities form and fade, the immutable Blockchain ensures that our Hive communities become timeless. No matter where and when you decide to
                contribute content to the Hive meshes, your contribution will be seen and valued.
              </p>
            </Grid>
            <Grid item sm={6} style={{ height: "60vh" }}>
              <img style={{ height: "60vh", paddingTop: 10 }} src={toAbsoluteUrl("/media/images/owl.png")} alt="owl" />
            </Grid>
          </Grid>
        </div>
      </div>

      <div style={{ margin: "auto" }} className="aboutSixth">
        <div style={{ maxWidth: 1400, margin: "auto" }}>
          <Grid container direction="row" justify="center" style={{ height: "60vh", paddingLeft: 5 }}>
            <Grid item sm={4}>
              <img style={{ height: "60vh", paddingTop: 10 }} src={toAbsoluteUrl("/media/images/chain.svg")} alt="chain" />
            </Grid>
            <Grid item sm={8} style={{ color: "#FEFEFE", maxWidth: 500, paddingTop: 80 }}>
              <h1 style={{ fontSize: 25, fontWeight: 500, color: "#E2A2A5", marginBottom: 20 }}>Secured by the blockchain</h1>

              <p style={{ fontSize: 16 }}>
                The Hive protocol utilises the Bitcoin SV blockchain - the only network that scales and enables microtransactions. Due to the nature of microtransactions on our
                platform, each user has a personal wallet on Hive. The Hive wallet keys are created client-side and stored on a security hardened Realtime Database.
              </p>
              <p>If you are interested in the datails, you can find all blockchain protocol implementation details on <a target='blank' href="http://documentation.kohze.com">http://documentation.kohze.com</a>.</p>
            </Grid>
          </Grid>
        </div>
      </div>
      <div style={{ margin: "auto", textAlign: "center", paddingTop: 30, paddingBottom: 50 }} className="aboutSeventh">
        <div>
          <Link to="/auth/registration">
            <Button variant="contained" style={{ color: "white", backgroundColor: "#54cdd6", fontSize: 15, marginBottom: "-40%" }}>
              Join Raspora
            </Button>
          </Link>
        </div>
        <img style={{ height: 700, paddingTop: 10 }} src={toAbsoluteUrl("/media/images/city.jpg")} alt="" />
        <div
          style={{
            backgroundColor: "#00284C",
            margin: "auto",
            color: "rgba(255,255,255,0.3)",
            paddingTop: 40,
            fontSize: 16,
          }}
        >
          <p>
            Raspora is a Vaionex Corporation platform. <br />
            If you have any question contact us at raspora@vaionex.com. <br /> Terms and Services apply.{" "}
          </p>
        </div>
      </div>
    </div>
  );
}