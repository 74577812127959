import React from "react";

import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { Upload, message } from "antd";
import ImgCrop from "antd-img-crop";
import "antd/dist/antd.css"; // or 'antd/dist/antd.less'
import ChipInput from "material-ui-chip-input";
import { TitleComponent } from "../../partials/content/helmetComponent";

var firebase = require("firebase");

class HiveCreation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      hiveCards: [],
      hiveType: "",
      imageUploadLoading: false,
      imageFile: [],
      userUid: null,
      chipDefaultValue: [],
      hiveDescription: "",
      hiveCategory: "",
      hiveTitle: "",
    };
  }

  componentDidMount() {
    firebase.auth().onAuthStateChanged((user) => {
      if (!user) {
        this.props.history.push("/explore");
      } else {
        this.setState({ userUid: user.uid });
      }
    });
  }

  async createHive() {
    const { hiveTitle, hiveDescription, hiveCategory } = this.state;
    var title = hiveTitle !== "" ? hiveTitle : "-";
    var description = hiveDescription !== "" ? hiveDescription : "-";
    var category = hiveCategory !== "" ? hiveCategory : "-";
    var user = firebase.auth().currentUser;
    var userID = user.uid;
    let hdPublicKey = await firebase
      .database()
      .ref("KeyStore/" + userID + "/hdPublicKey")
      .once("value");
    if (!this.state.imageFile[0]) {
      message.error("Please Upload an Image!");
    } else {
      //upload image to firebase storage
      var newPostKey = firebase
        .database()
        .ref("hiveCards")
        .push().key;
      let that = this;
      let storageRef = firebase
        .app()
        .storage("gs://hivedb-cdbf8")
        .ref();
      let uploadTask = storageRef.child("hives_images/" + newPostKey);

      uploadTask
        .put(this.state.imageFile[0].originFileObj)
        .then(function(snapshot) {
          let modefiedURL = `https://firebasestorage.googleapis.com/v0/b/hivedb-cdbf8/o/hives_images%2F${newPostKey}_420x280?alt=media`;
          var hivesContent = {
            title: title,
            description: description,
            nLikes: 0,
            nPulses: 0,
            nNodes: 0,
            nOnchained: 0,
            nPopularity: 1,
            category: category,
            imageURL: modefiedURL,
            userID: userID,
            timeStamp: firebase.database.ServerValue.TIMESTAMP,
            type: that.state.hiveType,
          };

          var hiveCardContent = {
            title: title,
            description: description,
            imageURL: modefiedURL,
            userID: userID,
            timeStamp: firebase.database.ServerValue.TIMESTAMP,
            type: that.state.hiveType,
            node: {},
            nLikes: 0,
            nPulses: 0,
            nNodes: 0,
            nOnchained: 0,
            nPopularity: 1,
            category: category,
          };

          var contents = {};
          if (that.state.hiveType === "Open") {
            contents["hives/" + newPostKey] = hivesContent;
          }

          //upload intial topics
          let defaultTopics = [...that.state.chipDefaultValue];
          defaultTopics.unshift("Main");
          let defaultNodes = {};
          let mainHiveNodeID = "";
          defaultTopics.map((item, index) => {
            let newNodeKey = firebase
              .database()
              .ref("hiveCards/" + newPostKey + "/node")
              .push().key;
            if (index === 0) {
              mainHiveNodeID = newNodeKey;
            }
            defaultNodes[newNodeKey] = {
              title: item,
              description: "",
              image: "",
              link: "",
              hiveID: newPostKey,
              linkedNode: index === 0 ? "" : mainHiveNodeID,
              userID: userID,
              hdPublicKey: hdPublicKey.val() ? hdPublicKey.val() : "",
              timeStamp: firebase.database.ServerValue.TIMESTAMP,
              weight: 0.5,
              clicks: 0,
              isTopic: index === 0 ? "Main" : true,
            };

            return null;
          });

          hiveCardContent.node = defaultNodes;
          contents["hiveCards/" + newPostKey] = hiveCardContent;
          contents["KeyStore/" + that.state.userUid + "/hives/" + newPostKey] = hivesContent;
          contents["KeyStore/" + userID + "/nodes"] = defaultNodes;

          firebase
            .database()
            .ref()
            .update(contents);
          //updating score
          firebase
            .database()
            .ref("score/" + userID)
            .child("score")
            .transaction((score) => {
              return (score || 0) + 1;
            });
          setTimeout(() => {
            that.props.history.push("/hive/" + newPostKey);
          }, 1000);
        })
        .catch((err) => {
          console.log("error occured while uploading image", err);
        });
    }
  }

  dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };

  onChangeImage = ({ fileList: newFileList }) => {
    if (newFileList[0] && newFileList[0].size > 625000) {
      message.error("Image must smaller than 5MB!");
    } else {
      this.setState({ imageFile: newFileList });
    }
  };

  onPreview = async (file) => {
    let src = file.url;
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow.document.write(image.outerHTML);
  };

  render() {
    let openTab = (
      <Grid item lg={3} md={4} xs={12}>
        <Paper style={{ padding: 15, backgroundColor: "#1F1E2E", color: "#fff", zIndex: 150 }}>
          <img className="fadeIn" style={{ height: 300, maxWidth: '100%' }} alt="open" src={"/media/images/public.jpg"} />
          <h2> Open </h2>
          <h4 style={{ color: "#d1cec2" }}> Unlimited Contributors</h4>
          <p style={{ margin: 40, height: 50 }}> Public Hives allow everyone to create nodes and comment to topics. </p>

          {!this.state.hiveType && (
            <Button variant="contained" style={{ padding: 10, margin: 10 }} color="primary" onClick={() => this.setState({ hiveType: "Open" })}>
              Select
            </Button>
          )}
          {this.state.hiveType && (
            <Button variant="contained" style={{ padding: 10, margin: 10 }} color="primary" onClick={() => this.setState({ hiveType: "" })}>
              Edit
            </Button>
          )}
        </Paper>
      </Grid>
    );

    let teamTab = (
      <Grid item lg={3} md={4} xs={12}>
        <Paper style={{ padding: 15, backgroundColor: "#1F1E2E", color: "#fff",  zIndex: 120 }}>
          <img className="fadeIn" style={{ height: 300, paddingTop: 30, maxWidth: '100%' }} alt="team" src={"/media/images/team2.jpg"} />
          <h2> Team </h2>
          <h4 style={{ color: "#d1cec2" }}> Invited Users </h4>
          <p style={{ margin: 40, height: 50 }}>Team Hives have restricted access for your team members. You can open them at a later point.</p>
          {!this.state.hiveType && (
            <Button variant="contained" style={{ padding: 10, margin: 10 }} color="primary" onClick={() => this.setState({ hiveType: "Team" })}>
              Select
            </Button>
          )}
          {this.state.hiveType && (
            <Button variant="contained" style={{ padding: 10, margin: 10 }} color="primary" onClick={() => this.setState({ hiveType: "" })}>
              Edit
            </Button>
          )}
        </Paper>
      </Grid>
    );

    let privateTab = (
      <Grid item lg={3} md={4} xs={12}>
        <Paper style={{ padding: 15, backgroundColor: "#1F1E2E", color: "#fff", zIndex: 100 }}>
          <img className="fadeIn" style={{ height: 300, paddingTop: 30, paddingRight: 50, maxWidth: '100%' }} alt="private" src={"/media/images/solo.jpg"} />
          <h2> Private </h2>
          <h4 style={{ color: "#d1cec2" }}> Only You</h4>
          <p style={{ margin: 40, height: 50 }}>Private Hives are ideal to build an overview of topics that only you have access to.</p>
          {!this.state.hiveType && (
            <Button variant="contained" style={{ padding: 10, margin: 10 }} color="primary" onClick={() => this.setState({ hiveType: "Private" })}>
              Select
            </Button>
          )}
          {this.state.hiveType && (
            <Button variant="contained" style={{ padding: 10, margin: 10 }} color="primary" onClick={() => this.setState({ hiveType: "" })}>
              Edit
            </Button>
          )}
        </Paper>
      </Grid>
    );

    const formData = (
      <div>
        <Grid container justify="center" alignItems="stretch" spacing={1}>
          {this.state.hiveType === "Open" && openTab}
          {this.state.hiveType === "Team" && teamTab}
          {this.state.hiveType === "Private" && privateTab}

          <Grid item lg={3} md={4} xs={12}>
            <Paper style={{ padding: 20, height: "100%" }}>
              <TextField autoFocus type="text" margin="dense" id="name-Title" label="Hive Title" fullWidth onChange={(x) => this.setState({ hiveTitle: x.target.value })} />

              <TextField type="text" margin="dense" id="name-Description" label="Description" fullWidth onChange={(x) => this.setState({ hiveDescription: x.target.value })} />

              <FormControl style={{ width: "100%" }}>
                <InputLabel id="dcategory">Select Category</InputLabel>
                <Select labelId="category" id="category" margin="dense" fullWidth onChange={(x) => this.setState({ hiveCategory: x.target.value })}>
                  <MenuItem value={"Politics"}>Politics</MenuItem>
                  <MenuItem value={"Crypto"}>Crypto</MenuItem>
                  <MenuItem value={"Sports"}>Sports</MenuItem>
                  <MenuItem value={"Business"}>Business</MenuItem>
                  <MenuItem value={"Health"}>Health</MenuItem>
                  <MenuItem value={"Tech"}>Tech</MenuItem>
                </Select>
              </FormControl>
              <div style={{ margin: "20px 0px 0px 0px", float: "left" }}>
                <ImgCrop rotate aspect={1.5 / 1}>
                  <Upload customRequest={this.dummyRequest} listType="picture-card" fileList={this.state.imageFile} onChange={this.onChangeImage} onPreview={this.onPreview}>
                    {this.state.imageFile.length < 1 && "+ Image"}
                  </Upload>
                </ImgCrop>
              </div>
              <div style={{ margin: "5px 0px 0px 0px" }}>
                <ChipInput
                  color="primary"
                  fullWidth={true}
                  label="Hive Topics"
                  defaultValue={this.state.chipDefaultValue}
                  onChange={(chips) => this.setState({ chipDefaultValue: chips })}
                />
              </div>
              <Button
                style={{ padding: 10, margin: 20, backgroundColor: "#FFBE05", color: "black" }}
                variant="contained"
                color="primary"
                onClick={() => {
                  this.createHive();
                }}
              >
                Create Hive
              </Button>
            </Paper>
          </Grid>
        </Grid>
      </div>
    );

    const hiveSelection = (
      <div style={{ textAlign: "center" }}>
        <Grid container justify="center" alignItems="stretch" spacing={1}>
          {openTab}
          {teamTab}
          {privateTab}
        </Grid>
      </div>
    );

    return (
      <div
        style={{
          height: "100vh",
          backgroundColor: "#0D0C1D",
          textAlign: "center",
          overflowX: "hidden",
        }}
      >
        <TitleComponent title="Hive Creation" />

        <div style={{ padding: 20, color: "white" }}>
          <h1> Create a new Hive </h1>
        </div>
        {this.state.hiveType ? formData : hiveSelection}
      </div>
    );
  }
}

export default HiveCreation;
