import React, { useState, useEffect } from "react";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import ExpandMore from "@material-ui/icons/ExpandMore";
import IndeterminateCheckBoxSharpIcon from "@material-ui/icons/IndeterminateCheckBoxSharp";
import ExpandLess from "@material-ui/icons/ExpandLess";
import AddBoxSharp from "@material-ui/icons/AddBoxSharp";
import moment from "moment";
import SecurityIcon from "@material-ui/icons/Security";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import IconButton from "@material-ui/core/IconButton";

const firebase = require("firebase");

function PostImage(props) {
  const [userImgUrl, setuserImgUrl] = useState("");

  useEffect(() => {
    if (props.userID) {
      let url = "https://firebasestorage.googleapis.com/v0/b/hivedb-cdbf7.appspot.com/o/profile_images%2F*_100x100?alt=media&token=3aabfdad-1402-4a6c-8074-d8ea6edd7c0d";
      let imgUrl = url.slice(0, url.indexOf("*")) + props.userID + url.slice(url.indexOf("*") + 1, url.length);
      setuserImgUrl(imgUrl);
    }
  }, [props.userID]);

  return (
    <ListItemAvatar>
      <Avatar variant="square" src={userImgUrl} />
    </ListItemAvatar>
  );
}

export default class ReplySection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showMore: false,
    };
  }

  moderate = (replyItem, commentID) => {
    let contents = {};
    contents["hiveCards/" + replyItem.hiveID + "/node/" + replyItem.nodeID + "/comments/" + commentID + "/reply/" + replyItem.replyId + "/moderate"] = replyItem.moderate
      ? !replyItem.moderate
      : true;
    firebase
      .database()
      .ref()
      .update(contents);
  };

  renderReplyVote = (item, replyId) => {
    const { commentId, onVote } = this.props;
    const user = firebase.auth().currentUser;
    if (user) {
      const userID = user.uid;
      let hasVote,
        isUpvote,
        voteUserId,
        voteId = false;
      if (item.hasOwnProperty("vote")) {
        Object.keys(item.vote).forEach((key) => {
          voteUserId = item.vote[key].userID;
          if (userID === voteUserId) {
            hasVote = true;
            voteId = key;
            isUpvote = item.vote[key].upvote;
          }
        });
      }
      return (
        <div style={{ display: "inline-block", flexDirection: "row" }}>
          <VoteComponent
            voteCount={item.voteCount || 0}
            hasVote={hasVote}
            isUpvote={isUpvote}
            user={this.props.user}
            hiveID={this.props.hiveID}
            id={this.props.id}
            commentId={commentId}
            voteId={voteId}
            comments={this.props.comments}
            onVote={onVote}
            replyId={replyId}
          />
          {/* {hasVote && isUpvote ? (
            <AddBoxSharp onClick={() => onVote(commentId, voteId, true, replyId, hasVote, isUpvote)} />
          ) : (
            <ExpandLess onClick={() => onVote(commentId, voteId, true, replyId, hasVote, isUpvote)} />
          )}
          <span style={{ padding: "0px 5px" }}>{item.voteCount || 0}</span>
          {hasVote && !isUpvote ? (
            <IndeterminateCheckBoxSharpIcon onClick={() => onVote(commentId, voteId, false, replyId, hasVote, isUpvote)} />
          ) : (
            <ExpandMore onClick={() => onVote(commentId, voteId, false, replyId, hasVote, isUpvote)} />
          )} */}

          {/* //moderate */}
          {this.props.canModerate && (
            <FormControlLabel
              control={
                <Checkbox
                  icon={<SecurityIcon color={item.moderate === true ? "secondary" : "inherit"} />}
                  checkedIcon={<SecurityIcon color="primary" />}
                  checked={false}
                  value="checkedH"
                  onClick={() => this.moderate(item, commentId)}
                />
              }
            />
          )}
        </div>
      );
    }
  };

  sliceReplies = (replies) => {
    let slicedReply = [];
    replies.forEach((reply, index) => {
      if (index < 3) {
        slicedReply.push(reply);
      }
    });
    return slicedReply;
  };

  toggleShowMore = () => {
    this.setState({ showMore: !this.state.showMore });
  };

  render() {
    const { comment } = this.props;

    let { showMore } = this.state;
    let repliesSorted = this.props.sortByVotes(comment.reply, true);
    if (this.props.canModerate === false) {
      repliesSorted = repliesSorted.filter((obj) => {
        return obj.moderate !== true;
      });
    }
    let replies;

    showMore ? (replies = repliesSorted) : (replies = this.sliceReplies(repliesSorted));
    return (
      <div>
        {replies.map((reply, index) => (
          <div key={"replyItem_" + reply.replyId} style={{ display: "flex", alignItems: "flex-start" }}>
            <PostImage userID={reply.userID} />
            <ListItemText
              primary={
                <React.Fragment>
                  <Typography component="span" variant="body2" style={{ display: "inline" }} color="textPrimary">
                    {reply.userName ? reply.userName : "placeholder"}
                  </Typography>
                  <span style={{ display: "inline", fontSize: 9, paddingLeft: 7, color: "black" }}>{moment(reply.timeStamp).fromNow()}</span>
                  <span style={{ display: "block", color: "black", paddingTop: 5 }}>{reply.replyText}</span>
                  {this.renderReplyVote(reply, reply.replyId)}
                </React.Fragment>
              }
            />
          </div>
        ))}
        {Object.keys(comment.reply).length > 3 ? <Button onClick={this.toggleShowMore}> {showMore ? "Show Less" : "Show More"}</Button> : null}
      </div>
    );
  }
}

function VoteComponent(props) {
  const [voteCount, setvoteCount] = React.useState(props.voteCount);
  const [hasVote, sethasVote] = React.useState(props.hasVote);
  const [isUpvote, setisUpvote] = React.useState(props.isUpvote);
  const [disableBtns, setdisableBtns] = React.useState(false);

  const updateLocalState = (commentId, voteId, like, replyId, hasVotein, isUpvotein) => {
    const { user, id, hiveID } = props;
    setdisableBtns(true);
    //update state

    let votecountlocal;
    hasVotein ? (isUpvotein ? (votecountlocal = -1) : (votecountlocal = 1)) : (votecountlocal = 0);
    let voteCountcheck;
    voteCountcheck = (voteCount || 0) + (like ? votecountlocal + 1 : votecountlocal - 1);
    setisUpvote(like);
    setvoteCount(voteCountcheck);
    !hasVote && sethasVote(true);
    setdisableBtns(false);
    //updating db
    setTimeout(() => {
      props.onVote(commentId, voteId, like, replyId, hasVotein, isUpvotein);
    }, 50);
  };

  return (
    <>
      {hasVote && isUpvote ? (
        <IconButton
          disabled={true}
          // onClick={() => {
          //   updateLocalState(props.commentId, props.voteId, true, false, hasVote, isUpvote);
          // }}
        >
          <AddBoxSharp fontSize="small" />
        </IconButton>
      ) : (
        <IconButton
          disabled={disableBtns}
          style={{ cursor: "pointer" }}
          onClick={() => {
            updateLocalState(props.commentId, props.voteId, true, props.replyId, hasVote, isUpvote);
          }}
        >
          <ExpandLess fontSize="small" />
        </IconButton>
      )}
      <div style={{ display: "inline-block", textAlign: "center", width: 10 }}> {voteCount} </div>
      {hasVote && !isUpvote ? (
        <IconButton
          disabled={true}
          // onClick={() => {
          //   updateLocalState(props.commentId, props.voteId, false, false, hasVote, isUpvote);
          // }}
        >
          <IndeterminateCheckBoxSharpIcon fontSize="small" />
        </IconButton>
      ) : (
        <IconButton
          disabled={disableBtns}
          style={{ cursor: "pointer" }}
          onClick={() => {
            updateLocalState(props.commentId, props.voteId, false, props.replyId, hasVote, isUpvote);
          }}
        >
          <ExpandMore fontSize="small" />
        </IconButton>
      )}
    </>
  );
}
