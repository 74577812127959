import React, { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import Card from "@material-ui/core/Card";
import TextField from "@material-ui/core/TextField";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import { useSnackbar } from "notistack";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Button from "@material-ui/core/Button";
import { toAbsoluteUrl } from '../../../../src/_metronic/utils/utils';

const firebase = require("firebase");

function ReportPage(props) {
  const [descVal, setdescVal] = useState("");
  const [bugType, setbugType] = useState("Profile");
  const [userID, setuserID] = useState("");

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        setuserID(user.uid);
      } else {
        props.history.push("/auth");
      }
    });
  }, []);

  const report = () => {
    let contents = {};
    if (descVal.length > 0) {
      //push to DB
      let reportKey = firebase
        .database()
        .ref("reports")
        .push().key;
      contents["reports/" + reportKey] = {
        timeStamp: firebase.database.ServerValue.TIMESTAMP,
        type: bugType,
        details: descVal,
        userID: userID,
      };
      firebase
        .database()
        .ref()
        .update(contents);
      enqueueSnackbar("Bug Reported Successfully.", { variant: "success" });
      enqueueSnackbar("Thanks for your support!", { variant: "success" });
      setTimeout(() => {
        props.history.push("/");
      }, 3000);
    } else {
      enqueueSnackbar("Please provide the details!", { variant: "error" });
    }
  };

  return (
    <div>
      <Container style={{ maxWidth: 1440, minHeight: '60vh' }}>
        <Grid container spacing={1} style={{ marginTop: 4 }}>
        <Grid item sm={6} xs={12}>
            <img style={{ width: '100%', paddingTop: 10 }} src={toAbsoluteUrl('/media/images/bugFix.jpg')} alt="" />
          </Grid>
          <Grid item sm={6} xs={12}>
            <Card className="kt-portlet kt-portlet--height-fluid" style={{ marginTop: 9, padding: 0, marginBottom: 0 }}>
              <div className="kt-portlet__head">
                <div className="kt-portlet__head-label">
                  <h3 className="kt-portlet__head-title">Report a Bug</h3>
                </div>
              </div>
              <div style={{padding: 20, paddingBottom: 0, maxWidth: '500px'}}> 
              <p> Critical and previously unknown bugs will be rewarded with a micro payment to your Raspora Wallet. We thank you for your support!</p>
              </div>
              <div style={{ padding: 20, paddingTop: 10, display: "flex", flexDirection: "column" }}>
                <FormControl style={{ width: "100%", marginBottom: 20 }}>
                  <InputLabel id="demo-simple-select-label">Category</InputLabel>
                  <Select labelId="demo-simple-select-label" id="demo-simple-select" value={bugType} onChange={(e) => setbugType(e.target.value)}>
                    <MenuItem value={"Interface"}>Interface</MenuItem>
                    <MenuItem value={"Profile"}>Profile</MenuItem>
                    <MenuItem value={"Bitcoin related"}>Bitcoin related</MenuItem>
                    <MenuItem value={"Functionality"}>Functionality</MenuItem>
                    <MenuItem value={"Registration"}>Registration</MenuItem>
                    <MenuItem value={"Suggestion"}>Suggestion</MenuItem>
                  </Select>
                </FormControl>
                <TextField
                  style={{ width: "100%" }}
                  id="outlined-multiline-report"
                  label="Details"
                  multiline
                  rows={4}
                  value={descVal}
                  variant="outlined"
                  onChange={(e) => setdescVal(e.target.value)}
                />
                <div style={{ marginTop: 20, marginBottom: 20 }}>
                  <Button color="primary" variant="contained" onClick={report}>
                    Report
                  </Button>
                </div>
              </div>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}

export default ReportPage;
