/* eslint-disable no-unused-vars */
import React from "react";
import QuickPanelToggler from "./quick-panel-toggler/QuickPanelToggle";
import QuickActionsPanel from "../../../../app/partials/layout/QuickActionsPanel";
import UserNotifications from "../../../../app/partials/layout/UserNotifications";
import UserProfile from "../../../../app/partials/layout/UserProfile";
import { toAbsoluteUrl } from "../../../utils/utils";
import { connect } from "react-redux";
import * as builder from "../../../ducks/builder";
import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import HMenuItem from "./HMenuItem";

var firebase = require("firebase");

class Topbar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loggedIn: false,
      userID: "",
    };
  }

  componentDidMount() {
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        var userID = user.uid;
        this.setState({ loggedIn: true });
      } else {
        this.setState({ loggedIn: false });
      }
    });
  }
  get currentUrl() {
    return this.props.location.pathname.split(/[?#]/)[0];
  }

  logOut = (props) => {
    firebase
      .auth()
      .signOut()
      .then(() => {
        this.setState({ loggedIn: false });
        console.log("signedOut");
        props.history.push("/dashboard");
      })
      .catch(function(error) {
        console.log(error);
      });
  };

  render() {
    let loggedInItems = (
      <div className="kt-header__topbar">
        <QuickPanelToggler />
        <UserProfile logOutTrigger={() => this.logOut()} showAvatar={false} showHi={true} showBadge={true} />
      </div>
    );

    let loggedOutItems = (
      <div className="kt-header__topbar">
        <Link to="/auth/login">
          {" "}
          <Button variant="contained" color="primary" style={{ padding: 10, margin: 5 }}>
            {" "}
            Login{" "}
          </Button>{" "}
        </Link>
        <Link to="/auth/registration">
          {" "}
          <Button variant="contained" color="primary" style={{ padding: 10, margin: 5 }}>
            {" "}
            Register{" "}
          </Button>{" "}
        </Link>
      </div>
    );

    let display = this.state.loggedIn ? loggedInItems : loggedOutItems;

    const { ktMenuClasses, ulClasses, rootArrowEnabled } = this.props;
    const items = this.props.menuConfig.header.items;
    let offCanvasCommonRef = React.createRef();
    let headerNav = (
      <div className="kt-header__topbar kt-header-menu-wrapper" id="kt_header_menu_wrapper" ref={this.offCanvasCommonRef}>
        <div id="kt_header_menu" className={`kt-header-menu kt-header-menu-mobile ${ktMenuClasses}`} ref={this.ktMenuCommonRef}>
          <ul className={`kt-menu__nav ${ulClasses}`}>
            {items.map((item, index) => {
              return (
                <React.Fragment key={`hmenuList${index}`}>
                  {item.title && <HMenuItem item={item} currentUrl={this.currentUrl} rootArrowEnabled={rootArrowEnabled} />}
                </React.Fragment>
              );
            })}
          </ul>
        </div>
      </div>
    );
    return (
      <>
        <div style={{ display: "flex" }}>
          {headerNav}
          {display}
        </div>
      </>
    );
  }
}

const mapStateToProps = (store) => ({
  config: store.builder.layoutConfig,
  menuConfig: store.builder.menuConfig,
  ktMenuClasses: builder.selectors.getClasses(store, {
    path: "header_menu",
    toString: true,
  }),
  rootArrowEnabled: builder.selectors.getConfig(store, "header.menu.self.root-arrow"),
  headerSelfSkin: builder.selectors.getConfig(store, "header.self.skin"),
  ulClasses: builder.selectors.getClasses(store, {
    path: "header_menu_nav",
    toString: true,
  }),
});

export default withRouter(connect(mapStateToProps)(Topbar));
