import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import CardHeader from "@material-ui/core/CardHeader";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { toAbsoluteUrl } from "../../../../src/_metronic/utils/utils";
import Grid from "@material-ui/core/Grid";

const useStyles = makeStyles({
  root: {
    minWidth: 275,
  },

  title: {
    color: "#000000",
  },
  header: {
    marginBottom: 0,
    marginTop: 0,
    paddingBottom: 0,
  },
});

export default function WelcomeCard(props) {
  const classes = useStyles();
  const [componentOpacity, setComponentOpacity] = React.useState(1);


	return (
		<div
			style={{
				backgroundColor: 'black',
				maxHeight: 900,
				opacity: componentOpacity,
				transition: 'opacity 0.6s',
			}}
		>
			<div className={classes.root}>
				<Grid container>
					<Grid item xs={6} style={{ padding: 10, paddingTop: 20, color: '#fefefe' }}>
						<div>
							<h3 style={{ fontWeight: 500 }}> Welcome </h3>
							<p>
								Every Hive starts somewhere. This instruction page vanishes as soon as you add your first 
								media "node".
							</p>
						</div>
						<div>
							<div style={{ height: 37, backgroundColor: '#00374C', padding: 10, marginBottom: 10 }}>
								Topics
							</div>
							<p>
								Topics are the fundaments on which your Hive is built on. Use the sections wisely to
								moderate your content, but make sure to limit the number of topics to have interactions
								between the nodes.
							</p>
							<div style={{ height: 37, backgroundColor: '#00374C', padding: 10, marginBottom: 10 }}>
								Nodes
							</div>
							<p>
								Nodes are the media content added to the topics. Successful nodes will have other nodes
								connected to them to extend, support or oppose the arguments of the content to get a
								more diverse perspective of the topic.
							</p>
							<div style={{ height: 37, backgroundColor: '#00374C', padding: 10, marginBottom: 10 }}>
								Hive Settings
							</div>
							<p>
								When clicking on the settings icon above, you can change the hive from private and
								restricted, to public. Make sure to do this with the neccessary caution.
							</p>
							<div style={{ height: 37, backgroundColor: '#00374C', padding: 10, marginBottom: 10 }}>
								Administration
							</div>
							<p>
								As a Hive creator, you can moderate your hive, to filter out content that might go
								against copyright agreements or other violations of laws as stated in our terms.
							</p>
							<div style={{ height: 37, backgroundColor: '#00374C', padding: 10, marginBottom: 10 }}>
								Note
							</div>
							<p>
								Public hives are only visible on raspora once they have > 3 nodes. 
							</p>
						</div>
					</Grid>
					<Grid item xs={6}>
						<img style={{ width: '100%', paddingTop: 10 }} src={toAbsoluteUrl('/media/images/crane.jpg')} alt="" />
					</Grid>
				</Grid>
			</div>
		</div>
	);
}
