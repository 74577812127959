/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import moment from "moment";
import SwipeableViews from "react-swipeable-views";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { Link } from "react-router-dom";
import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";
import Card from "@material-ui/core/Card";
import PublicSharpIcon from "@material-ui/icons/PublicSharp";
import HttpsSharpIcon from "@material-ui/icons/HttpsSharp";
import LockOpenRoundedIcon from "@material-ui/icons/LockOpenRounded";
import BubbleChartIcon from "@material-ui/icons/BubbleChart";

var firebase = require("firebase");

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} id={`full-width-tabpanel-${index}`} aria-labelledby={`full-width-tab-${index}`} {...other}>
      {value === index && <>{children}</>}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

function compare(a, b) {
  if (a.timeStamp > b.timeStamp) {
    return -1;
  }
  if (a.timeStamp < b.timeStamp) {
    return 1;
  }
  return 0;
}

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#ffffff",
    height: "100%",
  },
}));

export default function LatestUpdates(props) {
  const classes = useStyles();
  const theme = useTheme();
  const [value, setValue] = useState(0);
  const [latestActivitiesState, setlatestActivities] = useState({});
  const [latestNotificationsAndActivities, setlatestNotificationsAndActivities] = useState([]);

  useEffect(() => {
    if (props.latestActivities) {
      let localLatestActivities = { ...props.latestActivities };
      Object.keys(props.latestActivities)
        .reverse()
        .map(function(key, index) {
          let currentType = props.latestActivities[key].type;
          if (currentType === "pulse" && props.latestActivities[key].pulseId) {
            firebase
              .database()
              .ref("/pulses/" + props.latestActivities[key].pulseId)
              .once("value")
              .then(function(snapshot) {
                let pulseData = snapshot.val();
                if (pulseData) {
                  localLatestActivities[key].active = pulseData.active;
                  localLatestActivities[key].remaining = pulseData.remaining;
                  localLatestActivities[key].value = pulseData.value;
                }
              });
          }
          if (currentType === "hive" && props.latestActivities[key].userID) {
            firebase
              .database()
              .ref("/hiveCards/" + props.latestActivities[key].hiveID + "/type")
              .once("value")
              .then(function(snapshot) {
                let hiveType = snapshot.val();
                if (hiveType) {
                  localLatestActivities[key].hiveType = hiveType;
                }
              });
          }
          return null;
        });
      setlatestActivities(localLatestActivities);
    }
  }, [props.latestActivities]);

  useEffect(() => {
    let latestNotificationsArray = Object.values(props.latestNotifications);
    let unsortedlatestActivitiesArray = Object.values(props.latestActivities);
    let latestActivitiesArray = [];

    unsortedlatestActivitiesArray.map((a, b) => {
      if (a.type === "like" || a.type === "comment" || a.type === "email") {
        a.ctype = "activi";
        latestActivitiesArray.push(a);
      }
      return null;
    });
    latestNotificationsArray.map((a, b) => {
      latestNotificationsArray[b].ctype = "notifi";
      return null;
    });
    let latestNotifiAndActiArray = latestNotificationsArray.concat(latestActivitiesArray);
    latestNotifiAndActiArray = latestNotifiAndActiArray.sort(compare);
    setlatestNotificationsAndActivities([...latestNotifiAndActiArray]);
    props.setNotiAndActi([...latestNotifiAndActiArray]);
  }, [props.latestNotifications, props.latestActivities]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  const latestActivityTab = (
    <div className="kt-portlet kt-portlet--height-fluid kt-portlet--padding-bottom-fluid">
      <div className="kt-portlet__body">
        <div className="kt-widget4">
          {latestNotificationsAndActivities.map((item, index) => {
            let currentType = item.type;
            let ctype = item.ctype;

            let activityText = "";
            let price = 0;
            let iconType = "flaticon2-crisp-icons kt-font-success";
            let linkTo = undefined;
            if (item.hiveID) {
              linkTo = `/hive/${item.hiveID}`;
            }
            if (item.nodeID) {
              linkTo = linkTo + "&id=" + item.nodeID;
            }

            if (currentType === "like" && ctype === "activi") {
              activityText = `You liked node #${item.nodeID.substring(5, 10)}`;
              price = -0.05;
              iconType = "flaticon-like kt-font-success";
            } else if (currentType === "comment" && ctype === "activi") {
              activityText = `You commented on #${item.nodeID.substring(5, 10)}`;
              iconType = "flaticon2-chat kt-font-brand";
            } else if (currentType === "email" && ctype === "activi") {
              activityText = `Email Address Verified`;
              iconType = "flaticon-multimedia-2 kt-font-success";
            } else if (ctype === "notifi" && currentType === "like") {
              activityText = `You got a new like on your node #${item.nodeID.substring(5, 10)}`;
              iconType = "flaticon-like kt-font-brand";
            } else if (ctype === "notifi" && currentType === "pulse") {
              activityText = `You got a new pulse on your node #${item.nodeID.substring(5, 10)}`;
              iconType = "flaticon-stopwatch kt-font-success";
            } else if (ctype === "notifi" && currentType === "node") {
              activityText = `You got a new node on your hive #${item.hiveID.substring(5, 10)}`;
              iconType = "flaticon2-website kt-font-brand";
            }

            return (
              <div className="kt-widget4__item" key={`${item.nodeID}-activity${index}`}>
                <div style={{ width: "60%" }}>
                  <span className="kt-widget4__icon">
                    <i className={iconType} />
                  </span>
                  {linkTo ? (
                    <Link className="kt-widget4__title" to={linkTo}>
                      {activityText}
                    </Link>
                  ) : (
                    <span className="kt-widget4__title">{activityText}</span>
                  )}
                </div>
                <div style={{ width: "15%" }}>
                  <span className="kt-widget4__timestamp">{moment(item.timeStamp).fromNow()}</span>
                </div>
                <div style={{ width: "10%" }}>
                  <span className={`kt-widget4__number ${price < 0 ? "kt-font-danger" : "kt-font-success"}`}>
                    {price === 0 ? "-" : `${price < 0 ? "-" : "+"}$${Math.abs(price)}`}
                  </span>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );

  const PulsesTab = (
    <div className="kt-portlet kt-portlet--height-fluid kt-portlet--padding-bottom-fluid">
      <div className="kt-portlet__body">
        <div className="kt-widget4">
          {Object.keys(latestActivitiesState)
            .reverse()
            .map(function(key, index) {
              let currentType = latestActivitiesState[key].type;
              let activityText = "";
              let price = 0;
              let iconType;
              let linkTo = "";

              if (currentType === "pulse") {
                price = latestActivitiesState[key].value * -1;

                activityText = `You created a new pulse: `;
                if (latestActivitiesState[key].hiveID) {
                  linkTo = `/hive/${latestActivitiesState[key].hiveID}`;
                }
                if (latestActivitiesState[key].nodeID) {
                  linkTo = linkTo + "&id=" + latestActivitiesState[key].nodeID;
                }
              } else if (currentType === "like") {
                price = -1;
                activityText = `You liked node #: `;
                if (latestActivitiesState[key].hiveID) {
                  linkTo = `/hive/${latestActivitiesState[key].hiveID}`;
                }
                if (latestActivitiesState[key].nodeID) {
                  linkTo = linkTo + "&id=" + latestActivitiesState[key].nodeID;
                }
              }

              if (currentType === "pulse" || currentType === "like") {
                return (
                  <div className="kt-widget4__item" key={`${key}-activity`}>
                    <div style={{ width: "50%" }}>
                      <span className="kt-widget4__icon">
                        <i className={"flaticon-stopwatch kt-font-success"} />
                      </span>
                      <Link className="kt-widget4__title" to={linkTo}>
                        {activityText + latestActivitiesState[key].nodeID.substring(5, 10)}
                      </Link>
                    </div>

                    <div style={{ width: "15%" }}>
                      {currentType !== "like" && (
                        <span className="kt-widget4__timestamp">
                          {latestActivitiesState[key].remaining && latestActivitiesState[key].remaining !== 0 ? `${latestActivitiesState[key].remaining} remaining` : "finished"}
                        </span>
                      )}
                    </div>
                    {/* <div style={{ width: "10%" }}>
                      {latestActivitiesState[key].active ? (
                        <span className="kt-widget4__timestamp">active</span>
                      ) : (
                        <span className="kt-widget4__timestamp">{currentType !== "like" ? "in-active" : ""}</span>
                      )}{" "}
                    </div> */}
                    <div style={{ width: "15%" }}>
                      <span className="kt-widget4__timestamp">{moment(latestActivitiesState[key].timeStamp).fromNow()}</span>
                    </div>
                    <div style={{ width: "10%" }}>
                      <span className={`kt-widget4__number ${price < 0 ? "kt-font-danger" : "kt-font-success"}`}>
                        {!price ? "-" : `${price < 0 ? "-" : "+"}$${Math.abs(price)}`}
                      </span>
                    </div>
                  </div>
                );
              } else {
                return null;
              }
            })}
        </div>
      </div>
    </div>
  );

  const NodesTab = (
    <div className="kt-portlet kt-portlet--height-fluid kt-portlet--padding-bottom-fluid">
      <div className="kt-portlet__body">
        <div className="kt-widget4">
          {Object.keys(latestActivitiesState)
            .reverse()
            .map(function(key, index) {
              let currentType = latestActivitiesState[key].type;
              let activityText = "";
              let price = 0;
              let iconType;
              let linkTo = "";

              if (currentType === "node") {
                activityText = `You created a new node`;
                iconType = "flaticon2-website kt-font-brand";
                if (latestActivitiesState[key].hiveID) {
                  linkTo = `/hive/${latestActivitiesState[key].hiveID}`;
                }
                if (latestActivitiesState[key].nodeID) {
                  linkTo = linkTo + "&id=" + latestActivitiesState[key].nodeID;
                  activityText = activityText + " #" + latestActivitiesState[key].nodeID.substring(5, 10);
                }
                return (
                  <div className="kt-widget4__item" key={`${key}-activity`}>
                    <div style={{ width: "60%" }}>
                      <span className="kt-widget4__icon">
                        <i className={iconType} />
                      </span>
                      <Link className="kt-widget4__title" to={linkTo}>
                        {activityText}
                      </Link>
                    </div>
                    <div style={{ width: "15%" }}>
                      <span className="kt-widget4__timestamp">{moment(latestActivitiesState[key].timeStamp).fromNow()}</span>
                    </div>
                    <div style={{ width: "10%" }}>
                      <span className={`kt-widget4__number ${price < 0 ? "kt-font-danger" : "kt-font-success"}`}>
                        {price === 0 ? "-" : `${price < 0 ? "-" : "+"}$${Math.abs(price)}`}
                      </span>
                    </div>
                  </div>
                );
              } else if (currentType === "pulseAdded") {
                activityText = `${latestActivitiesState[key].userName} created a new pulse on your node`;
                iconType = "flaticon2-crisp-icons kt-font-success";
                if (latestActivitiesState[key].hiveID) {
                  linkTo = `/hive/${latestActivitiesState[key].hiveID}`;
                }
                if (latestActivitiesState[key].nodeID) {
                  linkTo = linkTo + "&id=" + latestActivitiesState[key].nodeID;
                }
                return (
                  <div className="kt-widget4__item" key={`${key}-activity`}>
                    <div style={{ width: "60%" }}>
                      <span className="kt-widget4__icon">
                        <i className={"flaticon-stopwatch kt-font-success"} />
                      </span>
                      <Link className="kt-widget4__title" to={linkTo}>
                        {activityText}
                      </Link>
                    </div>
                    <div style={{ width: "15%" }}>
                      <span className="kt-widget4__timestamp">{moment(latestActivitiesState[key].timeStamp).fromNow()}</span>
                    </div>
                    <div style={{ width: "10%" }}>
                      <span className={`kt-widget4__number kt-font-success`}>-</span>
                    </div>
                  </div>
                );
              } else {
                return null;
              }
            })}
        </div>
      </div>
    </div>
  );

  const HivesTab = (
    <div className="kt-portlet kt-portlet--height-fluid kt-portlet--padding-bottom-fluid">
      <div className="kt-portlet__body">
        <div className="kt-widget4">
          {Object.keys(latestActivitiesState)
            .reverse()
            .map(function(key, index) {
              let currentType = latestActivitiesState[key].type;
              let activityText = "";
              let price = 0;
              let linkTo = "";
              let hiveType = undefined;
              // let iconType;

              if (currentType === "hive") {
                activityText = `You created a new hive`;
                // iconType = "flaticon2-line-chart kt-font-danger";
                price = 1 * -1;
                if (latestActivitiesState[key].hiveID) {
                  linkTo = `/hive/${latestActivitiesState[key].hiveID}`;
                  activityText = activityText + " #" + latestActivitiesState[key].hiveID;
                }
                if (latestActivitiesState[key].hiveType) {
                  hiveType = latestActivitiesState[key].hiveType;
                }

                return (
                  <div className="kt-widget4__item" key={`${key}-activity`}>
                    <div style={{ width: "60%" }}>
                      <span className="kt-widget4__icon">
                        {(() => {
                          if (hiveType === "Open") {
                            return <PublicSharpIcon color="primary" />;
                          } else if (hiveType === "Team") {
                            return <LockOpenRoundedIcon color="primary" />;
                          } else if (hiveType === "Private") {
                            return <HttpsSharpIcon color="primary" />;
                          }
                        })()}
                      </span>
                      <Link className="kt-widget4__title" to={linkTo}>
                        {activityText}
                      </Link>
                    </div>

                    <div style={{ width: "15%" }}>
                      <span className="kt-widget4__timestamp">{moment(latestActivitiesState[key].timeStamp).fromNow()}</span>
                    </div>
                    <div style={{ width: "10%" }}>
                      <span className={`kt-widget4__number ${price < 0 ? "kt-font-danger" : "kt-font-success"}`}>{`${price < 0 ? "-" : "+"}$${Math.abs(price)}`}</span>
                    </div>
                  </div>
                );
              } else {
                return null;
              }
            })}
        </div>
      </div>
    </div>
  );

  return (
    <Card style={{ height: "100%" }}>
      <div className={classes.root}>
        <AppBar position="static" color="transparent">
          <Tabs value={value} onChange={handleChange} indicatorColor="primary" textColor="primary" variant="fullWidth" aria-label="full width tabs example">
            <Tab label="Latest Activity" {...a11yProps(0)} />
            <Tab label="Nodes" {...a11yProps(1)} />
            <Tab label="Hives" {...a11yProps(2)} />
            <Tab label="Supported" {...a11yProps(3)} />
          </Tabs>
        </AppBar>
        <PerfectScrollbar>
          <div style={{ height: "500px" }}>
            <SwipeableViews axis={theme.direction === "rtl" ? "x-reverse" : "x"} index={value} onChangeIndex={handleChangeIndex} className={classes.swipeViews}>
              <TabPanel value={value} index={0} dir={theme.direction}>
                {latestActivityTab}
              </TabPanel>
              <TabPanel value={value} index={1} dir={theme.direction}>
                {NodesTab}
              </TabPanel>
              <TabPanel value={value} index={2} dir={theme.direction}>
                {HivesTab}
              </TabPanel>
              <TabPanel value={value} index={3} dir={theme.direction}>
                {PulsesTab}
              </TabPanel>
            </SwipeableViews>
          </div>
        </PerfectScrollbar>
      </div>
    </Card>
  );
}
