import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Formik } from 'formik';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import { TextField } from '@material-ui/core';
import clsx from 'clsx';
import { updateUserStatus } from '../../store/ducks/auth.duck';
import * as firebase from 'firebase';
import { withRouter } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { toAbsoluteUrl } from '../../../_metronic';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import 'bootstrap-social/bootstrap-social.css';
import { makeStyles } from '@material-ui/core/styles';
import { GoogleLoginButton, GithubLoginButton } from 'react-social-login-buttons';
import Grid from '@material-ui/core/Grid';

var googleProvider = new firebase.auth.GoogleAuthProvider();
var githubProvider = new firebase.auth.GithubAuthProvider();

const useStyles = makeStyles((theme) => ({
	gitBtn: {
		backgroundColor: '#444'
	}
}));

function Login(props) {
	const classes = useStyles();

	const { intl, goTo } = props;
	const [ loading, setLoading ] = useState(false);
	const [ loadingButtonStyle, setLoadingButtonStyle ] = useState({
		paddingRight: '2.5rem'
	});

	const enableLoading = () => {
		setLoading(true);
		setLoadingButtonStyle({ paddingRight: '3.5rem' });
	};

	const disableLoading = () => {
		setLoading(false);
		setLoadingButtonStyle({ paddingRight: '2.5rem' });
	};

	const { enqueueSnackbar } = useSnackbar();

	const signInWithGoogle = () => {
		firebase
			.auth()
			.signInWithPopup(googleProvider)
			.then(function(result) {
				// This gives you a Google Access Token. You can use it to access the Google API.
				var token = result.credential.accessToken;
				// The signed-in user info.
				var user = result.user;
				if (props.isPopup) {
					props.setLoginPopup(false);
					if (goTo) {
						props.history.push(goTo);
						// props.history.push("/overview");
					}
				} else {
					props.history.push('/');
				}
				props.updateUserStatus(true);
				console.log('user', result);
				// ...
			})
			.catch(function(error) {
				// Handle Errors here.
				var errorCode = error.code;
				var errorMessage = error.message;
				// The email of the user's account used.
				var email = error.email;
				// The firebase.auth.AuthCredential type that was used.
				var credential = error.credential;
				console.log('error', error);
				enqueueSnackbar(error.message, {
					variant: 'error'
				});
				// ...
			});
	};

	const signInWithGithub = () => {
		firebase
			.auth()
			.signInWithPopup(githubProvider)
			.then(function(result) {
				// This gives you a GitHub Access Token. You can use it to access the GitHub API.
				var token = result.credential.accessToken;
				// The signed-in user info.
				var user = result.user;
				console.log('result', result);
				if (props.isPopup) {
					props.setLoginPopup(false);
					if (goTo) {
						props.history.push(goTo);
					}
				} else {
					props.history.push('/');
				}
				props.updateUserStatus(true);
				// ...
			})
			.catch(function(error) {
				// Handle Errors here.
				var errorCode = error.code;
				var errorMessage = error.message;
				// The email of the user's account used.
				var email = error.email;
				// The firebase.auth.AuthCredential type that was used.
				var credential = error.credential;
				console.log('error', error);
				enqueueSnackbar(error.message, {
					variant: 'error'
				});
				// ...
			});
	};

	return (
		<div>
			<div className="kt-login__body" style={{ height: 'calc(100vh - 160px)' }}>
				<div className="kt-login__form">
					<div className="kt-login__title">
						<h3>
							<FormattedMessage id="AUTH.LOGIN.TITLE" />
						</h3>
					</div>
					<div style={{ marginBottom: 10 }}>
						<Grid container>
							<Grid item xs={6}>
								<GoogleLoginButton onClick={signInWithGoogle} />
							</Grid>
							<Grid item xs={6}>
								<GithubLoginButton onClick={signInWithGithub} />
							</Grid>
						</Grid>
					</div>

					<p style={{ textAlign: 'center', margin: '20px 0px 0px 0px' }}>OR</p>
					<Formik
						initialValues={{
							email: '',
							password: ''
						}}
						validate={(values) => {
							const errors = {};

							if (!values.email) {
								// https://github.com/formatjs/react-intl/blob/master/docs/API.md#injection-api
								errors.email = intl.formatMessage({
									id: 'AUTH.VALIDATION.REQUIRED_FIELD'
								});
							} else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
								errors.email = intl.formatMessage({
									id: 'AUTH.VALIDATION.INVALID_FIELD'
								});
							}

							if (!values.password) {
								errors.password = intl.formatMessage({
									id: 'AUTH.VALIDATION.REQUIRED_FIELD'
								});
							}

							return errors;
						}}
						onSubmit={(values, { setStatus, setSubmitting }) => {
							enableLoading();
							setTimeout(() => {
								firebase
									.auth()
									.signInWithEmailAndPassword(values.email, values.password)
									.then(() => {
										disableLoading();
										console.log('logged in!');
										if (props.isPopup) {
											props.setLoginPopup(false);
											if (goTo) {
												props.history.push(goTo);
												// props.history.push("/overview");
											}
										} else {
											props.history.push('/overview');
										}
										props.updateUserStatus(true);
									})
									.catch((error) => {
										console.log('failed to log in!');
										console.log(error);
										disableLoading();
										setSubmitting(false);
										enqueueSnackbar(error.message, {
											variant: 'error'
										});
										setStatus(
											intl.formatMessage({
												id: 'AUTH.VALIDATION.INVALID_LOGIN'
											})
										);
									});
							}, 1000);
						}}
					>
						{({
							values,
							status,
							errors,
							touched,
							handleChange,
							handleBlur,
							handleSubmit,
							isSubmitting
						}) => (
							<form
								noValidate={true}
								autoComplete="off"
								className="kt-form"
								onSubmit={handleSubmit}
								style={{ marginTop: -10 }}
							>
								<div className="form-group">
									<TextField
										type="email"
										label="Email"
										margin="normal"
										className="kt-width-full"
										name="email"
										onBlur={handleBlur}
										onChange={handleChange}
										value={values.email}
										helperText={touched.email && errors.email}
										error={Boolean(touched.email && errors.email)}
									/>
								</div>

								<div className="form-group">
									<TextField
										type="password"
										margin="normal"
										label="Password"
										className="kt-width-full"
										name="password"
										onBlur={handleBlur}
										onChange={handleChange}
										value={values.password}
										helperText={touched.password && errors.password}
										error={Boolean(touched.password && errors.password)}
									/>
								</div>

								<div className="kt-login__actions">
									<Link to="/auth/forgot-password" className="kt-link kt-login__link-forgot">
										<FormattedMessage id="AUTH.GENERAL.FORGOT_BUTTON" />
									</Link>

									<button
										id="kt_login_signin_submit"
										type="submit"
										disabled={isSubmitting}
										className={`btn btn-primary btn-elevate kt-login__btn-primary ${clsx({
											'kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light': loading
										})}`}
										style={loadingButtonStyle}
									>
										Sign In
									</button>
								</div>
							</form>
						)}
					</Formik>
				</div>
			</div>
		</div>
	);
}

const mapStateToProps = ({ auth: { isUserLogin } }) => ({
	isUserLogin
});

export default injectIntl(connect(mapStateToProps, { updateUserStatus })(withRouter(Login)));
