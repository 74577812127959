export default {
  header: {
    self: {},
    items: [
     
      {
        title: "Explore",
        root: true,
        alignment: "left",
        page: "explore",
        translate: "MENU.DASHBOARD"
      },
      {
        title: "About",
        root: true,
        alignment: "left",
        page: "about",
        translate: "MENU.DASHBOARD"
      },
    ]
  },
  aside: {
    self: {},
    items: []
      }
    
  
};
