import * as user from "./action.jsx";
import filepay from "filepay";
import axios from "axios";

var firebase = require("firebase");

export function currentuserdetails() {
  var user = firebase.auth().currentUser;
  var Userid = user.uid;
  return {
    type: "CURRENTUSERDETAILS",
    payload: {
      Userid: Userid,
    },
  };
}

export function changepassword(newpassword) {
  return function(dispatch) {
    var user = firebase.auth().currentUser;
    user.updatePassword(newpassword).then(
      function() {
        dispatch({
          type: "CHANGEPASSWORDETAILS",
          payload: {
            password: "Changed Successfully",
          },
        });
      },
      function(error) {
        dispatch({
          type: "CHANGEPASSWORDETAILS",
          payload: {
            password: error,
          },
        });
      }
    );
  };
}

export function changeemaildetails(newemail) {
  return function(dispatch) {
    var user = firebase.auth().currentUser;
    user.updateEmail(newemail).then(
      function() {
        dispatch({
          type: "CHANGEEMAIL",
          payload: {
            Email: "Changed Successfully",
          },
        });
      },
      function(error) {
        dispatch({
          type: "CHANGEEMAIL",
          payload: {
            Email: error,
          },
        });
      }
    );
  };
}

export function currentuserid() {
  var user = firebase.auth().currentUser;
  var Userid = user.uid;
  return {
    type: "USERID",
    payload: {
      Userid,
    },
  };
}

export function updateProfilePic(value) {
  return {
    type: "[User] PROFILE PIC UPDATE",
    payload: value,
  };
}

export default user;

//transction Function

export async function makeWithdrawal(type, calllableSnack, handleLoader, gethdPrivateKey, opData, txoSet, bsvPrice, withdrawlValues, handleWithdrawlDialog) {
  let getMetrices = firebase.functions().httpsCallable("getMetrices");
  let userWalletInfo = await getMetrices();

  let market_price_usd = await firebase
    .database()
    .ref("stats/market_price_usd")
    .once("value")
    .then((snapshot) => {
      return snapshot.val();
    });

  if (type === "withdrawal") {
    if (userWalletInfo.data && userWalletInfo.data.metrices && market_price_usd) {
      let amountInUserAccount = (userWalletInfo.data.metrices.balance * market_price_usd) / 100000000;
      if (withdrawlValues.amount > amountInUserAccount) {
        calllableSnack("You didn't have enough balance to do this transction", {
          variant: "error",
        });
        handleLoader(false);
        return null;
      } else {
        //hdPrivateKey (from /keyStore), txoSet (from /keyStore), amount (function input), address (function input), data (function input), bsvprice (from /stats)
        let hdPrivateKey = filepay.bsv.HDPrivateKey.fromString(gethdPrivateKey);
        let amount = withdrawlValues.amount;
        let address = withdrawlValues.address;
        //data and output are arrays with one or multiple entries

        //rest of transaction logic
        let privateKey = hdPrivateKey.deriveChild("m/44'/0'/0'/0/0").privateKey;
        let changeAddress = filepay.bsv.Address.fromPrivateKey(privateKey).toString();
        let satAmount = parseInt(Math.ceil((amount / bsvPrice) * 100000000));
        console.log("satAmount: ", satAmount);
        let loopValue = 0;
        let txids = [];

        let output = [
          {
            address: address,
            value: satAmount,
          },
        ];

        for (let el in txoSet) {
          if (loopValue < satAmount) {
            let x = txoSet[el];
            txids.push({
              txid: x.txid,
              value: parseInt(x.value),
              script: x.scriptPubKey,
              outputIndex: x.vout,
              required: true,
            });
            loopValue += parseInt(x.value);
          } else {
            break;
          }
        }

        var tx = {
          safe: true,
          data: opData,
          pay: {
            key: privateKey.toString(),
            feeb: 0.5,
            changeAddress: changeAddress,
            inputs: txids,
            to: output,
          },
        };
        console.log("tx", tx);
        var rawTX;
        try {
          filepay.build(tx, (err, res) => {
            rawTX = res.toString();
          });
        } catch (err) {
          console.log("err", err.message);
          calllableSnack("Invalid Address Provided", {
            variant: "error",
          });
          handleLoader(false);
          return null;
        }

        console.log("rawTx: ", rawTX);
        const pushTx = async () => {
          const res = await axios.post(
            "https://merchantapi.taal.com/mapi/tx",
            { rawtx: rawTX },
            {
              headers: {
                "content-type": "application/json",
              },
            }
          );
          let txData = res.data;
          let txid = txData.payload;
          console.log(txid);
        };

        pushTx()
          .then(function(txid) {
            calllableSnack("Transaction Completed Successfully", {
              variant: "success",
            });
            handleLoader(false);
            handleWithdrawlDialog(false);
            setTimeout(() => {
              getMetrices();
            }, 10000);
            return txid;
          })
          .catch((err) => {
            calllableSnack(err.message ? err.message : "An error occured while performing your Transaction", {
              variant: "error",
            });
            handleLoader(false);
            return null;
          });
      }
    } else {
      calllableSnack("Insufficient balance in wallet", {
        variant: "error",
      });
      handleLoader(false);
    }
  }
}

export async function makeTransction(type, gethdPrivateKey, opData, output, prevUsedTxos, snackHandler) {
  let getMetrices = firebase.functions().httpsCallable("getMetrices");
  let userWalletInfo = await getMetrices();

  
  console.log("prevUsedTxos:", prevUsedTxos);

  if (type === "node" || type === "like") {
    if (userWalletInfo.data && userWalletInfo.data.metrices) {
      let txoSet = userWalletInfo.data.metrices.txoSet.sort((a, b) => b.amount - a.amount);

      let requestAmount = 1200;
      output.forEach((x) => (requestAmount += x.value));

      if (requestAmount > userWalletInfo.data.metrices.balance) {
        snackHandler("Insufficient balance to make this transaction", {
          variant: "error",
        });
        return null;
      } else {
        let user = firebase.auth().currentUser;
        let userID = user.uid;
        let hdPrivateKey = filepay.bsv.HDPrivateKey.fromString(gethdPrivateKey);
        let privateKey = hdPrivateKey.deriveChild("m/44'/0'/0'/0/0").privateKey;
        let changeAddress = filepay.bsv.Address.fromPrivateKey(privateKey).toString();
        let loopValue = 0;
        let filteredTxoValue = 0;
        let outputAmount = 0;
        let txids = [];

        prevUsedTxos = prevUsedTxos ? prevUsedTxos : [];
        let usedTxoSet = [];
        console.log("txoSet before: ", txoSet);

        txoSet = txoSet.filter((x) => !prevUsedTxos.some((y) => x.txid === y.txid && x.vout === y.vout));
        txoSet.filter((x) => (filteredTxoValue += x.value));
        output.filter((x) => (outputAmount += x.value));

        let remainingSats = filteredTxoValue - outputAmount - 1200;
        if (remainingSats < 0) {
          snackHandler("Generating new UTXOs, try again!", {
            variant: "error",
          });
          setTimeout(getMetrices, 3000);
          return "failure";
        }

        if (txoSet.length < 20 && remainingSats > 20000) {
          requestAmount += filteredTxoValue / 2 - outputAmount;
          for (let i = 0; i < 5; i++) {
            if (parseInt(remainingSats / 10 > 1000)) {
              output.push({
                address: changeAddress,
                value: parseInt(remainingSats / 10),
              });
            }
          }
        }

        console.log("filteredTxoSet after", txoSet);
        for (let el in txoSet) {
          if (loopValue < requestAmount) {
            let x = txoSet[el];
            txids.push({
              txid: x.txid,
              value: parseInt(x.value),
              script: x.scriptPubKey,
              outputIndex: x.vout,
              required: false,
            });
            usedTxoSet.push(x);
            loopValue += parseInt(x.value);
          } else {
            break;
          }
        }

        var tx = {
          safe: true,
          data: opData,
          pay: {
            key: privateKey.toString(),
            feeb: 0.5,
            changeAddress: changeAddress,
            inputs: txids,
            to: output,
          },
        };
        console.log("tx:", tx);
        var rawTX;
        try {
          filepay.build(tx, (err, res) => {
            rawTX = res.toString();
          });
        } catch (err) {
          console.log("err", err.message);
          snackHandler("Invalid Address Provided", {
            variant: "error",
          });
          return null;
        }

        let apiCall = axios
          .post(
            "https://merchantapi.taal.com/mapi/tx",
            { rawtx: rawTX },
            {
              headers: {
                "content-type": "application/json",
              },
            }
          )
          .then((response) => {
            let txData = response.data;
            let txid = JSON.parse(txData.payload);

            if (txid.returnResult === "failure") {
              return "failure";
            }

            if (txid.returnResult == "success") {
              console.log("pushed to firebase");
              firebase
                .database()
                .ref("KeyStore/" + userID + "/")
                .update({ usedTxos: [...usedTxoSet, ...prevUsedTxos] });
            }
            return txid;
          })
          .then((txid) => {
            console.log("usedTxoSet:", usedTxoSet);
            let remainingTxoSet = txoSet.filter((x) => !usedTxoSet.includes(x));
            console.log("remainingTxoSet", remainingTxoSet);
            console.log("txids:", txid);
            return txid;
          })
          .then((txid) => {
            getMetrices();
            return "success";
          })
          .catch((err) => {
            console.log("Transaction processor declined tx bundle!");
            return null;
          });

        return apiCall;
      }
    }
  }
}
