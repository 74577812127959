import React from "react";
import CardGrid from "./Hive/CardGrid/CardGrid.js";
import CreateNode from "./Hive/CreateNode.js";
import { Suspense } from "react";
import Collapse from "@material-ui/core/Collapse";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import BubbleChartIcon from "@material-ui/icons/BubbleChart";
import ImportExportIcon from "@material-ui/icons/ImportExport";
import SearchIcon from "@material-ui/icons/Search";
import InputBase from "@material-ui/core/InputBase";
import LoginPopup from "../auth/LoginPopup";
import SimpleBar from "simplebar-react";
import "simplebar/dist/simplebar.min.css";
import GraphComponent from "./Hive/leftGraph";
import { Spin } from "antd";
import "antd/dist/antd.css"; // or 'antd/dist/antd.less'
import SettingsIcon from "@material-ui/icons/Settings";
import IconButton from "@material-ui/core/IconButton";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import { interpolateHcl } from "d3-interpolate";
import moment from "moment";
import WelcomeCard from "./WelcomeHive";
import TextField from "@material-ui/core/TextField";
import { Upload, message } from "antd";
import ImgCrop from "antd-img-crop";
import "antd/dist/antd.css"; // or 'antd/dist/antd.less'
import Typography from "@material-ui/core/Typography";
import { withSnackbar } from "notistack";
import EditIcon from "@material-ui/icons/Edit";
import CloseIcon from "@material-ui/icons/Close";
import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Paper from "@material-ui/core/Paper";
import { TitleComponent } from "../../partials/content/helmetComponent";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";

const firebase = require("firebase");
var maxLikes = 1;
var maxPulses = 1;
var maxClicks = 1;
var maxTrendRate = 0;
var currentLikedNodeID = undefined;
var nodeCount = 0;

var editTopicID = {
  id: "",
  index: 0,
};
var editedTopics = [];

class Hive extends React.PureComponent {
  constructor(props) {
    super(props);
    this.scrollableNodeRef = React.createRef();
    this.state = {
      nodeActive: props.match.params.hiveID.includes("&id=") ? props.match.params.hiveID.split("&id=")[1] : "",
      tabState: 1,
      tabState0: 1,
      snackOpen: false,
      hiveID: props.match.params.hiveID.includes("&id=") ? props.match.params.hiveID.split("&id=")[0] : props.match.params.hiveID,
      grid: { l1: [], l2: [] },
      hdPrivateKey: "",
      hdPublicKey: "",
      hiveNodes: [],
      addNode: false,
      loading: true,
      nodeLimit: [0, 15],
      loggedIn: "",
      openLoginPopup: false,
      user: "",
      userLikedNodes: [],
      userClickedNodes: [],
      ticks: 500,
      loadingMoreData: false,
      hiveNodesForGraph: [],
      displayConfig: false,
      configDialog: false,
      selectedType: "Open",
      hiveContent: {},
      userID: "",
      hiveCreatorID: "",
      updateEmites: false,
      addTopic: false,
      mainNodeID: "",
      hiveNameVal: "",
      hiveDesVal: "",
      hiveImageUrl: "",
      imageFile: [],
      openTopicEditDialog: false,
      topicNameVal: "",
      canModerate: false,
      helmetTitle: "Raspora",
      helmetImage: "",
      helmetDes: "",

      helmetType: "hive",
      displayUtxoSnack: false,
    };
    this.graphRef = React.createRef();
  }

  getNodeData = (hiveID, erasePreviousNodes) => {
    firebase
      .database()
      .ref("hiveCards/" + hiveID + "/node")
      .on("value", (snapshot) => {
        let hiveNodes = [];
        let hiveNodesForGraph;
        if (erasePreviousNodes) {
          hiveNodesForGraph = [];
        } else {
          hiveNodesForGraph = [...this.state.hiveNodesForGraph];
        }

        snapshot.val() &&
          Object.keys(snapshot.val()).forEach((key) => {
            let modifiedComments = snapshot.val()[key].comments;
            hiveNodes.push({
              description: snapshot.val()[key].description,
              hdPublicKey: snapshot.val()[key].hdPublicKey,
              image: snapshot.val()[key].image,
              link: snapshot.val()[key].link,
              linkedNode: snapshot.val()[key].linkedNode,
              timeStamp: snapshot.val()[key].timeStamp,
              title: snapshot.val()[key].title,
              userID: snapshot.val()[key].userID,
              nodeID: key,
              comments: modifiedComments ? modifiedComments : false,
              likes: snapshot.val()[key].likes ? snapshot.val()[key].likes : [],
              clicks: snapshot.val()[key].clicks ? snapshot.val()[key].clicks : 0,
              isTopic: snapshot.val()[key].isTopic ? snapshot.val()[key].isTopic : false,
              moderate: snapshot.val()[key].moderate ? snapshot.val()[key].moderate : false,
              archived: snapshot.val()[key].archived ? snapshot.val()[key].archived : false,
            });

            //updating helmet
            if (this.state.helmetType === "node" && key === this.state.nodeActive) {
              console.log("runn");
              this.setState({
                helmetTitle: snapshot.val()[key].title,
                helmetImage: snapshot.val()[key].image,
                helmetDes: snapshot.val()[key].description,
              });
            }

            //for likes
            if (snapshot.val()[key].likes && Object.keys(snapshot.val()[key].likes).length > maxLikes) {
              maxLikes = Object.keys(snapshot.val()[key].likes).length;
            }
            //for pulses
            if (snapshot.val()[key].pulses && Object.keys(snapshot.val()[key].pulses).length > maxPulses) {
              maxPulses = Object.keys(snapshot.val()[key].pulses).length;
            }
            //for clicks

            if (snapshot.val()[key].clicks && snapshot.val()[key].clicks > maxClicks) {
              maxClicks = snapshot.val()[key].clicks;
            }
            //for trend

            let trendRate = 0;
            if (snapshot.val()[key].timeStamp && snapshot.val()[key].likes && Object.keys(snapshot.val()[key].likes).length > 0) {
              let timDiff = Math.round(moment.duration(snapshot.val()[key].timeStamp).asMinutes());
              let totalLikes = Object.keys(snapshot.val()[key].likes).length;
              trendRate = totalLikes / timDiff;
              if (trendRate > maxTrendRate) {
                maxTrendRate = trendRate;
              }
            }
            if (hiveNodesForGraph.findIndex((x) => x.key === key) === -1) {
              hiveNodesForGraph.push({
                title: snapshot.val()[key].title,
                key: key,
                linkedNode: snapshot.val()[key].linkedNode,
                timeStamp: snapshot.val()[key].timeStamp,
                nodeID: key,
                likes: snapshot.val()[key].likes ? snapshot.val()[key].likes : [],
                clicks: snapshot.val()[key].clicks ? snapshot.val()[key].clicks : 0,
                trendRate: trendRate,
                pulses: snapshot.val()[key].pulses ? snapshot.val()[key].pulses : [],
                isTopic: snapshot.val()[key].isTopic ? snapshot.val()[key].isTopic : false,
              });
            } else {
              let objIndex = hiveNodesForGraph.findIndex((x) => x.key === key);

              hiveNodesForGraph[objIndex].title = snapshot.val()[key].title;
              hiveNodesForGraph[objIndex].linkedNode = snapshot.val()[key].linkedNode;
              hiveNodesForGraph[objIndex].timeStamp = snapshot.val()[key].timeStamp;
              hiveNodesForGraph[objIndex].likes = snapshot.val()[key].likes ? snapshot.val()[key].likes : [];
              hiveNodesForGraph[objIndex].nodeID = key;
              hiveNodesForGraph[objIndex].clicks = snapshot.val()[key].clicks ? snapshot.val()[key].clicks : 0;
              hiveNodesForGraph[objIndex].trendRate = trendRate;
              hiveNodesForGraph[objIndex].pulses = snapshot.val()[key].pulses ? snapshot.val()[key].pulses : [];
              hiveNodesForGraph[objIndex].isTopic = snapshot.val()[key].isTopic ? snapshot.val()[key].isTopic : false;
            }

            if (snapshot.val()[key].isTopic === "Main") {
              this.setState({ mainNodeID: key });
            }
          });

        this.setState(
          {
            hiveNodes: hiveNodes,
            loading: false,
            ticks: 300,
            hiveNodesForGraph: hiveNodesForGraph,
          },
          () => {
            if (this.state.nodeActive !== "") {
              this.setActiveNode(this.state.nodeActive, true);
            }
          }
        );
      });
    firebase
      .database()
      .ref("hiveCards/" + hiveID + "/userID")
      .once("value", (snapshot) => {
        if (snapshot.val()) {
          this.setState({ hiveCreatorID: snapshot.val() });
        }
      });
  };

  getHiveData = (hiveID) => {
    firebase
      .database()
      .ref("hiveCards/" + hiveID)
      .on("value", (snapshot) => {
        let hiveData = snapshot.val();
        if (hiveData) {
          this.setState({
            hiveNameVal: hiveData.title,
            hiveDesVal: hiveData.description,
            hiveImageUrl: hiveData.imageURL,
            selectedType: hiveData.type,
            userID: hiveData.userID,
            hiveContent: hiveData,
          });
          if (this.state.helmetType === "hive") {
            this.setState({ helmetTitle: hiveData.title, helmetImage: hiveData.imageURL, helmetDes: hiveData.description });
          }
        }
      });
  };

  getUserData = () => {
    firebase.auth().onAuthStateChanged((user) => {
      this.setState({ user });

      if (user) {
        this.checkAvalibilityOfNewTxoset(user.uid);
        let userID = user.uid;
        firebase
          .database()
          .ref("KeyStore/" + userID)
          .on("value", (snapshot) => {
            let hdPrivateKey = snapshot.val() && snapshot.val().hdPrivateKey ? snapshot.val().hdPrivateKey : "";
            let hdPublicKey = snapshot.val() && snapshot.val().hdPublicKey ? snapshot.val().hdPublicKey : "";
            let userLikedNodes = [];
            let userClickedNodes = [];

            if (snapshot.val() && snapshot.val().likes) {
               Object.keys(snapshot.val().likes).forEach((key) => {
                userLikedNodes.push(snapshot.val().likes[key].nodeID);
              });
            }
            if (snapshot.val() && snapshot.val().read) {
              Object.keys(snapshot.val().read).forEach((key) => {
                userClickedNodes.push(snapshot.val().read[key].nodeID);
              });
            }
            if (snapshot.val() && snapshot.val().hives && snapshot.val().hives[this.state.hiveID]) {
              this.setState({
                displayConfig: true,
                canModerate: true,
              });
            } else {
              //checking for admin status
              firebase
                .database()
                .ref("admin/" + userID)
                .once("value")
                .then((snapshot) => {
                  if (snapshot.val()) {
                    this.setState({
                      displayConfig: true,
                      canModerate: true,
                    });
                  }
                });
            }
            this.setState({
              userLikedNodes,
              userClickedNodes,
              hdPrivateKey: hdPrivateKey,
              hdPublicKey: hdPublicKey,
              loggedIn: true,
            });
          });
      } else {
        this.setState({ loggedIn: false });
      }
    });
  };

  componentDidMount() {
    maxLikes = 1;
    maxPulses = 1;
    maxClicks = 1;
    maxTrendRate = 0;
    currentLikedNodeID = undefined;
    nodeCount = 0;
    // console.log("nodeActive", this.state.nodeActive);
    if (this.state.nodeActive !== "") {
      this.setState({ helmetType: "node" });
    }
    this.getNodeData(this.state.hiveID);
    this.getHiveData(this.state.hiveID);
    this.getUserData();
    this.scrollableNodeRef.current.addEventListener("scroll", this.handleScrollMoreData);
  }

  componentDidUpdate(prevProps) {
    if (this.props.match.params.hiveID !== prevProps.match.params.hiveID) {
      this.setActiveNode(this.props.match.params.hiveID.split("&id=")[1], true);

      // if (this.props.match.params.hiveID.split("&id=")[1]) {
      //   this.setState({ helmetType: "node" });
      // } else {
      //   this.setState({ helmetType: "hive" });
      // }
      if (this.props.match.params.hiveID && prevProps.match.params.hiveID && this.props.match.params.hiveID.split("&id=")[0] !== prevProps.match.params.hiveID.split("&id=")[0]) {
        this.setState({
          hiveID: this.props.match.params.hiveID.split("&id=")[0],
        });
        this.getNodeData(this.props.match.params.hiveID.split("&id=")[0], true);
        this.getHiveData(this.props.match.params.hiveID.split("&id=")[0]);
        this.getUserData();
      }
    }
  }

  handleScrollMoreData = (event) => {
    let maxScroll = event.target.scrollHeight - event.target.clientHeight;
    let currentScroll = event.target.scrollTop;
    if (currentScroll + 2 >= maxScroll) {
      if (this.state.hiveNodes.length > this.state.nodeLimit[1] && this.state.loadingMoreData === false) {
        this.setState({ loadingMoreData: true });
        setTimeout(() => {
          this.setState({ loadingMoreData: false, nodeLimit: [0, this.state.nodeLimit[1] + 15] });
        }, 70);
      }
    }
  };

  componentWillUnmount() {
    this.scrollableNodeRef.current.removeEventListener("scroll", this.handleScrollMoreData);
  }

  searchElement(nameKey, myArray) {
    let finalArray = [];
    for (var i = 0; i < myArray.length; i++) {
      if (myArray[i].title.toLowerCase().includes(nameKey.toLowerCase()) || myArray[i].description.toLowerCase().includes(nameKey.toLowerCase())) {
        finalArray.push(myArray[i]);
      }
    }
    return finalArray;
  }

  sortByFunction() {
    let { hiveNodes, tabState, tabState0, nodeActive, searchTerm } = this.state;

    let sortedHiveNodes = [...hiveNodes];
    sortedHiveNodes = sortedHiveNodes.filter((obj) => obj.isTopic === false);
    if (searchTerm) {
      return this.searchElement(searchTerm, sortedHiveNodes);
    }
    if (nodeActive) {
      return sortedHiveNodes.sort((a, b) => this.cardOrder(a, b));
    }

    if (tabState0 === 1) {
      return sortedHiveNodes.sort((a, b) => this.sortByPopular(a, b));
    } else if (tabState0 === 2) {
      return sortedHiveNodes.sort((a, b) => this.sortByTime(a, b));
    } else if (tabState0 === 3) {
      const { userLikedNodes } = this.state;
      return sortedHiveNodes.filter((item) => userLikedNodes.includes(item.nodeID));
    }
  }

  nodeSearch(e) {
    var term = e.target.value;
    this.setState({
      hiveNodes: this.state.hiveNodes.sort((a, b) => (b.title.toLowerCase().includes(term.toLowerCase()) | b.description.toLowerCase().includes(term.toLowerCase()) ? 1 : -1)),
    });
  }

  sortByTime(a, b) {
    return b.timeStamp - a.timeStamp;
  }

  sortByPopular(a, b) {
    const aLength = Object.keys(a.likes).length;
    const bLength = Object.keys(b.likes).length;
    return aLength > bLength ? -1 : 1;
  }

  setActiveNode(nodeID, queryparam = false) {
    if (nodeID !== this.state.nodeActive || queryparam) {
      if (!queryparam) {
        this.setState({ ticks: 0 });
      }
      let grid = { l1: [], l2: [] };
      grid.l1.push(nodeID);
      this.state.hiveNodes.filter((x) => x.linkedNode === nodeID).map((x) => grid.l2.push(x.nodeID));
      this.state.hiveNodes.filter((x) => x.nodeID === nodeID).map((x) => grid.l2.push(x.linkedNode));
      this.setState({ grid, nodeActive: nodeID });
      this.scrollableNodeRef.current.scrollTo({ top: 0, behavior: "smooth" });
    }
  }

  cardOrder(a, b) {
    let { grid } = this.state;
    let compare;
    if (grid.l1.includes(a.nodeID)) {
      compare = -1;
    } else if (grid.l2.includes(a.nodeID) && !grid.l2.includes(b.nodeID) && !grid.l1.includes(b.nodeID)) {
      compare = -1;
    } else {
      compare = 0;
    }
    return compare;
  }

  handleLoginPopup = (state) => {
    this.setState({ openLoginPopup: state });
  };

  handleRadioChange = (event) => {
    this.setState({ selectedType: event.target.value });
  };

  configSubmit = () => {
    //upload image to firebase storage
    if (this.state.imageFile[0]) {
      let storageRef = firebase
        .app()
        .storage("gs://hivedb-cdbf8")
        .ref();
      let uploadTask = storageRef.child("hives_images/" + this.state.hiveID);
      uploadTask.put(this.state.imageFile[0].originFileObj);
    }
    let contents = {};

    // if (this.state.selectedType !== this.state.hiveContent.type) {
    let updateHiveContent = { ...this.state.hiveContent };
    updateHiveContent.type = this.state.selectedType;
    updateHiveContent.title = this.state.hiveNameVal;
    updateHiveContent.description = this.state.hiveDesVal;
    // contents["KeyStore/" + this.state.userID + "/hives/" + this.state.hiveID] = updateHiveContent;
    contents["hiveCards/" + this.state.hiveID + "/description"] = this.state.hiveDesVal;
    contents["hiveCards/" + this.state.hiveID + "/title"] = this.state.hiveNameVal;
    contents["hiveCards/" + this.state.hiveID + "/type"] = this.state.selectedType;

    if (this.state.selectedType === "Open") {
      delete updateHiveContent.node;
      updateHiveContent.title = this.state.hiveNameVal;
      updateHiveContent.description = this.state.hiveDesVal;
      contents["hives/" + this.state.hiveID] = updateHiveContent;
    } else {
      contents["hives/" + this.state.hiveID] = null;
    }
    //  }
    // else {
    //   contents["hiveCards/" + this.state.hiveID + "/description"] = this.state.hiveDesVal;
    //   contents["hiveCards/" + this.state.hiveID + "/title"] = this.state.hiveNameVal;
    //   if (this.state.selectedType === "Open") {
    //     contents["hives/" + this.state.hiveID + "/description"] = this.state.hiveDesVal;
    //     contents["hives/" + this.state.hiveID + "/title"] = this.state.hiveNameVal;
    //   }
    // }

    //updating Topic Name
    if (editedTopics.length > 0) {
      editedTopics.map((a, b) => {
        if (a.name === true) {
          contents["hiveCards/" + this.state.hiveID + "/node/" + a.id] = null;
        } else {
          contents["hiveCards/" + this.state.hiveID + "/node/" + a.id + "/title"] = a.name;
        }
      });
    }
    firebase
      .database()
      .ref()
      .update(contents);
    this.setState({ configDialog: false });
    this.props.enqueueSnackbar("Hive Successfully Updated", { variant: "success" });
  };

  nodeColor = (node) => {
    if (node.nodeID === this.state.nodeActive) {
      return "green";
    } else {
      if (this.state.tabState === 1) {
        let interColor = interpolateHcl("white", "#ff4000");
        return interColor(Math.log(Object.keys(node.likes).length + 0.01) / (Math.log(maxLikes) === 0 ? 1 : Math.log(maxLikes)));
      } else if (this.state.tabState === 3) {
        let interColor = interpolateHcl("white", "#ff4000");
        return interColor(Math.log(node.clicks + 0.01) / (Math.log(maxClicks) === 0 ? 1 : Math.log(maxClicks)));
      } else if (this.state.tabState === 2) {
        let interColor = interpolateHcl("white", "#ff4000");
        if (maxTrendRate === 0) {
          maxTrendRate = 1;
        }
        return interColor(node.trendRate / maxTrendRate);
      } else if (this.state.tabState === 4) {
        let interColor = interpolateHcl("white", "#ff0080");
        return interColor(Math.log(Object.keys(node.pulses).length + 0.01) / (Math.log(maxPulses) === 0 ? 1 : Math.log(maxPulses)));
      } else {
        return "white";
      }
    }
  };

  onLikeClick = (nodeID) => {
    currentLikedNodeID = nodeID;
    nodeCount = 0;
  };

  onLikeClickNode = (node) => {
    if (currentLikedNodeID) {
      if (
        node.nodeID === currentLikedNodeID ||
        node.linkedNode === currentLikedNodeID ||
        (node.source && node.source.nodeID && node.source.nodeID === currentLikedNodeID) ||
        // || node.source.linkedNode === currentLikedNodeID
        (node.target && node.target.nodeID && node.target.nodeID === currentLikedNodeID)
        // || node.target.linkedNode === currentLikedNodeID
      ) {
        if (nodeCount === 0) {
          nodeCount = 1;
          let currentLikeId = currentLikedNodeID;
          setTimeout(() => {
            if (currentLikeId === currentLikedNodeID) {
              currentLikedNodeID = undefined;
              nodeCount = 0;
              this.setState({
                updateEmites: !this.state.updateEmites,
              });
            }
          }, 5000);
        } else {
        }

        return 6;
      } else {
        return 2;
      }
    } else {
      return 2;
    }
  };

  nodeParticlesRate = (node) => {
    if (currentLikedNodeID) {
      if (
        node.nodeID === currentLikedNodeID ||
        node.linkedNode === currentLikedNodeID ||
        (node.source && node.source.nodeID && node.source.nodeID === currentLikedNodeID) ||
        // || node.source.linkedNode === currentLikedNodeID
        (node.target && node.target.nodeID && node.target.nodeID === currentLikedNodeID)
        // || node.target.linkedNode === currentLikedNodeID
      ) {
        return 6;
      } else {
        return Object.keys(node.likes).length * 0.5;
      }
    } else {
      return 2;
    }
  };

  dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };

  onPreview = async (file) => {
    let src = file.url;
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow.document.write(image.outerHTML);
  };

  onChangeImage = ({ fileList: newFileList }) => {
    if (newFileList[0] && newFileList[0].size > 625000) {
      message.error("Image must smaller than 5MB!");
    } else {
      this.setState({ imageFile: newFileList });
    }
  };

  updateTopicName = () => {
    let modifiedNodeList = [...this.state.hiveNodesForGraph];
    let obj = modifiedNodeList.find((o) => o.nodeID === editTopicID.id);
    let itemIndex = modifiedNodeList.indexOf(obj);
    if (itemIndex !== -1) {
      modifiedNodeList[itemIndex].title = this.state.topicNameVal;
    }
    this.setState({
      hiveNodesForGraph: modifiedNodeList,
      openTopicEditDialog: false,
    });
    if (!editedTopics.find((o) => o.id === editTopicID.id)) {
      editedTopics.push({
        id: editTopicID.id,
        name: this.state.topicNameVal,
      });
    } else {
      editedTopics[editedTopics.indexOf(editedTopics.find((o) => o.id === editTopicID.id))].name = this.state.topicNameVal;
    }
  };

  deleteTopicName = () => {
    let modifiedNodeList = [...this.state.hiveNodesForGraph];
    let obj = modifiedNodeList.find((o) => o.nodeID === editTopicID.id);
    let itemIndex = modifiedNodeList.indexOf(obj);
    if (itemIndex !== -1) {
      modifiedNodeList.splice(itemIndex, 1);
      this.setState({
        hiveNodesForGraph: modifiedNodeList,
      });
    }

    if (!editedTopics.find((o) => o.id === editTopicID.id)) {
      editedTopics.push({
        id: editTopicID.id,
        name: true,
      });
    } else {
      editedTopics[editedTopics.indexOf(editedTopics.find((o) => o.id === editTopicID.id))].name = true;
    }
  };

  checkAvalibilityOfNewTxoset = async (userid_) => {
    let getMetrices = firebase.functions().httpsCallable("getMetrices");
    let userWalletInfo = await getMetrices();
    let txoSet = userWalletInfo.data.metrices.txoSet.sort((a, b) => b.amount - a.amount);
    let filteredTxoValue = 0;
    let outputAmount = 0;

    let prevUsedTxos = await firebase
      .database()
      .ref("KeyStore/" + userid_ + "/usedTxos")
      .once("value")
      .then((snapshot) => {
        return snapshot.val();
      })
      .then((res) => {
        prevUsedTxos = res;
        return prevUsedTxos;
      });
    console.log("userWalletInfo: ", userWalletInfo);
    console.log("prevUsedTxos: ", prevUsedTxos);
    console.log("txoSet1", txoSet);

    txoSet =  prevUsedTxos && txoSet.filter((x) => !prevUsedTxos.some((y) => x.txid === y.txid && x.vout === y.vout));
    prevUsedTxos && txoSet.filter((x) => (filteredTxoValue += x.value));
    // output.filter((x) => (outputAmount += x.value));

    console.log("txoSet2", txoSet);
    if (txoSet && txoSet.length > 0) {
      this.setState({ displayUtxoSnack: false });
    } else {
      this.setState({ displayUtxoSnack: true });
      setTimeout(() => {
        this.checkAvalibilityOfNewTxoset(userid_);
      }, 2000);
    }
  };

  render() {
    console.log("displayUtxoSnack", this.state.displayUtxoSnack);
    let linkData = [...this.state.hiveNodesForGraph];
    Object.keys(linkData).forEach((key) => linkData[key].linkedNode === "" && delete linkData[key]);
    linkData = linkData.filter((value) => {
      return Object.keys(value).length !== 0;
    });

    const nodeGraphData = {
      nodes: this.state.hiveNodesForGraph,
      links: linkData,
    };

    const addNodeDiv =
      this.state.hdPublicKey === "" ? (
        <></>
      ) : (
        <div style={{ overflow: "hidden" }}>
          <Collapse in={this.state.addNode}>
            <CreateNode
              hdPublicKey={this.state.hdPublicKey}
              hdPrivateKey={this.state.hdPrivateKey}
              hiveNodes={this.state.hiveNodes}
              hiveID={this.state.hiveID}
              activeNode={this.state.nodeActive}
              image="https://picsum.photos/350/150?random=1"
              hiveCreatorID={this.state.hiveCreatorID}
              closeAddNodePannel={() => this.setState({ addNode: false })}
              checkAvalibilityOfNewTxoset={this.checkAvalibilityOfNewTxoset}
            />
          </Collapse>
          <Collapse in={this.state.addTopic}>
            <CreateNode
              hdPublicKey={this.state.hdPublicKey}
              hdPrivateKey={this.state.hdPrivateKey}
              hiveNodes={this.state.hiveNodes}
              hiveID={this.state.hiveID}
              activeNode={this.state.nodeActive !== "" ? this.state.nodeActive : this.state.mainNodeID}
              image="https://picsum.photos/350/150?random=1"
              hiveCreatorID={this.state.hiveCreatorID}
              addTopic={true}
              closeAddTopicPannel={() => this.setState({ addTopic: false })}
            />
          </Collapse>
        </div>
      );

    const nodeCardsLoading = [...Array(6)].map((entry, index) => (
      <Suspense key={"suspense" + index} fallback={<div>Loading...</div>}>
        <CardGrid index={index} loading={true} explore={false} display={"loading"} comments={false} />
      </Suspense>
    ));
    let nodeData = this.sortByFunction().slice(this.state.nodeLimit[0], this.state.nodeLimit[1]);
    let nodeCardsActive =
      nodeData.length > 0 ? (
        nodeData.map((entry, index) => {
          if (entry.isTopic === false) {
            if (this.state.canModerate === true) {
              return (
                <Suspense key={"subsuspense" + index} fallback={<div>Loading...</div>}>
                  <CardGrid
                    loading={false}
                    explore={false}
                    user={this.state.user}
                    liked={this.state.userLikedNodes.includes(entry.nodeID)}
                    clickedStatus={this.state.userClickedNodes.includes(entry.nodeID)}
                    hiveID={this.state.hiveID}
                    hiveNodes={this.state.hiveNodes}
                    title={entry.title}
                    link={entry.link}
                    description={entry.description}
                    display="comments"
                    hdPublicKey={this.state.hdPublicKey}
                    hdPrivateKey={this.state.hdPrivateKey}
                    key={"cardGrid" + index}
                    image={entry.image}
                    id={entry.nodeID}
                    comments={entry.comments}
                    nodeCreatorUserID={entry.userID}
                    handleLoginPopup={this.handleLoginPopup}
                    hiveCreatorID={this.state.hiveCreatorID}
                    likeClicked={this.onLikeClick}
                    canModerate={this.state.canModerate}
                    moderateStatus={entry.moderate}
                    index={index}
                    archived={entry.archived ? entry.archived : false}
                    checkAvalibilityOfNewTxoset={this.checkAvalibilityOfNewTxoset}
                  />
                </Suspense>
              );
            } else if (entry.moderate === false) {
              return (
                <Suspense key={"subsuspense" + index} fallback={<div>Loading...</div>}>
                  <CardGrid
                    loading={false}
                    explore={false}
                    user={this.state.user}
                    liked={this.state.userLikedNodes.includes(entry.nodeID)}
                    clickedStatus={this.state.userClickedNodes.includes(entry.nodeID)}
                    hiveID={this.state.hiveID}
                    hiveNodes={this.state.hiveNodes}
                    title={entry.title}
                    link={entry.link}
                    description={entry.description}
                    display="comments"
                    hdPublicKey={this.state.hdPublicKey}
                    hdPrivateKey={this.state.hdPrivateKey}
                    key={"cardGrid" + index}
                    image={entry.image}
                    id={entry.nodeID}
                    comments={entry.comments}
                    nodeCreatorUserID={entry.userID}
                    handleLoginPopup={this.handleLoginPopup}
                    hiveCreatorID={this.state.hiveCreatorID}
                    likeClicked={this.onLikeClick}
                    canModerate={this.state.canModerate}
                    moderateStatus={entry.moderate}
                    index={index}
                    archived={entry.archived ? entry.archived : false}
                    checkAvalibilityOfNewTxoset={this.checkAvalibilityOfNewTxoset}
                  />
                </Suspense>
              );
            }
          }
        })
      ) : (
        <>
          {(() => {
            let topicExist = this.state.hiveNodes.filter((obj) => obj.isTopic !== false).length;

            if (topicExist === this.state.hiveNodes.length) {
              return <WelcomeCard openAddTopicPannel={() => this.setState({ addTopic: true })} />;
            }
          })()}
        </>
      );
    const nodeCards = this.state.loading ? nodeCardsLoading : nodeCardsActive;

    const rightSide = (
      <Grid
        item
        xs={12}
        sm={5}
        style={{
          backgroundColor: "rgb(255,255,255)",
          height: "100%",
          id: "RightSide",
          paddingLeft: 0,
        }}
      >
        <Grid
          container
          spacing={0}
          style={{
            zIndex: 100,
            position: "sticky",
            // maxWidth: "inherit",
            backgroundColor: "rgb(255,255,255)",
            display: "flex",
          }}
        >
          <Grid item>
            <div
              style={{
                flexGrow: 1,
                backgroundColor: "#FFFFFF",
                background: this.state.addNode === false ? "white" : "linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)",
                boxShadow: this.state.addNode === false ? null : "0 3px 5px 2px rgba(255, 105, 135, .3)",
              }}
            >
              <Button
                disabled={this.state.loggedIn === false}
                style={{
                  height: 48,
                  width: "100%",
                  paddingLeft: 25,
                  paddingRight: 25,
                  boxShadow: `none`,
                }}
                onClick={() => this.setState({ addNode: !this.state.addNode })}
              >
                Add Node
              </Button>
            </div>
          </Grid>
          {this.state.displayConfig && (
            <>
              <Grid item>
                <div
                  style={{
                    flexGrow: 1,
                    backgroundColor: "#FFFFFF",
                    background: this.state.addTopic === false ? "white" : "linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)",
                    boxShadow: this.state.addTopic === false ? null : "0 3px 5px 2px rgba(255, 105, 135, .3)",
                  }}
                >
                  <Button
                    disabled={this.state.loggedIn === false}
                    style={{
                      height: 48,
                      width: "100%",
                      paddingLeft: 25,
                      paddingRight: 25,
                      boxShadow: `none`,
                    }}
                    onClick={() => this.setState({ addTopic: !this.state.addTopic })}
                  >
                    Add Topic
                  </Button>
                </div>
              </Grid>
              <Grid item style={{ marginLeft: 10 }}>
                <IconButton
                  aria-label="hive-settings"
                  onClick={() => {
                    editedTopics = [];
                    this.setState({ configDialog: true });
                  }}
                >
                  <SettingsIcon />
                </IconButton>
              </Grid>
            </>
          )}
          <Grid item style={{ marginLeft: 10 }}>
            <ImportExportIcon style={{ height: 48, width: 20, marginRight: 5 }} />
            <Select
              value={this.state.tabState0}
              disableUnderline={true}
              style={{ height: 48, width: 80 }}
              onChange={(e) => this.setState({ tabState0: e.target.value, nodeActive: "" })}
            >
              <MenuItem value={1}>Popular</MenuItem>
              <MenuItem value={2}>Recent</MenuItem>
              <MenuItem value={3}>Shortlist</MenuItem>
            </Select>
          </Grid>
          <Grid item style={{ marginLeft: 10 }}>
            <BubbleChartIcon style={{ height: 48, width: 20, marginRight: 5 }} />
            <Select
              value={this.state.tabState}
              disableUnderline={true}
              style={{ height: 48, width: 80 }}
              onChange={(e) => this.setState({ tabState: e.target.value, nodeActive: "" })}
            >
              <MenuItem value={1}>Likes</MenuItem>
              <MenuItem value={2}>Trending</MenuItem>
              <MenuItem value={3}>Clicks</MenuItem>
              <MenuItem value={4}>Pulses</MenuItem>
            </Select>
          </Grid>
          <Grid item style={{ marginLeft: 10, display: "flex", alignItems: "center" }}>
            <SearchComponent
              updateState={(val) => {
                this.setState({ searchTerm: val });
              }}
            />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <div>{addNodeDiv}</div>
        </Grid>

        <Grid item xs={12} style={{ height: "100%", paddingLeft: 0, backgroundColor: "rgb(0,0,0)" }}>
          <SimpleBar scrollableNodeProps={{ ref: this.scrollableNodeRef }} direction={"rtl"} data-simplebar-direction="rtl" style={{ height: "100%", marginTop: 2 }}>
            <Spin
              style={{
                position: "fixed",
                top: "50%",
                left: "80%",
                width: "200px",
                transform: "translate(-50%, -50%)",
              }}
              spinning={this.state.loadingMoreData}
            >
              <div style={{ marginBottom: "10px" }}>{nodeCards}</div>
            </Spin>
          </SimpleBar>
        </Grid>
      </Grid>
    );

    let leftSide = (
      <GraphComponent
        refProp={this.graphRef}
        nodeActive={this.state.nodeActive}
        graphData={nodeGraphData}
        onBackgroundClick={() => this.setState({ nodeActive: "" })}
        onNodeClick={(node) => {
          if (node.title !== "Main") {
            this.setActiveNode(node.nodeID);
          }
        }}
        nodeColor={this.nodeColor}
        cooldownTicks={this.state.ticks}
        nodeWidth={this.onLikeClickNode}
        nodeParticlesRate={this.nodeParticlesRate}
      />
    );

    const editTopicNameDialog = (
      <Dialog
        fullWidth={true}
        maxWidth="xs"
        open={this.state.openTopicEditDialog}
        onClose={() => {
          this.setState({ openTopicEditDialog: false });
        }}
        aria-labelledby="topicedit-dialog-title"
        aria-describedby="topicedit-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Edit Topic Name</DialogTitle>
        <DialogContent>
          <TextField id="standard-basic1" label="Topic Name" value={this.state.topicNameVal} onChange={(e) => this.setState({ topicNameVal: e.target.value })} />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              this.setState({ openTopicEditDialog: false });
            }}
            color="primary"
          >
            Cancel
          </Button>
          <Button onClick={this.updateTopicName} color="primary" autoFocus>
            Update
          </Button>
        </DialogActions>
      </Dialog>
    );

    const configDialog = (
      <Dialog
        onClose={() => {
          this.setState({ configDialog: false });
        }}
        aria-labelledby="simple-dialog-config"
        open={this.state.configDialog}
        fullWidth={true}
        maxWidth="md"
        style={{ zIndex: 100 }}
      >
        <DialogTitle id="simple-dialog-config">Hive Settings</DialogTitle>
        <DialogContent style={{ padding: "10px 30px" }}>
          <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            <div style={{ display: "flex", flexDirection: "column", width: "30%" }}>
              <TextField
                style={{ marginBottom: 20 }}
                id="standard-basic1"
                label="Hive Name"
                value={this.state.hiveNameVal}
                onChange={(e) => this.setState({ hiveNameVal: e.target.value })}
              />
              <TextField
                style={{ marginBottom: 20 }}
                id="standard-basic2"
                label="Hive Description"
                value={this.state.hiveDesVal}
                onChange={(e) => this.setState({ hiveDesVal: e.target.value })}
              />
            </div>
            <div style={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
              <Typography style={{ marginBottom: 6 }} variant="caption" color="textSecondary">
                Edit Hive Image
              </Typography>
              <ImgCrop rotate aspect={1.5 / 1}>
                <Upload customRequest={this.dummyRequest} listType="picture-card" fileList={this.state.imageFile} onChange={this.onChangeImage} onPreview={this.onPreview}>
                  {this.state.imageFile.length < 1 && <img style={{ height: "auto", width: "inherit" }} src={this.state.hiveImageUrl} />}
                </Upload>
              </ImgCrop>
            </div>
            <div style={{ width: "30%", display: "flex", justifyContent: "center" }}>
              <FormControl style={{ width: "100%" }} component="fieldset">
                <FormLabel component="legend">Type:</FormLabel>
                <RadioGroup
                  style={{ width: "100%", display: "flex", justifyContent: "space-between" }}
                  row
                  aria-label="type"
                  name="type"
                  value={this.state.selectedType}
                  onChange={this.handleRadioChange}
                >
                  <FormControlLabel labelPlacement="start" value="Open" control={<Radio />} label="Open" />
                  <FormControlLabel labelPlacement="start" value="Team" control={<Radio />} label="Team" />
                  <FormControlLabel labelPlacement="start" value="Private" control={<Radio />} label="Private" />
                </RadioGroup>
              </FormControl>
            </div>
          </div>
          <div style={{ marginTop: 20 }}>
            <Typography style={{ marginBottom: 6 }} variant="caption" color="textSecondary">
              Edit Hive Topics
            </Typography>
            <div>
              <PerfectScrollbar style={{ maxHeight: "250px" }}>
                {[...this.state.hiveNodesForGraph.filter((obj) => obj.isTopic === true)].map((item, index) => {
                  let showDelIcon = true;
                  this.state.hiveNodesForGraph.filter((obj) => {
                    if (obj.linkedNode === item.nodeID) {
                      showDelIcon = false;
                    }
                  });

                  return (
                    <div
                      key={"topic-dialog" + index}
                      style={{
                        borderBottom: "0.2px solid #d8d8d8",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <Typography variant="subtitle1">{item.title}</Typography>
                      <div>
                        {showDelIcon && (
                          <IconButton
                            onClick={() => {
                              editTopicID.id = item.nodeID;
                              this.deleteTopicName();
                            }}
                            color="secondary"
                            aria-label="close"
                          >
                            <CloseIcon fontSize="small" />
                          </IconButton>
                        )}
                        <IconButton
                          color="secondary"
                          aria-label="edit"
                          onClick={() => {
                            this.setState({
                              openTopicEditDialog: true,
                              topicNameVal: item.title,
                            });
                            editTopicID.id = item.nodeID;
                          }}
                        >
                          <EditIcon fontSize="small" />
                        </IconButton>
                      </div>
                    </div>
                  );
                })}
              </PerfectScrollbar>
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              this.setState({ configDialog: false });
            }}
            color="primary"
          >
            Cancel
          </Button>
          <Button onClick={this.configSubmit} color="primary" autoFocus>
            Update
          </Button>
        </DialogActions>
        {editTopicNameDialog}
      </Dialog>
    );

    return (
      <div style={{ height: "100%" }}>
        <TitleComponent title={this.state.helmetTitle} image={this.state.helmetImage} desc={this.state.helmetDes} />
        <Snackbar anchorOrigin={{ vertical: "bottom", horizontal: "left" }} open={this.state.displayUtxoSnack}>
          <Alert severity="info">Updating UTXOs...</Alert>
        </Snackbar>
        <div style={{ height: window.innerHeight - 88 }}>
          <Grid container spacing={0} style={{ height: "100%", overflow: "hidden" }}>
            <LoginPopup openLoginPopup={this.state.openLoginPopup} setLoginPopup={this.handleLoginPopup} />
            {leftSide}

            {rightSide}
          </Grid>
        </div>
        {configDialog}
      </div>
    );
  }
}

export default withSnackbar(Hive);

function SearchComponent(props) {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));
  const [open, setOpen] = React.useState(false);
  const [SearchVal, setSearchVal] = React.useState("");
  if (matches) {
    return (
      <>
        <Button style={{ marginBottom: 5 }} onClick={() => setOpen(true)} variant="contained" color="primary" size="small" startIcon={<SearchIcon />}>
          Search
        </Button>
        <Dialog fullWidth={true} maxWidth="xs" open={open} onClose={() => setOpen(false)} aria-labelledby="search-dialog-title" aria-describedby="search-dialog-description">
          <DialogTitle id="alert-dialog-title">Search Node</DialogTitle>
          <DialogContent>
            <SearchIcon style={{ height: 48, width: 20, marginRight: 5 }} />
            <InputBase
              placeholder="Search…"
              inputProps={{ "aria-label": "search" }}
              style={{ width: "80%", height: 48 }}
              onKeyPress={(e) => {
                e.key === "Enter" && props.updateState(e.target.value);
              }}
              onChange={(e) => setSearchVal(e.target.value)}
              value={SearchVal}
            />
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                props.updateState(SearchVal);
                setOpen(false);
              }}
              color="primary"
            >
              Search
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  } else {
    return (
      <>
        <SearchIcon style={{ height: 48, width: 18, marginRight: 5 }} />
        <InputBase
          placeholder="Search…"
          inputProps={{ "aria-label": "search" }}
          style={{ width: 100, height: 48 }}
          onKeyPress={(e) => {
            e.key === "Enter" && props.updateState(e.target.value);
          }}
        />
      </>
    );
  }
}

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
