import React, { useState, useEffect } from "react";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";

const firebase = require("firebase");

function PostImage(props) {
  const [userImgUrl, setuserImgUrl] = useState("");
  const [userScore, setuserScore] = useState(0);

  useEffect(() => {
    if (props.userID) {
      let url = "https://firebasestorage.googleapis.com/v0/b/hivedb-cdbf7.appspot.com/o/profile_images%2F*_100x100?alt=media&token=3aabfdad-1402-4a6c-8074-d8ea6edd7c0d";
      let imgUrl = url.slice(0, url.indexOf("*")) + props.userID + url.slice(url.indexOf("*") + 1, url.length);
      setuserImgUrl(imgUrl);

      //fetching userscore
      firebase
        .database()
        .ref("/score/" + props.userID + "/score")
        .once("value")
        .then((snapshot) => {
          if (snapshot.val()) {
            setuserScore(snapshot.val());
          }
        });
    }
  }, [props.userID]);

  return (
    <ListItemAvatar>
      <div>
        <Avatar variant="square" src={userImgUrl} />
        <p
          style={{
            fontSize: 9,
            width: 40,
            height: 20,
            textAlign: "centered !important",
            backgroundColor: "#f0fcf3",
            padding: 4,
          }}
        >
          <span> {userScore} </span>
        </p>
      </div>
    </ListItemAvatar>
  );
}

export default PostImage;
