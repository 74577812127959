/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { Link } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import { connect } from "react-redux";
import { toAbsoluteUrl } from "../../../_metronic";
import HeaderDropdownToggle from "../content/CustomDropdowns/HeaderDropdownToggle";
import Button from "@material-ui/core/Button";
import { updateProfilePicFunc } from "../../store/ducks/auth.duck";
var firebase = require("firebase");

class UserProfile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userImage: null,
      userName: "",
      userBalance: 0,
      bsvPrice: 100,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.updateProfilePic !== this.props.updateProfilePic) {
      if (this.props.updateProfilePic === true) {
        this.getUserData(this.props.profilePicUrl);
        this.props.updateProfilePicFunc({ type: false, url: "" });
      }
    }
  }

  componentDidMount() {
    this.getUserData();

    //fetching price data
    firebase
      .database()
      .ref("stats/market_price_usd")
      .once("value")
      .then((snapshot) => {
        if (snapshot.val()) {
          this.setState({
            bsvPrice: snapshot.val(),
          });
        }
      });
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        firebase
          .database()
          .ref("KeyStore/" + user.uid + "/balance")
          .on("value", (snapshot) => {
            if (snapshot.val()) {
              this.setState({
                userBalance: snapshot.val(),
              });
            }
          });
      }
    });
  }

  getUserData = (url) => {
    if (url) {
      this.setState({
        userImage: url,
      });
    } else {
      let user = firebase.auth().currentUser;
      this.setState({
        userName: user.displayName,
        userImage: user.photoURL ? user.photoURL : null,
      });
    }
  };

  render() {
    let showBadge = false;
    let showAvatar = true;
    let showHi = true;
    if (this.state.userImage) {
      showBadge = false;
      showAvatar = true;
    } else {
      showBadge = true;
      showAvatar = false;
    }

    return (
      <Dropdown className="kt-header__topbar-item kt-header__topbar-item--user" drop="down" alignRight>
        <Dropdown.Toggle as={HeaderDropdownToggle} id="dropdown-toggle-user-profile">
          {showHi && <span className="kt-header__topbar-welcome kt-hidden-mobile">Hi,</span>}

          {showHi && <span className="kt-header__topbar-username kt-hidden-mobile">{this.state.userName && this.state.userName}</span>}

          {showAvatar && <img alt="Pic" src={this.state.userImage} />}

          {showBadge && (
            <span className="kt-badge kt-badge--username kt-badge--unified-success kt-badge--lg kt-badge--rounded kt-badge--bold kt-hidden-">
              {/* TODO: Should get from currentUser */}
              {this.state.userName && this.state.userName[0]}
            </span>
          )}
        </Dropdown.Toggle>
        <Dropdown.Menu className="dropdown-menu dropdown-menu-fit dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-xl">
          {/** ClassName should be 'dropdown-menu dropdown-menu-fit dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-xl' */}
          <div
            className="kt-user-card kt-user-card--skin-dark kt-notification-item-padding-x"
            style={{
              backgroundImage: `url(${toAbsoluteUrl("/media/bg/bg-1.jpg")})`,
            }}
          >
            {(() => {
              if (!this.state.userImage) {
                return (
                  <div className="kt-user-card__avatar">
                    <span className="kt-badge kt-badge--lg kt-badge--rounded kt-badge--bold kt-font-success">{this.state.userName && this.state.userName[0]}</span>
                  </div>
                );
              } else {
                return (
                  <div className="kt-user-card__avatar">
                    <img src={this.state.userImage} alt="Pic" />
                  </div>
                );
              }
            })()}

            <div className="kt-user-card__name"> {this.state.userName && this.state.userName}</div>
            <div className="kt-user-card__badge">
              <span className="btn btn-success btn-sm btn-bold btn-font-md">$ {((this.state.userBalance * this.state.bsvPrice) / 100000000).toFixed(2)}</span>
            </div>
          </div>
          <div className="kt-notification">
            <Link to="/profile" className="kt-notification__item">
              <div className="kt-notification__item-icon">
                <i className="flaticon2-calendar-3 kt-font-success" />
              </div>
              <div className="kt-notification__item-details">
                <div className="kt-notification__item-title kt-font-bold">My Profile</div>
                <div className="kt-notification__item-time">Account settings, wallet and more</div>
              </div>
            </Link>
            <Link to="/report" className="kt-notification__item">
              <div className="kt-notification__item-icon">
                <i className="flaticon-danger kt-font-success" />
              </div>
              <div className="kt-notification__item-details">
                <div className="kt-notification__item-title kt-font-bold">Report a Bug</div>
                <div className="kt-notification__item-time">And earn rewards</div>
              </div>
            </Link>
            <Link to="/terms" className="kt-notification__item">
              <div className="kt-notification__item-icon">
                <i
                  className="flaticon-interface-11
 kt-font-success"
                />
              </div>
              <div className="kt-notification__item-details">
                <div className="kt-notification__item-title kt-font-bold">Terms and Contitions</div>
                <div className="kt-notification__item-time">Our Policies</div>
              </div>
            </Link>

            <div className="kt-notification__custom">
              <Button className="btn btn-label-brand btn-sm btn-bold" onClick={this.props.logOutTrigger}>
                Sign Out
              </Button>
            </div>
          </div>
        </Dropdown.Menu>
      </Dropdown>
    );
  }
}

const mapStateToProps = ({ auth: { user, updateProfilePic, profilePicUrl } }) => ({
  user,
  updateProfilePic,
  profilePicUrl,
});

export default connect(mapStateToProps, { updateProfilePicFunc })(UserProfile);
