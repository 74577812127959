import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import moment from "moment";

const firebase = require("firebase");

function NotificationPannel() {
  const [notifications, setnotifications] = useState({});
  const [loggedIn, setloggedIn] = useState(false);

  useEffect(() => {
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        let userID = user.uid;
        setloggedIn(true);
        firebase
          .database()
          .ref("notifications/" + userID)
          .on("value", (snapshot) => {
            let snapshotData = snapshot.val();
            if (snapshotData) {
              setnotifications(snapshotData);
            }
          });
      } else {
        setloggedIn(false);
      }
    });
  }, []);

  if (loggedIn) {
    return (
      <div className="kt-notification">
        {Object.keys(notifications)
          .reverse()
          .map((key, index) => {
            let currnetObj = notifications[key];
            let LinkTo;
            let text;
            let iconType;
            if (currnetObj.hiveID) {
              LinkTo = `/hive/${currnetObj.hiveID}`;
              if (currnetObj.nodeID) {
                LinkTo = LinkTo + "&id=" + currnetObj.nodeID;
              }
            }
            if (currnetObj.type === "like") {
              text = `You got a new like on your node #${currnetObj.nodeID.substring(5, 10)}`;
              iconType = "flaticon-like kt-font-brand";
            } else if (currnetObj.type === "pulse") {
              text = `You got a new pulse on your node #${currnetObj.nodeID.substring(5, 10)}`;
              iconType = "flaticon-stopwatch kt-font-success";
            } else if (currnetObj.type === "node") {
              text = `You got a new node on your hive #${currnetObj.hiveID.substring(5, 10)}`;
              iconType = "flaticon2-website kt-font-brand";
            }

            return (
              <Link to={LinkTo} className="kt-notification__item" key={key}>
                <div className="kt-notification__item-icon">
                  <i className={iconType} />
                </div>
                <div className="kt-notification__item-details">
                  <div className="kt-notification__item-title">{text}</div>
                  <div className="kt-notification__item-time">{moment(currnetObj.timeStamp).fromNow()}</div>
                </div>
              </Link>
            );
          })}
      
      </div>
    );
  } else {
    return <div className="kt-notification"></div>;
  }
}

export default NotificationPannel;
