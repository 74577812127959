/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/pages/auth/AuthPage`, `src/pages/home/HomePage`).
 */

import React from "react";
import { Route, Switch, withRouter } from "react-router-dom";
import { shallowEqual, useSelector } from "react-redux";
import { useLastLocation } from "react-router-last-location";
import HomePage from "../pages/home/HomePage";
import ErrorsPage from "../pages/errors/ErrorsPage";
import LogoutPage from "../pages/auth/Logout";
import { LayoutContextProvider } from "../../_metronic";
import Layout from "../../_metronic/layout/Layout";
import * as routerHelpers from "../router/RouterHelpers";
import * as firebase from "firebase";
import Introduction from "../pages/Introduction/Introduction.js";
import { SnackbarProvider } from "notistack";

export const Routes = withRouter(({ history }) => {
  let user = null;

  firebase.auth().onAuthStateChanged((user) => {
    if (user) {
      // console.log("user found");
    }
  });

  const lastLocation = useLastLocation();
  routerHelpers.saveLastLocation(lastLocation);
  const { menuConfig, userLastLocation } = useSelector(
    ({ auth, urls, builder: { menuConfig } }) => ({
      menuConfig,
      isAuthorized: user != null,
      userLastLocation: routerHelpers.getLastLocation(),
    }),
    shallowEqual
  );

  return (
    /* Create `LayoutContext` from current `history` and `menuConfig`. */
    <SnackbarProvider
      maxSnack={5}
      anchorOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      autoHideDuration={5000}
    >
      <LayoutContextProvider history={history} menuConfig={menuConfig}>
        <Switch>
          <Route path="/error" component={ErrorsPage} />
          <Route path="/logout" component={LogoutPage} />
          <Route path="/introduction" component={Introduction} />
          <Layout>
            <HomePage userLastLocation={userLastLocation} />
          </Layout>
        </Switch>
      </LayoutContextProvider>
    </SnackbarProvider>
  );
});
