import React from "react";

import Grid from "@material-ui/core/Grid";
import HiveCard from "./HiveCards.js";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import Button from "@material-ui/core/Button";
// import CardGrid from "./CardGrid/CardGrid"
const CardGrid = React.lazy(() => import("./CardGrid/CardGrid.js"));

var firebase = require("firebase");

class ExploreGrid extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tabValue: 1,
      hiveNodes: [],
      viewedNode: 1,
    };
  }

  componentDidMount() {
    this.getNodeData();
  }

  getNodeData = () => {
    firebase
      .database()
      .ref("hiveCards/" + this.props.hiveID + "/node")
      .limitToLast(3)
      .orderByChild("isTopic")
      .equalTo(false)
      .on("value", (snapshot) => {
        var hiveNodes = [];
        snapshot.val() &&
          Object.keys(snapshot.val()).forEach((key) => {
            hiveNodes.push({
              description: snapshot.val()[key].description,
              hdPublicKey: snapshot.val()[key].hdPublicKey,
              image: snapshot.val()[key].image,
              link: snapshot.val()[key].link,
              linkedNode: snapshot.val()[key].linkedNode,
              timeStamp: snapshot.val()[key].timeStamp,
              title: snapshot.val()[key].title,
              userID: snapshot.val()[key].userID,
              nodeID: key,
            });
          });

        this.setState({
          hiveNodes: hiveNodes,
        });
      });
  };

  componentDidUpdate(prevprops) {
    if (prevprops.hiveID !== this.props.hiveID) {
      this.getNodeData();
    }
  }

  render() {
    let nodeCardsActive =
      this.state.hiveNodes &&
      this.state.hiveNodes
        .slice(this.state.viewedNode - 1, this.state.viewedNode)
        .map((entry, index) => (
          <CardGrid
            loading={false}
            explore={true}
            hiveID={this.props.hiveID}
            title={entry.title}
            description={entry.description}
            display="comments"
            link={entry.link}
            hdPublicKey={entry.hdPublicKey}
            key={"cardGrid" + index}
            image={entry.image}
            id={entry.nodeID}
            comments={entry.comments}
          />
        ));

    let hiveItem = (
      <HiveCard
        title={this.props.title}
        nLikes={this.props.nLikes}
        nNodes={this.props.nNodes}
        nOnchained={this.props.nOnchained}
        nPulses={this.props.nPulses}
        loading={false}
        key={this.props.keyIndex}
        image={this.props.image}
        description={this.props.description}
        id={this.props.hiveID}
      />
    );

    let nextNode = this.state.hiveNodes.length > 1 && (
      <div>
        <Button style={{ padding: 0 }}>
          <NavigateNextIcon
            onClick={() =>
              this.setState({
                viewedNode: this.state.viewedNode < this.state.hiveNodes.length ? this.state.viewedNode + 1 : 1,
              })
            }
            style={{ fontSize: 40, height: 243 }}
            color="primary"
          />
        </Button>
      </div>
    );

    let gridItem = (
      <Grid container direction="row" key={this.props.hiveID + "/" + this.props.entryID}>
        <Grid
          item
          xs={12}
          sm={7}
          lg={8}
          style={{
            marginTop: "0px",
            marginBottom: "2px",
            paddingRight: "3px",
            height: "254px !important",
            overflow: "hidden !important",
          }}
        >
          {hiveItem}
        </Grid>

        <Grid
          item
          xs={12}
          sm={5}
          lg={4}
          style={{
            height: "254px",
            marginBottom: "2px",
            marginTop: "0px",
            overflow: "hidden",
          }}
        >
          <Grid container direction="row" spacing={0} style={{ height: "100%" }}>
            <Grid item xs={10}>
              {nodeCardsActive}
            </Grid>
            <Grid item xs={2} style={{ background: "#00284c", height: "100%" }}>
              {nextNode}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );

    return <div>{gridItem}</div>;
  }
}

export default ExploreGrid;
