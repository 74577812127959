import React from "react";

import energy from '../../../../_metronic/layout/assets/Logo/energy.png'

class ImgSlider2 extends React.Component {
  render() {
    return (
        <div className="img-slider-container">
            <div>
              <img src={energy}  alt="John" Doe className="rounded-circle"/>
            </div>
           
        </div>
    )
  }
}

export default ImgSlider2;
