import React from "react";

import Card from "@material-ui/core/Card";
import CardText from "@material-ui/core/Card";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import OrderStatisticsChart from "../../widgets/OrderStatisticsChart";
import WalletStats from "../../widgets/WalletStats";
import LatestUpdates from "../../widgets/LatestUpdates";
import Grid from "@material-ui/core/Grid";
import Firebasefunctionurl from "../../../constants";
import WithdrawlComponent from "../../widgets/Withdrawl";
import UpdatePortraitComponent from "../../widgets/uploadPortrait";
import { connect } from "react-redux";
import { updateProfilePicFunc } from "../../store/ducks/auth.duck";
import CircularProgress from "@material-ui/core/CircularProgress";
import { withSnackbar } from "notistack";
import { TitleComponent } from "../../partials/content/helmetComponent";

var firebase = require("firebase");

function validateEmail(email) {
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

class Profile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      open1: false,
      open2: false,
      open3: false,
      openMnemonic: false,
      tags: [],
      description: "",
      newEmail: "",
      mnemonic: "",
      hdPrivateKey: "",
      satoshis: "",
      address: "",
      hdPublicKey: "",
      latestActivities: {},
      latestNotifications: {},
      totalIncome: 0,
      nTransactions: 0,
      balance: 0,
      bsvPrice: 100,
      txoSet: [],
      profileImage: null,
      refreshLoader: false,
      userEmail: "",
      userID: "",
      newEmailError: false,
      newEmailPassword: "",
      showLoader: false,
      oldPassword: "",
      newPassword: "",
      delPassword: "",
      notiAndActi: [],
      userDisplayName: "",
      userLikesLength: 0,
      userNodesLength: 0,
    };
  }

  componentDidMount(props) {
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        var userID = user.uid;
        this.setState({ userDisplayName: user.displayName, profileImage: user.photoURL, userEmail: user.email, userID: userID });
        firebase
          .database()
          .ref("KeyStore/" + userID)
          .on("value", (snapshot) => {
            if (snapshot.exists()) {
              if (snapshot.hasChild("hdPrivateKey")) {
                var hdPrivateKey = snapshot.val().hdPrivateKey;
                var hdPublicKey = snapshot.val().hdPublicKey;
                var address = snapshot.val().address;
                var mnemonic = snapshot.val().mnemonic;
                var balance = snapshot.val().balance;
                var nTransactions = snapshot.val().nTransactions;
                var totalIncome = snapshot.val().totalIncome;
                var txoSet = snapshot.val().txoSet;
                var userLikesLength = snapshot.val().likes ? Object.keys(snapshot.val().likes).length : 0;
                var userNodesLength = snapshot.val().nodes ? Object.keys(snapshot.val().nodes).length : 0;

                this.setState({
                  hdPrivateKey,
                  hdPublicKey,
                  address,
                  mnemonic,
                  balance,
                  nTransactions,
                  totalIncome,
                  txoSet,
                  userLikesLength,
                  userNodesLength,
                });
              }
            } else {
              console.log("Key value doesnt exist");
            }
          });

        //fetching price data
        firebase
          .database()
          .ref("stats/")
          .once("value")
          .then((snapshot) => {
            if (snapshot.val()) {
              this.setState({
                bsvPrice: snapshot.val().market_price_usd,
              });
            }
          });

        //fetching latest activities
        firebase
          .database()
          .ref("activities/" + userID)
          .on("value", (snapshot) => {
            if (snapshot.val()) {
              this.setState({
                latestActivities: snapshot.val(),
              });
            }
          });

        //fetching latest notifications
        firebase
          .database()
          .ref("notifications/" + userID)
          .on("value", (snapshot) => {
            if (snapshot.val()) {
              this.setState({
                latestNotifications: snapshot.val(),
              });
            }
          });

        this.updateWithdrawl();
      } else {
        this.props.history.push("/explore");
      }
    });
  }

  getactivitesAndNotif = async () => {};

  updateWithdrawl = () => {
    let getMetrices = firebase.functions().httpsCallable("getMetrices");
    getMetrices();
  };

  onEmailChange = (e) => {
    this.setState({
      newEmail: e.target.value,
      newEmailError: !validateEmail(e.target.value),
    });
  };

  onNewPasswordConfirmationChange = (event, value) => {
    this.setState({
      newPasswordConfirmation: value,
    });
  };

  submitEmailChange = () => {
    this.setState({
      showLoader: true,
    });
    const { newEmail, newEmailPassword, userEmail } = this.state;
    if (newEmailPassword.length === 0) {
      this.props.enqueueSnackbar("Please provide a correct paeeword!", { variant: "error" });
      this.setState({
        showLoader: false,
      });
    } else if (validateEmail(newEmail)) {
      let that = this;
      //signning the user
      firebase
        .auth()
        .signInWithEmailAndPassword(userEmail, newEmailPassword)
        .then(() => {
          console.log("sign success");
          //updating email
          var user = firebase.auth().currentUser;
          return user.updateEmail(newEmail);
        })
        .then(function() {
          // Update successful.
          that.setState({ open3: false, userEmail: newEmail, showLoader: false });
          that.props.enqueueSnackbar("Email Updated Successfully!", { variant: "success" });
        })
        .catch(function(error) {
          console.log("err", error);
          that.props.enqueueSnackbar(error.message, { variant: "error" });
          that.setState({
            showLoader: false,
          });
        });
    } else {
      this.props.enqueueSnackbar("Please provide a valid Email address!", { variant: "error" });
      this.setState({
        showLoader: false,
      });
    }
  };

  submitChangePassword = () => {
    this.setState({
      showLoader: true,
    });
    const { oldPassword, newPassword, userEmail } = this.state;
    if (newPassword.length === 0 || oldPassword.length === 0) {
      this.props.enqueueSnackbar("Please provide a correct value for each paeewords!", { variant: "error" });
      this.setState({
        showLoader: false,
      });
    } else {
      let that = this;
      //signning the user
      firebase
        .auth()
        .signInWithEmailAndPassword(userEmail, oldPassword)
        .then(() => {
          console.log("sign success");
          //updating email
          var user = firebase.auth().currentUser;
          return user.updatePassword(newPassword);
        })
        .then(function() {
          // Update successful.
          that.setState({ open: false, showLoader: false });
          that.props.enqueueSnackbar("Password Updated Successfully!", { variant: "success" });
        })
        .catch(function(error) {
          console.log("err", error);
          that.props.enqueueSnackbar(error.message, { variant: "error" });
          that.setState({
            showLoader: false,
          });
        });
    }
  };

  deleteAccount = () => {
    this.setState({
      showLoader: true,
    });
    const { delPassword, userEmail } = this.state;
    if (delPassword.length === 0) {
      this.props.enqueueSnackbar("Please provide the correct paeeword!", { variant: "error" });
      this.setState({
        showLoader: false,
      });
    } else {
      let that = this;
      //signning the user
      firebase
        .auth()
        .signInWithEmailAndPassword(userEmail, delPassword)
        .then(() => {
          console.log("sign success");
          //updating email
          var user = firebase.auth().currentUser;
          return user.delete();
        })
        .then(function() {
          // Update successful.
          that.setState({ open1: false, showLoader: false });
          that.props.enqueueSnackbar("Account Deleted!", { variant: "success" });
          // that.props.history.push("/");
        })
        .catch(function(error) {
          console.log("err", error);
          that.props.enqueueSnackbar(error.message, { variant: "error" });
          that.setState({
            showLoader: false,
          });
        });
    }
  };

  componentDidUpdate(prevProps) {
    if (prevProps.updateProfilePic !== this.props.updateProfilePic) {
      if (this.props.updateProfilePic === true) {
        this.setState({ profileImage: this.props.profilePicUrl });
      }
    }
  }

  setnotiAndActi = (data) => {
    this.setState({ notiAndActi: data });
  };

  render() {
    var user = firebase.auth().currentUser;
    var userName;

    if (user != null) {
      userName = user.displayName;
    } else {
      userName = "not logged in";
    }

    return (
      <div>
        <TitleComponent title={this.state.userDisplayName} />
        <Container style={{ maxWidth: 1440 }}>
          <Grid container spacing={1} style={{ marginTop: 4 }}>
            <Grid item sm={6}>
              <div className="Profiledata " id="profile-page">
                <Grid container spacing={1}>
                  <Grid item sm={6}>
                    <Card>
                      <CardText style={{ padding: 20, textAlign: "left" }}>
                        <Grid container spacing={1} style={{ marginBottom: "10px" }}>
                          <Grid item sm={9}>
                            <b style={{ fontSize: 18 }}> {userName} </b>
                            <br />
                            <span style={{ wordBreak: "break-word" }}>{this.state.userEmail}</span>
                            <br />
                            <br />
                          </Grid>
                          {this.state.profileImage && (
                            <Grid item sm={3} style={{ display: "flex", justifyContent: "flex-end" }}>
                              <div style={{ width: "100%" }}>
                                <img style={{ width: "100%", height: "auto" }} src={this.state.profileImage} alt="profileImage" />
                              </div>
                            </Grid>
                          )}
                        </Grid>
                        <Grid container spacing={1}>
                          <Grid item sm={6}>
                            <Card style={{ marginTop: 8 }}>
                              <Button style={{ width: "100%", padding: 15 }} onClick={() => this.setState({ open3: true, newEmail: "", newEmailPassword: "" })}>
                                Change Email
                              </Button>
                            </Card>

                            <Card style={{ marginTop: 8 }}>
                              <Button style={{ width: "100%", padding: 15 }} onClick={() => this.setState({ open: true, oldPassword: "", newPassword: "" })}>
                                Change Password
                              </Button>
                            </Card>
                          </Grid>

                          <Grid item sm={6}>
                            <UpdatePortraitComponent />

                            <Card style={{ marginTop: 8 }}>
                              <Button style={{ width: "100%", padding: 15 }} onClick={() => this.setState({ open1: true, delPassword: "" })}>
                                Delete Account
                              </Button>
                            </Card>
                          </Grid>
                        </Grid>
                        <br />

                        <br />
                        <Dialog
                          onEscapeKeyDown={() => this.setState({ open: false })}
                          open={this.state.open}
                          onClose={() => this.setState({ open: false })}
                          aria-labelledby="password-form"
                        >
                          <DialogTitle id="form-dialog-title">Password</DialogTitle>
                          <DialogContent>
                            <DialogContentText>To change your password, type in and confirm your new password below.</DialogContentText>

                            <TextField
                              autoFocus
                              type="password"
                              margin="dense"
                              label="Old Password"
                              floatingLabelText="Old Password"
                              floatingLabelStyle={{ fontWeight: "normal" }}
                              fullWidth
                              value={this.state.oldPassword}
                              onChange={(e) => {
                                this.setState({
                                  oldPassword: e.target.value,
                                });
                              }}
                            />

                            <TextField
                              type="password"
                              margin="dense"
                              label="New Password"
                              floatingLabelText="New Password"
                              floatingLabelStyle={{ fontWeight: "normal" }}
                              fullWidth
                              value={this.state.newPassword}
                              onChange={(e) => {
                                this.setState({
                                  newPassword: e.target.value,
                                });
                              }}
                            />
                          </DialogContent>
                          <DialogActions>
                            <Button onClick={() => this.setState({ open: false })} color="primary">
                              Cancel
                            </Button>

                            <div style={{ width: "10%", display: "flex", justifyContent: "center" }}>
                              {this.state.showLoader ? (
                                <CircularProgress color="primary" size={20} />
                              ) : (
                                <Button
                                  onClick={() => {
                                    this.submitChangePassword();
                                  }}
                                  color="primary"
                                >
                                  Change
                                </Button>
                              )}
                            </div>
                          </DialogActions>
                        </Dialog>
                        {/* {mnemonicDialog} */}
                        <Dialog
                          onEscapeKeyDown={() => this.setState({ open3: false })}
                          open={this.state.open3}
                          onClose={() => this.setState({ open3: false })}
                          aria-labelledby="email-form"
                        >
                          <DialogTitle id="form-dialog-title">Correspondence</DialogTitle>
                          <DialogContent>
                            <DialogContentText>To change your email, please enter a new email address with current password here.</DialogContentText>

                            <TextField
                              autoFocus
                              type="password"
                              margin="dense"
                              id="newEmailPassword"
                              label="Current Password"
                              floatingLabelStyle={{ fontWeight: "normal" }}
                              fullWidth
                              onChange={(e) => {
                                this.setState({ newEmailPassword: e.target.value });
                              }}
                              value={this.state.newEmailPassword}
                            />
                            <TextField
                              type="email"
                              margin="dense"
                              id="name"
                              label="New Email Address"
                              floatingLabelStyle={{ fontWeight: "normal" }}
                              fullWidth
                              onChange={this.onEmailChange}
                              error={this.state.newEmailError}
                            />
                          </DialogContent>
                          <DialogActions>
                            <Button onClick={() => this.setState({ open3: false })} color="primary">
                              Cancel
                            </Button>
                            <div style={{ width: "10%", display: "flex", justifyContent: "center" }}>
                              {this.state.showLoader ? (
                                <CircularProgress color="primary" size={20} />
                              ) : (
                                <Button onClick={() => this.submitEmailChange()} color="primary">
                                  Change
                                </Button>
                              )}
                            </div>
                          </DialogActions>
                        </Dialog>
                        {/* delete account */}
                        <Dialog
                          onEscapeKeyDown={() => this.setState({ open1: false })}
                          open={this.state.open1}
                          onClose={() => this.setState({ open1: false })}
                          aria-labelledby="delete-form"
                        >
                          <DialogTitle id="form-dialog-title">Delete Account</DialogTitle>
                          <DialogContent>
                            <DialogContentText>Warning: Deleting this account is irreversible.</DialogContentText>
                            <TextField
                              autoFocus
                              type="password"
                              margin="dense"
                              id="delete-password"
                              label="Current Password"
                              floatingLabelStyle={{ fontWeight: "normal" }}
                              fullWidth
                              onChange={(e) => {
                                this.setState({ delPassword: e.target.value });
                              }}
                              value={this.state.delPassword}
                            />
                          </DialogContent>
                          <DialogActions>
                            <Button
                              onClick={() =>
                                this.setState({
                                  open1: false,
                                })
                              }
                              color="primary"
                            >
                              Cancel
                            </Button>

                            <div style={{ width: "20%", display: "flex", justifyContent: "center" }}>
                              {this.state.showLoader ? (
                                <CircularProgress color="primary" size={20} />
                              ) : (
                                <Button color="primary" onClick={this.deleteAccount}>
                                  Confirm
                                </Button>
                              )}
                            </div>
                          </DialogActions>
                        </Dialog>
                      </CardText>
                    </Card>
                  </Grid>
                  <Grid item sm={6}>
                    <Card style={{ height: "100%" }}>
                      <WalletStats
                        userLikesLength={this.state.userLikesLength}
                        userNodesLength={this.state.userNodesLength}
                        nTransactions={this.state.nTransactions}
                        balance={this.state.balance}
                        bsvPrice={this.state.bsvPrice}
                        totalIncome={this.state.totalIncome}
                      />
                      <div>
                        <Button
                          style={{ display: this.state.refreshLoader ? "none" : "inline-block" }}
                          onClick={() => {
                            this.updateWithdrawl();
                            this.setState({ refreshLoader: true });
                            setTimeout(() => {
                              this.setState({ refreshLoader: false });
                            }, 2000);
                          }}
                        >
                          Refresh
                        </Button>
                        <CircularProgress style={{ display: this.state.refreshLoader ? "inline-block" : "none" }} size={18} />
                      </div>
                    </Card>
                  </Grid>
                </Grid>
                <WithdrawlComponent
                  mnemonic={this.state.mnemonic}
                  address={this.state.address}
                  opData={["hive", "withdrawl"]}
                  hdPublicKey={this.state.hdPublicKey}
                  hdPrivateKey={this.state.hdPrivateKey}
                  txoSet={this.state.txoSet}
                  balance={this.state.balance}
                  bsvPrice={this.state.bsvPrice}
                />
              </div>
            </Grid>
            <Grid item sm={6}>
              <LatestUpdates setNotiAndActi={this.setnotiAndActi} latestNotifications={this.state.latestNotifications} latestActivities={this.state.latestActivities} />
            </Grid>
            <Grid item sm={12}>
              <Card style={{ height: 250, padding: 10 }}>
                <OrderStatisticsChart latestActivities={this.state.notiAndActi} />
              </Card>
            </Grid>
          </Grid>
        </Container>
      </div>
    );
  }
}
const mapStateToProps = ({ auth: { updateProfilePic, profilePicUrl } }) => ({
  updateProfilePic,
  profilePicUrl,
});
export default withSnackbar(connect(mapStateToProps, { updateProfilePicFunc })(Profile));
