import React, { useMemo } from "react";
import { ForceGraph3D } from "react-force-graph";
import Grid from "@material-ui/core/Grid";
import * as THREE from "three";
import SpriteText from "three-spritetext";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";

function GraphComponent(props) {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("xs"));

  React.useEffect(() => {
    if (props.refProp && props.refProp.current && props.refProp.current.d3Force) {
      props.refProp.current.d3Force("link").distance((link) => {
        if (link.isTopic === true) {
          return 80;
        } else {
          return 40;
        }
      });
    }
  }, [props.graphData, matches]);

  return useMemo(() => {
    if (!matches) {
      return (
        <Grid item xs={12} sm={7} className={"flex-no-shrink"}>
          <div>
            <ForceGraph3D
              ref={props.refProp}
              style={{ position: "absolute" }}
              numDimensions={3}
              graphData={props.graphData}
              backgroundColor="#00284C"
              nodeId="nodeID"
              nodeVal={(node) => {
                let tlikes = Object.keys(node.likes).length;
                if (tlikes === 0) {
                  tlikes = 1;
                }
                return tlikes * 10;
              }}
              // linkDirectionalParticles={(node) => Object.keys(node.likes).length * 0.5}
              linkDirectionalParticles={props.nodeParticlesRate}
              linkDirectionalParticleWidth={props.nodeWidth}
              linkDirectionalParticleColor={() => "#FFAD05"}
              nodeLabel={(node) => (node.isTopic ? null : node.title)}
              linkSource="nodeID"
              linkTarget="linkedNode"
              onBackgroundClick={props.onBackgroundClick}
              width={((window.innerWidth - 15) * 7) / 12}
              height={window.innerHeight - 77}
              nodeResolution={20}
              showNavInfo={false}
              onNodeClick={props.onNodeClick}
              linkDirectionalArrowLength={(node) => (node.isTopic ? 0 : 2)}
              nodeColor={props.nodeColor}
              cooldownTicks={props.cooldownTicks}
              linkDirectionalArrowRelPos={0.5}
              nodeThreeObject={(id) => {
                if (id.isTopic === "Main") {
                  let box = new THREE.TorusKnotGeometry(2, 3, 100, 16);
                  let materials;

                  materials = [
                    new THREE.MeshStandardMaterial({
                      depthWrite: false,
                      transparent: true,
                      opacity: 0.2,
                      color: "#ffcc00",
                    }),
                  ];

                  let obj = new THREE.Mesh(box, materials);

                  return obj;
                } else if (id.isTopic === true) {
                  let box = new THREE.TorusGeometry(20, 1.1, 8, 6, 3 * id.weight);
                  let materials;
                  if (props.nodeActive === id.key) {
                    materials = [
                      new THREE.MeshStandardMaterial({
                        depthWrite: false,
                        transparent: true,
                        opacity: 0.6,
                        color: "#98df54",
                      }),
                    ];
                  } else {
                    materials = [
                      new THREE.MeshStandardMaterial({
                        depthWrite: false,
                        transparent: true,
                        opacity: 0.05,
                        color: "#fff",
                      }),
                    ];
                  }

                  let obj = new THREE.Mesh(box, materials);

                  const sprite = new SpriteText(id.title);

                  sprite.color = "#ffffff";
                  sprite.textHeight = 5;
                  obj.add(sprite);
                  return obj;
                }
              }}
            />
          </div>
        </Grid>
      );
    } else {
      return <></>;
    }
  }, [props, matches]);
}

export default GraphComponent;
