import React from 'react';
import styled from '@emotion/styled';
import { Transition, animated } from 'react-spring';
import Introduction1 from './RightSlider/IntroductionSlide1';
import Introduction2 from './RightSlider/IntroductionSlide2';
import Introduction3 from './RightSlider/IntroductionSlide3';
import ImgSlider1 from './LeftSlider/ImgSlider1';
import ImgSlider2 from './LeftSlider/ImgSlider2';
import ImgSlider3 from './LeftSlider/ImgSlider3';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Cloud from '../../../_metronic/layout/assets/Logo/cloud.png';
import '../../../_metronic/_assets/sass/pages/introduction/introduction.scss';

import clsx from 'clsx';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Check from '@material-ui/icons/Check';
import SettingsIcon from '@material-ui/icons/Settings';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import VideoLabelIcon from '@material-ui/icons/VideoLabel';
import StepConnector from '@material-ui/core/StepConnector';
import Typography from '@material-ui/core/Typography';

class Introduction extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			index: 0,
		};
	}
	onClickChange = (index, next) => {
		this.setState({
			...this.state,
      index: index === 2 ? 0 : next ? index + 1 : index - 1,
		});

		if (index === 2 && next) {
			this.props.history.push('/explore');
		}
	};

	render() {
		const { index } = this.state;
		return (
			<div className="intro-main">
				
					
			</div>
		);
	}
}

export default Introduction;
