import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Typography from "@material-ui/core/Typography";
import BubbleChartIcon from "@material-ui/icons/BubbleChart";
import LockOpenSharp from "@material-ui/icons/LockOpenSharp";
import ThumbUpAltSharpIcon from "@material-ui/icons/ThumbUpAltSharp";
import Skeleton from "@material-ui/lab/Skeleton";
import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Link } from "react-router-dom";
import SecurityIcon from "@material-ui/icons/Security";
import { withSnackbar } from "notistack";
import ChatBubbleIcon from "@material-ui/icons/ChatBubble";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import HttpsSharpIcon from "@material-ui/icons/HttpsSharp";
import moment from "moment";
let filepay = window.filepay;
let bsv = require("bsv");
let firebase = require("firebase");
let imgReloadCount = 0;

class LeftCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      pulse: false,
      imageLoader: false,
      // liked: props.liked,
      disableLike: false,
    };
  }

  getAddrFromPubKey(hdPublicKey) {
    let hdPK = bsv.HDPublicKey.fromString(hdPublicKey);
    let publicKey = hdPK.deriveChild("m/1");
    let address = bsv.Address.fromPublicKey(publicKey.publicKey).toString();
    return address;
  }

  async like() {
    // emit particles
    this.props.likeClicked(this.props.id);
    this.props.enqueueSnackbar("Processing Like Transction", {
      variant: "info",
    });
    this.setState({
      // liked: true,
      disableLike: true,
    });
 
    //processing like transction
    let { user } = this.props;
    let userID = user.uid;
    let likeContent = {
      hiveID: this.props.hiveID,
      nodeID: this.props.id,
      userID: userID,
      timeStamp: firebase.database.ServerValue.TIMESTAMP,
    };

    let tx = [];
    let activeNode = this.props.hiveNodes.filter((x) => x.nodeID === this.props.id);
    let l2LinkNode = this.props.hiveNodes.filter((x) => activeNode[0] && activeNode[0].linkedNode && x.nodeID === activeNode[0].linkedNode);
    activeNode.map((x) => tx.push({ address: this.getAddrFromPubKey(x.hdPublicKey), value: 1400 }));
    l2LinkNode.map((x) => tx.push({ address: this.getAddrFromPubKey(x.hdPublicKey), value: 1000 }));

    this.props.hiveNodes
      .filter((x) => l2LinkNode[0] && l2LinkNode[0].linkedNode && x.nodeID === l2LinkNode[0].linkedNode)
      .map((x) =>
        tx.push({
          address: this.getAddrFromPubKey(x.hdPublicKey),
          value: 800,
        })
      );
    this.props.hiveNodes
      .filter((x) => x.linkedNode === this.props.id)
      .map((x) =>
        tx.push({
          address: this.getAddrFromPubKey(x.hdPublicKey),
          value: 600,
        })
      );

    //
    // const hdprivateKey = bsv.HDPrivateKey.fromString(this.props.hdPrivateKey);
    // const privateKey = hdprivateKey.privateKey.toString();
    // const hdPublicKey = bsv.HDPublicKey.fromString(this.props.hdPublicKey);
    // const publicKey = hdPublicKey.publicKey.toString();

    let opData = ["hive", "like", this.props.id, this.props.hiveID, userID, this.props.hdPublicKey];

    let prevUsedTxos = await firebase
      .database()
      .ref("KeyStore/" + userID + "/usedTxos")
      .once("value")
      .then((snapshot) => {
        return snapshot.val();
      })
      .then((res) => {
        prevUsedTxos = res;
        console.log("prevUsedTxos: ", prevUsedTxos);
        return prevUsedTxos;
      });

    let makeTransctionAPI = firebase.functions().httpsCallable("makeTransction");
    let makeTransctionRes = await makeTransctionAPI({
      type: "like",
      hdPrivateKey: this.props.hdPrivateKey,
      opData: opData,
      txoSet: tx,
      prevUsedTxos: prevUsedTxos,
    });
    //remove
    // let makeTransctionRes = { data: { status: "success" } };
    console.log("makeTransctionRes", makeTransctionRes);

    if (makeTransctionRes && makeTransctionRes.data) {
      if (makeTransctionRes.data.status && makeTransctionRes.data.status === "error") {
        this.props.enqueueSnackbar(makeTransctionRes.data.msg, {
          variant: "error",
        });
        this.props.enqueueSnackbar(`Like Not Submitted `, {
          variant: "error",
        });
        this.setState({
          // liked: this.props.liked,
          disableLike: false,
        });
      } else if (makeTransctionRes.data.status && makeTransctionRes.data.status === "success") {
        console.log("like success");
        //updating DB
        let newPostKey = firebase
          .database()
          .ref("likes")
          .push().key;
        let contents = {};
        contents["hiveCards/" + this.props.hiveID + "/node/" + this.props.id + "/likes/" + newPostKey] = likeContent;
        contents["KeyStore/" + userID + "/likes/" + newPostKey] = likeContent;
        firebase
          .database()
          .ref()
          .update(contents);
        this.setState({
          // snackOpen: true,
          amount: 0.1,
          disableLike: false,

          // snackMessage: "Like Submitted!",
        });
        this.props.enqueueSnackbar(`Like Submitted Successfully`, {
          variant: "success",
        });
        this.props.checkAvalibilityOfNewTxoset(userID);
      }
    }
  }

  getToLink = () => {
    if (this.props.user && this.props.user.uid) {
      firebase
        .database()
        .ref("hiveCards/" + this.props.hiveID + "/node/" + this.props.id)
        .child("clicks")
        .transaction((clicks) => {
          return (clicks || 0) + 1;
        });
      let { user } = this.props;
      let userID = user.uid;
      let clickContent = {
        hiveID: this.props.hiveID,
        nodeID: this.props.id,
        userID: userID,
        timeStamp: firebase.database.ServerValue.TIMESTAMP,
      };
      let newClickKey = firebase
        .database()
        .ref("KeyStore/" + userID + "/read")
        .push().key;
      let readActivity = {
        timeStamp: firebase.database.ServerValue.TIMESTAMP,
        type: "read",
        hiveID: this.props.hiveID,
        nodeID: this.props.id,
      };
      let contents = {};
      contents["KeyStore/" + userID + "/read/" + newClickKey] = clickContent;
      contents["/activities/" + userID + "/" + newClickKey] = readActivity;

      firebase
        .database()
        .ref()
        .update(contents);
      window.open(this.props.link, "_blank");
    } else {
      window.open(this.props.link, "_blank");
    }
  };

  moderate = () => {
    let contents = {};
    contents["hiveCards/" + this.props.hiveID + "/node/" + this.props.id + "/moderate"] = !this.props.moderateStatus;
    firebase
      .database()
      .ref()
      .update(contents);
  };

  componentDidMount() {
    imgReloadCount = 0;
  }

  componentDidUpdate(prevProps) {
    // console.log("left card update");
    if (prevProps.image !== this.props.image) {
      this.setState({ imageLoader: true });
      imgReloadCount = 0;
      setTimeout(() => {
        this.setState({ imageLoader: false });
      }, 10);
    }
    // if (prevProps.liked !== this.props.liked) {
    //   this.setState({ liked: this.props.liked });
    // }
  }

  imageDisplayError = () => {
    if (imgReloadCount < 5) {
      imgReloadCount = imgReloadCount + 1;
      this.setState({ imageLoader: true });
      setTimeout(() => {
        this.setState({ imageLoader: false });
      }, 5000);
    }
  };

  render() {
    let bgColorOfNode = "inherit";
    if (this.props.clickedStatus === true) {
      bgColorOfNode = "#dfeaf3";
    } else if (this.props.moderateStatus === true && this.props.canModerate === true) {
      bgColorOfNode = "#FE9FA7";
    }
    const CardActionContent = (
      <CardActionArea>
        <div style={{ height: 143, overflow: "hidden" }}>
          {(() => {
            if (this.props.loading || this.state.imageLoader) {
              return <Skeleton animation="wave" variant="rect" style={{ height: 142, maxWidth: "100%" }} />;
            } else {
              return (
                <LazyLoadImage
                  onError={this.imageDisplayError}
                  height={142}
                  style={{ objectFit: "cover" }}
                  width={"100%"}
                  src={this.props.image}
                  className="fadeIn"
                  effect="opacity"
                  delayTime={300}
                />
              );
            }
          })()}
        </div>
        {this.props.loading ? (
          <CardContent>
            <Skeleton animation="wave" height={20} style={{ marginBottom: 6 }} />
            <div style={{ overflow: "hidden", height: 46, marginTop: 5 }}>
              <Skeleton animation="wave" height={12} width="80%" />
              <Skeleton animation={false} height={12} width="80%" />
              <Skeleton animation="wave" height={12} width="80%" />
            </div>
          </CardContent>
        ) : (
          <CardContent>
            <Typography gutterBottom noWrap variant="h5" component="h2">
              {this.props.title}
            </Typography>
            <Typography variant="body2" color="textSecondary" component="p" style={{ overflow: "hidden", height: 46 }}>
              {this.props.description}...
            </Typography>
          </CardContent>
        )}
      </CardActionArea>
    );

    const CardItems = (
      <div style={{ maxWidth: "100%" }}>
        {this.props.explore ? (
          <Link to={`/hive/${this.props.hiveID}&id=${this.props.id}`} style={{ color: "inherit", textDecoration: "none" }}>
            {CardActionContent}
          </Link>
        ) : (
          <div onClick={this.getToLink} rel="noopener noreferrer" style={{ color: "inherit", textDecoration: "none", backgroundColor: bgColorOfNode }}>
            {CardActionContent}
          </div>
        )}
        {!this.props.explore && (
          <CardActions>
            <div style={{ margin: "auto", textAlign: "center" }}>
              <FormControlLabel
                control={
                  <Checkbox
                    icon={<ThumbUpAltSharpIcon />}
                    checkedIcon={<ThumbUpAltSharpIcon />}
                    disabled={this.state.disableLike}
                    checked={this.props.liked}
                    value="checkedH"
                    onClick={() => (this.props.user ? this.like() : this.props.handleLoginPopup(true))}
                  />
                }
              />
              <CommentsComponent display={this.props.display} handleDisplay={this.props.handleDisplay} />
              <PulseComponent display={this.props.display} handleDisplay={this.props.handleDisplay} statepulse={this.state.pulse} />
              <OnchainComponent archived={this.props.archived} display={this.props.display} handleDisplay={this.props.handleDisplay} archived={this.props.archived} />

              {this.props.canModerate && (
                <FormControlLabel
                  control={
                    <Checkbox
                      icon={<SecurityIcon color={this.props.moderateStatus === true ? "secondary" : "inherit"} />}
                      checkedIcon={<SecurityIcon color="primary" />}
                      checked={false}
                      value="checkedH"
                      onClick={this.moderate}
                    />
                  }
                />
              )}
            </div>
          </CardActions>
        )}
      </div>
    );
    return CardItems;
  }
}

function CommentsComponent(props) {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));

  if (matches) {
    return (
      <FormControlLabel
        control={
          <Checkbox
            icon={<ChatBubbleIcon />}
            checkedIcon={<ChatBubbleIcon />}
            checked={props.display === "comments" ? true : false}
            value="checkedH"
            onClick={() => {
              props.handleDisplay(props.display === "comments" ? "" : "comments");
            }}
          />
        }
      />
    );
  } else {
    return <></>;
  }
}

function PulseComponent(props) {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));

  const pulseclick = () => {
    if (matches) {
      props.display === "pulse" ? props.handleDisplay("") : props.handleDisplay("pulse");
    } else {
      props.display === "pulse" ? props.handleDisplay("comments") : props.handleDisplay("pulse");
    }
  };

  return (
    <FormControlLabel
      control={
        <Checkbox
          icon={<BubbleChartIcon color={props.statepulse === true ? "secondary" : "inherit"} />}
          checkedIcon={<BubbleChartIcon color="primary" />}
          checked={props.display === "pulse"}
          value="checkedH"
          onClick={pulseclick}
        />
      }
    />
  );
}

function OnchainComponent(props) {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));

  const onchainclick = () => {
    if (matches) {
      props.display === "onchain" ? props.handleDisplay("") : props.handleDisplay("onchain");
    } else {
      props.display === "onchain" ? props.handleDisplay("comments") : props.handleDisplay("onchain");
    }
  };
  return (
    <FormControlLabel
      control={
        <Checkbox
          icon={props.archived ? <HttpsSharpIcon color={props.archived ? "secondary" : "inherit"} /> : <LockOpenSharp color={props.archived ? "secondary" : "inherit"} />}
          checkedIcon={props.archived ? <HttpsSharpIcon color="primary" /> : <LockOpenSharp color="primary" />}
          checked={props.display === "onchain"}
          value="checkedH"
          onClick={onchainclick}
        />
      }
    />
  );
}

export default withSnackbar(LeftCard);
