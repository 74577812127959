import React from "react";

import Grid from "@material-ui/core/Grid";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import CardMedia from "@material-ui/core/CardMedia";
import TextField from "@material-ui/core/TextField";
import Skeleton from "@material-ui/lab/Skeleton";
import Snackbar from "@material-ui/core/Snackbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Firebasefunctionurl from "../../../../constants";
import { toAbsoluteUrl } from "../../../../../src/_metronic/utils/utils";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeTransction } from "../action";
import { withSnackbar } from "notistack";

var bsv = require("bsv");
{
  /* var Message = require('bsv/message'); */
}
var firebase = require("firebase");

class CreateNode extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      snackMessage: "",
      snackOpen: false,
      archived: false,
      nodeImage: "",
      nodeLinkURL: "",
      nodeTitle: "",
      nodeDescription: "",
      linkedNode: this.props.activeNode,
      hiveID: this.props.hiveID,
      amount: 0.05,
      hdPublicKey: this.props.hdPublicKey,
      hdPrivateKey: this.props.hdPrivateKey,
      nodeTopicValue: "",
      showLoader: false,
    };
  }

  getPreview(inputURL) {
    let isURL = (str) => {
      var pattern = new RegExp(
        "^(https?:\\/\\/)?" + // protocol
        "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
        "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
        "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
        "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
          "(\\#[-a-z\\d_]*)?$",
        "i"
      ); // fragment locator
      return !!pattern.test(str);
    };

    if (isURL(inputURL)) {
      var data = { key: "11ddf7af014f2a237f015940b4d11675", q: inputURL };
      fetch("https://api.linkpreview.net", {
        method: "POST",
        mode: "cors",
        body: JSON.stringify(data),
      })
        .then((res) => res.json())
        .then((response) => {
          this.setState({
            nodeTitle: response.title,
            nodeDescription: response.description,
            nodeImage: response.image,
          });
        });
    } else {
      console.log("invalid url");
    }
  }

  getAddrFromPubKey(hdPublicKey) {
    let hdPK = bsv.HDPublicKey.fromString(hdPublicKey);
    let publicKey = hdPK.deriveChild("m/1");
    let address = bsv.Address.fromPublicKey(publicKey.publicKey).toString();
    // console.log('getAddrGeneration');
    // console.log(address);
    return address;
  }

  async createNode(topic) {
    this.setState({ showLoader: true });
    var title = this.state.nodeTitle !== "" ? this.state.nodeTitle : "-";
    var description = this.state.nodeDescription !== "" ? this.state.nodeDescription : "-";
    var image = this.state.nodeImage !== "" ? this.state.nodeImage : "https://picsum.photos/350/150?random=1";

    //comparing with blacklist
    let nodeIsBlacklisted = false;
    let nodeIsBlacklistedreason = "";

    let blacklist = await firebase
      .database()
      .ref("blacklist")
      .once("value")
      .then((snapshot) => {
        return snapshot.val();
      });
    blacklist.map((a, b) => {
      if (this.state.nodeLinkURL.includes(a.url)) {
        nodeIsBlacklisted = true;
        nodeIsBlacklistedreason = a.reason;
      }
    });

    //checking node uniquness
    let checkNodeUniquness = [];
    if (!topic) {
      checkNodeUniquness = this.props.hiveNodes.filter((obj) => obj.link === this.state.nodeLinkURL);
    }
    console.log("uu", checkNodeUniquness);

    //
    if (nodeIsBlacklisted === true) {
      this.props.enqueueSnackbar("Url is black listed! " + nodeIsBlacklistedreason, {
        variant: "error",
      });
      this.setState({
        showLoader: false,
      });
    } else if (checkNodeUniquness.length > 0) {
      this.props.enqueueSnackbar("Node with this Url has already created!", {
        variant: "error",
      });
      this.setState({
        showLoader: false,
      });
    } else {
      var user = firebase.auth().currentUser;
      var userID = user.uid;
      //adding img to stoarge
      var newPostKey = firebase
        .database()
        .ref("nodes")
        .push().key;
      ////
      let storageUrl = await fetch(`${Firebasefunctionurl.firebaseFunctionUrl}getNodeImageUrl`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          nodeID: newPostKey,
          url: image,
        }),
      }).then((response) => response.json());
      if (storageUrl.url) {
        image = storageUrl.url;
      }
      var nodeContent;
      if (topic) {
        nodeContent = {
          title: this.state.nodeTopicValue !== "" ? this.state.nodeTopicValue : "#topic",
          description: "",
          image: "",
          link: "",
          hiveID: this.props.hiveID,
          linkedNode: this.props.activeNode,
          userID: userID,
          hdPublicKey: this.state.hdPublicKey,
          timeStamp: firebase.database.ServerValue.TIMESTAMP,
          weight: 0.5,
          clicks: 0,
          isTopic: true,
        };
      } else {
        nodeContent = {
          title: title,
          description: description,
          image: image,
          link: this.state.nodeLinkURL,
          hiveID: this.props.hiveID,
          linkedNode: this.props.activeNode,
          userID: userID,
          hdPublicKey: this.state.hdPublicKey,
          timeStamp: firebase.database.ServerValue.TIMESTAMP,
          weight: 0.5,
          clicks: 0,
          isTopic: false,
          pulses: {},
        };
      }

      let tx = [];
      let activeNode = this.props.hiveNodes.filter((x) => x.nodeID === this.props.activeNode);
      let l2LinkNode = this.props.hiveNodes.filter((x) => x.nodeID === activeNode[0].linkedNode);
      activeNode.map((x) => tx.push({ address: this.getAddrFromPubKey(x.hdPublicKey), value: 1400 }));
      l2LinkNode.map((x) => tx.push({ address: this.getAddrFromPubKey(x.hdPublicKey), value: 1000 }));
      l2LinkNode[0] &&
        l2LinkNode[0].linkedNode &&
        this.props.hiveNodes.filter((x) => x.nodeID === l2LinkNode[0].linkedNode).map((x) => tx.push({ address: this.getAddrFromPubKey(x.hdPublicKey), value: 800 }));
      this.props.hiveNodes.filter((x) => x.linkedNode === this.props.activeNode).map((x) => tx.push({ address: this.getAddrFromPubKey(x.hdPublicKey), value: 600 }));

      const hdprivateKey = bsv.HDPrivateKey.fromString(this.state.hdPrivateKey);
      let opData = ["hive", "node", title, description, image, this.state.nodeLinkURL, this.props.activeNode, this.props.hiveID, userID, this.state.hdPublicKey];

      {
        /*const privateKey = hdprivateKey.privateKey.toString();
    	 var privateKeyTest = bsv.PrivateKey.fromRandom()
    	var message = "this is the message that i want to sign"
        let signature = Message.sign(message, privateKeyTest); */
      }

      //transaction comes here
      let resFromTrans = null;
      if (!topic) {
        let prevUsedTxos = [];

        await firebase
          .database()
          .ref("KeyStore/" + userID + "/usedTxos")
          .once("value")
          .then((snapshot) => {
            return snapshot.val();
          })
          .then((res) => {
            prevUsedTxos = res;
            console.log("prevUsedTxos: ", prevUsedTxos);

            return null;
          });
        // resFromTrans = await makeTransction("node", this.state.hdPrivateKey, opData, tx, prevUsedTxos, this.props.enqueueSnackbar);
        let makeTransactionAPI = firebase.functions().httpsCallable("makeTransction");
        resFromTrans = await makeTransactionAPI({
          type: "node",
          hdPrivateKey: this.state.hdPrivateKey,
          opData: opData,
          txoSet: tx,
          prevUsedTxos: prevUsedTxos,
          hiveID: this.props.hiveID,
          hiveCreatorID: this.props.hiveCreatorID,
        });

        // console.log("makeTransctionRes", resFromTrans);
      }

      if ((!topic && resFromTrans && resFromTrans.data && resFromTrans.data.status === "success") || topic) {
        let contents = {};

        //relasing a pulse
        if (!topic) {
          let newPulseKey = firebase
            .database()
            .ref("pulses")
            .push().key;
          let pulseData = {
            total: 10,
            remaining: 10,
            value: 0.1,
            pulseValue: 0.1 / 10,
            active: true,
            nodeID: newPostKey,
            hiveID: this.props.hiveID,
            userID: userID,
            nodeCreatorUserID: userID,
            pulseId: newPulseKey,
          };
          let pulseActivity = {
            timeStamp: firebase.database.ServerValue.TIMESTAMP,
            type: "pulse",
            pulseId: newPulseKey,
            hiveID: this.props.hiveID,
            nodeID: newPostKey,
          };
          let pulseInHivecrds = {
            timeStamp: firebase.database.ServerValue.TIMESTAMP,
            pulseId: newPulseKey,
            hiveID: this.props.hiveID,
            nodeID: newPostKey,
            userID: userID,
            active: true,
          };
          contents["/pulses/" + newPulseKey] = pulseData;
          contents["/activities/" + userID + "/" + newPulseKey] = pulseActivity;
          nodeContent.pulses[newPulseKey] = pulseInHivecrds;

          //updating score
          firebase
            .database()
            .ref("score/" + userID)
            .child("score")
            .transaction((score) => {
              console.log("score", score);
              return (score || 0) + 1;
            });

          this.props.checkAvalibilityOfNewTxoset(userID);
        }

        contents["hiveCards/" + this.props.hiveID + "/node/" + newPostKey] = nodeContent;
        contents["KeyStore/" + userID + "/nodes/" + newPostKey] = nodeContent;
        //
        firebase
          .database()
          .ref()
          .update(contents);

        this.setState({
          //   snackMessage: "Added node!",
          // snackOpen: true,
          nodeTitle: "",
          nodeImage: "",
          nodeDescription: "",
          nodeLinkURL: "",
          nodeTopicValue: "",
          showLoader: false,
        });
        if (this.props.closeAddNodePannel) {
          this.props.closeAddNodePannel();
        }
        if (this.props.closeAddTopicPannel) {
          this.props.closeAddTopicPannel();
        }
        this.props.enqueueSnackbar(`${topic ? "Topic" : "Node"} Created Successfully`, {
          variant: "success",
        });
      } else {
        this.props.enqueueSnackbar(resFromTrans.data.msg, {
          variant: "error",
        });
        this.setState({
          showLoader: false,
        });
        if (this.props.closeAddNodePannel) {
          this.props.closeAddNodePannel();
        }
        if (this.props.closeAddTopicPannel) {
          this.props.closeAddTopicPannel();
        }
        this.props.enqueueSnackbar("Node not created!", {
          variant: "error",
        });
      }
    }
  }

  render() {
    const imageCard = <CardMedia style={{ height: 140, maxWidth: "100%" }} image={this.state.nodeImage} />;
    const placeholderImg = <Skeleton animation="wave" variant="rect" style={{ height: 142, maxWidth: "100%" }} />;
    const imageDiv = this.state.nodeImage === "" ? placeholderImg : imageCard;
    const CardItems = (
      <div style={{ maxWidth: "100%", height: 283 }}>
        <CardActionArea>
          {imageDiv}
          <CardContent>
            <Typography gutterBottom noWrap variant="h5" component="h2">
              {this.state.nodeTitle}
            </Typography>
            <Typography variant="body2" color="textSecondary" component="p" style={{ overflow: "hidden", height: 48 }}>
              {this.state.nodeDescription}
            </Typography>
          </CardContent>
        </CardActionArea>
      </div>
    );

    let addContent = (
      <div style={{ textAlign: "center", padding: 10 }}>
        <TextField
          style={{ marginTop: 7 }}
          label="Add Weblink"
          value={this.state.nodeLinkURL}
          onChange={(x) => {
            this.setState({ nodeLinkURL: x.target.value });
            this.getPreview(x.target.value);
          }}
          variant="outlined"
          fullWidth
          id="mui-theme-provider-outlined-input"
        />
        <TextField
          style={{ marginTop: 7 }}
          label="Title"
          value={this.state.nodeTitle}
          onChange={(x) => this.setState({ nodeTitle: x.target.value })}
          variant="outlined"
          fullWidth
          id="mui-theme-provider-outlined-input"
        />
        <TextField
          style={{ marginTop: 7 }}
          label="Description"
          value={this.state.nodeDescription}
          onChange={(x) => this.setState({ nodeDescription: x.target.value })}
          variant="outlined"
          fullWidth
          id="mui-theme-provider-outlined-input"
        />
        <div>
          {this.state.showLoader ? (
            <CircularProgress color="primary" size={30} style={{ margin: 25 }} />
          ) : (
            <>
              <Button variant="outlined" color="primary" style={{ marginRight: 4, marginTop: 1, padding: 15, pointerEvents: "none" }}>
                {this.state.amount}$
              </Button>
              <Button variant="contained" onClick={() => this.createNode()} color="primary" style={{ margin: 20, padding: 15 }}>
                Add Node
              </Button>
            </>
          )}
        </div>
      </div>
    );

    let contentView =
      this.props.hiveNodes.length === 0 || this.props.activeNode ? (
        <Grid container spacing={0}>
          <Grid item xs={12} sm={5}>
            <div style={{ backgroundColor: "#F5F5F7" }}>{CardItems}</div>
          </Grid>
          <Grid item xs={12} sm={7}>
            {addContent}
          </Grid>
        </Grid>
      ) : (
        <Grid container spacing={0}>
          <Grid item xs={12} sm={5}>
            <div>
              <img style={{ height: 287, width: "100%" }} src={toAbsoluteUrl("/media/images/Vr_man.jpg")} alt="" />
            </div>
          </Grid>
          <Grid item xs={12} sm={7}>
            <div style={{ padding: 30, marginTop: 50, textAlign: "center" }}>
              {" "}
              <p style={{ fontWeight: 500 }}>
                Select a node by clicking <br /> on one of the graph nodes!{" "}
              </p>
            </div>
          </Grid>
        </Grid>
      );

    let activeNodeTopic = this.props.hiveNodes.filter((x) => x.nodeID === this.props.activeNode);
    let TopicView =
      (activeNodeTopic[0] && activeNodeTopic[0].isTopic === true) || (activeNodeTopic[0] && activeNodeTopic[0].isTopic === "Main") ? (
        <Grid container spacing={0} style={{ display: "flex", justifyContent: "center" }}>
          <Grid item xs={12} sm={10}>
            <div style={{ height: 170, textAlign: "center", padding: 10 }}>
              <TextField
                style={{ marginTop: 10 }}
                label={`Add ${activeNodeTopic[0].isTopic === true ? "Sub" : "New"} Topic Name`}
                value={this.state.nodeTopicValue}
                onChange={(x) => {
                  this.setState({ nodeTopicValue: x.target.value });
                }}
                variant="outlined"
                fullWidth
                id="mui-theme-provider-outlined-input"
              />

              {this.state.showLoader ? (
                <CircularProgress color="primary" size={30} style={{ margin: 25 }} />
              ) : (
                <Button variant="contained" onClick={() => this.createNode(true)} color="primary" style={{ margin: 20, padding: 15 }}>
                  Add Topic
                </Button>
              )}
            </div>
          </Grid>
        </Grid>
      ) : (
        <Grid container spacing={0}>
          <Grid item xs={12} sm={5}>
            <div>
              <img style={{ height: 287, width: "100%" }} src={toAbsoluteUrl("/media/images/Vr_man.jpg")} alt="" />
            </div>
          </Grid>
          <Grid item xs={12} sm={7}>
            <div style={{ padding: 30, marginTop: 50, textAlign: "center" }}>
              {" "}
              <p style={{ fontWeight: 500 }}>
                Select a topic by clicking <br /> on one of the graph nodes!{" "}
              </p>
            </div>
          </Grid>
        </Grid>
      );

    return (
      <div>
        <div
          style={{
            marginBottom: 2,
            marginTop: 2,
            backgroundColor: "#FFFFFF",
            gridAutoRows: "1fr",
          }}
        >
          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            open={this.state.snackOpen}
            autoHideDuration={5000}
            onClose={() => this.setState({ snackOpen: false })}
            message={this.state.snackMessage}
            action={
              <React.Fragment>
                <Button style={{ color: "red" }} size="medium">
                  {this.state.amount.toFixed(2)}$
                </Button>
                <IconButton size="small" aria-label="close" color="inherit" onClick={() => this.setState({ snackOpen: false })}>
                  <CloseIcon fontSize="small" />
                </IconButton>
              </React.Fragment>
            }
          />
          {this.props.addTopic ? TopicView : contentView}
        </div>
      </div>
    );
  }
}

export default withSnackbar(CreateNode);
