import React  from "react";
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
//logo
import Cloud from '../../../../_metronic/layout/assets/Logo/cloud.png'

//css
import "../../../../_metronic/_assets/sass/pages/introduction/introduction.scss"
class Introduction2 extends React.Component {
    render() {
        return (
            <Grid xs={12} container spacing={5} className="intro-slide-1-container">
            <Grid item xs={12}>
                <p>
                    2Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla eu erat in libero ullamcorper
                    egestas et tempus libero. Maecenas ultrices ullamcorper eros, accumsan dictum massa bibendum eu.
                    Praesent vel felis turpis. Etiam at sapien tellus. Fusce mi massa, porttitor at gravida sit
                    amet, scelerisque id augue. Nunc at eros at purus vehicula semper non imperdiet mi. Suspendisse
                    vel maximus quam. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere
                    cubilia curae; Donec id lorem quis odio lacinia hendrerit. Pellentesque eu pretium metus. Fusce
                    quis risus erat. Nunc tincidunt nibh velit, rhoncus iaculis purus semper id. Suspendisse ipsum
                    mauris, dapibus sit amet augue sollicitudin, hendrerit pellentesque purus. Nam tincidunt tortor
                    enim, eu imperdiet turpis lobortis id. Aliquam lacinia, purus at mollis convallis, urna quam
                    feugiat ligula, vel euismod ante erat ut diam. Integer ac iaculis massa. Vestibulum laoreet
                    suscipit ex, vitae blandit nisl aliquet non. Vivamus at sapien ac elit tempor tristique sed ac
                    quam. Vivamus eget semper ante. Sed posuere consectetur vestibulum. Donec eu tortor dictum,
                    interdum risus vel, finibus odio. Pellentesque tristique elit nisi, a consectetur urna varius
                    ac. Donec sollicitudin iaculis nunc non maximus.
                </p>
            </Grid>
           
        </Grid>
        )
    }
}

export default Introduction2;