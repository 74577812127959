import React from 'react';
import styled from '@emotion/styled';
import { Transition, animated } from 'react-spring';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Cloud from '../../../_metronic/layout/assets/Logo/cloud.png';
import '../../../_metronic/_assets/sass/pages/introduction/introduction.scss';
import CustomizedSteppers from './stepper';
import Stepper from '@material-ui/core/Stepper';
import StepLabel from '@material-ui/core/StepLabel';
import { toAbsoluteUrl } from '../../../_metronic/utils/utils';

import Step from '@material-ui/core/Step';

class Introduction extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			index: 0
		};
	}

	nextTab = (next) => {
		this.setState({ index: next ? this.state.index + 1 : this.state.index - 1 });
		if (this.state.index === 2 && next) {
			this.props.history.push('/explore');
		}
	};

	render() {
		const { index } = this.state;

		let pageOne = (
			<div>
				<h3> Welcome to Raspora </h3>
				<p style={{ columnCount: 2, marginTop: 20, marginBottom: 20 }}>
					Raspora is a platform to build graphs of knowledge by connecting and accumulating media sources and
					putting them into perspective to each other. Doing so is not trivial with the traditional social media
					paradigm that is based on social "communities". The Raspora platform to the contrary is build with financial micro
					incentives and hides intentionally the authors that add media content. That way, we build a
					productive environment that expands knowledge networks, and thus provides the readers valuable
					insights into complex topics.
				</p>
				<h4> Research </h4>
				<p style={{ columnCount: 2, marginTop: 20, marginBottom: 20 }}>
					As the potential and possibilities of micro incentive based value creation in that form is rather
					unexplored, we will try and test a variety of incentive combinations over the next couple of weeks
					and publish our results. The publication solely related to the game theoretical model and no user
					information will be shared in that process.
				</p>
			</div>
		);

		let pageTwo = (
			<div style={{columnCount: 2, }}>
				<p style={{ marginBottom: 20 }}>
					The Raspora platform is build ontop of the HIVE protocol. The bitcoin sv native protocol enable multiples parties
					to contribute and connect media content to broaden the understanding of differing perspectives
					related to each topic. Each contribution is not only visible on the Raspora platform but also shared
					publicly on the bitcoin sv blockchain.
				</p>
				<h4> Nodes </h4>
				<p style={{  marginTop: 20, marginBottom: 20 }}>
					Nodes represent the many media files, videos, websites and blocks that users contribute to each hive
					network. Each node needs to either be connected to a Hive topic, or to another node that is related
					to the posted content. Each node post costs an estimated 2 cents which partially go to the node
					connected to, their direct neighbours and as fee to store that content on the blockchain. The fee for each node not only rewards those nodes you connect to,
          but also triggers a pulse incentivises future users to expand the knowledge you contributed. Please ensure that your nodes don't infringe 3rd party rights since
          nodes remain forever on the blockchain.
				</p>
				<h4> Likes </h4>
				<p style={{  marginTop: 20, marginBottom: 20 }}>
					Likes incentivize content creators to post relevant nodes to each Hive by paying them and their surrounding nodes in total 1 cent. In return, Raspora will
					inform you whenever another node is connected to the content you liked.
				</p>
			</div>
		);

		let pageThree = (
			<div>
				<h4> The Bitcoin Blockchain </h4>
				<p style={{ columnCount: 2 }}>
					All hives, nodes, likes and pulses are archieved on the Bitcoin SV protocol in the open source Hive
					protocol format. Comments are excluded to allow free expressions without concerns of unintentional
					consequences that immutable data might bring with it. Using the open protocol, we ensure that all connections created
          and knowledge added to the network remains a public property and can be fetched and integrated into a variety of platforms.
          <br/> 
          After the beta phase, each registered and email confirmed account will receive $0.10 to be able to create media nodes and thus being able to participate in our
          micro-transaction ecosystem. All wallet balances can be withdrawn at any time in the profile page. 
          <br/> <br/> 
          Attention: Please ensure to only hold micro bitcoin amounts in this beta test since breaking
          changes will likely be added the next weeks.
				</p>
	
			</div>
		);

		let imgOne = <div style={{backgroundColor: 'black', height: '100%'}}> <img className="fadeIn" style={{ width: '100%' }} src={toAbsoluteUrl('/media/images/crane.jpg')} alt="" /> </div>;
		let imgTwo = <div style={{backgroundColor: 'black', height: '100%'}}><img className="fadeIn" style={{ width: '100%' }} src={toAbsoluteUrl('/media/images/node_man.jpg')} alt="" /> </div>;
		let imgThree = <div style={{backgroundColor: 'black', height: '100%'}}> <img className="fadeIn" style={{ width: '100%' }} src={toAbsoluteUrl('/media/images/chain.svg')} alt="" /> </div>;

		return (
			<div style={{ margin: 'auto' }}>
				<div style={{ backgroundColor: 'white', width: 1200, height: 600 }}>
					<Grid style={{ width: '100%', height: '100%' }} spacing={0} container direction="row">
						<Grid item style={{ width: '30%', height: '100%', backgroundColor: '#111021' }}>
								{this.state.index === 0 && imgOne}
								{this.state.index === 1 && imgTwo}
								{this.state.index === 2 && imgThree}
						</Grid>
						<Grid item style={{ width: '60%', paddingLeft: 100, paddingTop: 20 }}>
							<div style={{ height: '90%' }}>
								<Stepper activeStep={this.state.index} alternativeLabel>
									<Step key={0}>
										<StepLabel>Raspora</StepLabel>
									</Step>
									<Step key={1}>
										<StepLabel>Hive</StepLabel>
									</Step>
									<Step key={2}>
										<StepLabel>Bitcoin</StepLabel>
									</Step>
								</Stepper>
								{this.state.index === 0 && pageOne}
								{this.state.index === 1 && pageTwo}
								{this.state.index === 2 && pageThree}
							</div>
							<div style={{ float: 'right', position: 'relative', bottom: 10 }}>
								<Button
									disabled={this.state.index === 0 ? true : false}
									onClick={() => this.nextTab(false)}
								>
									Back
								</Button>
								<Button variant="outlined" onClick={() => this.nextTab(true)}>
									Next
								</Button>
							</div>
						</Grid>
					</Grid>
				</div>
			</div>
		);
	}
}

export default Introduction;
