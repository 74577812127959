import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import * as routerHelpers from "../../router/RouterHelpers";

export const actionTypes = {
  Login: "[Login] Action",
  Logout: "[Logout] Action",
  Register: "[Register] Action",
  UserRequested: "[Request User] Action",
  UserLoaded: "[Load User] Auth API",
  UserProilePicUpdated: "[User] PROFILE PIC UPDATE",
  chimeMeeting: "[] chimeMeeting",
  updateTutorStatus: "[] updateTutorStatus",
  userStatus: "[] userStatus",
  isTutorConnectedToMeeting: "[] isTutorConnectedToMeeting",
};

const initialAuthState = {
  user: undefined,
  authToken: undefined,
  updateProfilePic: false,
  profilePicUrl: "",
  chimeMeeting: {
    status: "null",
    studentID: "",
    tutorID: "",
  },
  updateTutorStatus: false,
  isUserLogin: false,
  isTutorConnectedToMeeting: false,
  meetingID: "",
  attendiID: "",
};

export const reducer = persistReducer(
  { storage, key: "demo2-auth", whitelist: ["user", "authToken"] },
  (state = initialAuthState, action) => {
    switch (action.type) {
      case actionTypes.Login: {
        const { authToken } = action.payload;

        return { authToken, user: undefined };
      }

      case actionTypes.Register: {
        const { authToken } = action.payload;

        return { authToken, user: undefined };
      }

      case actionTypes.Logout: {
        routerHelpers.forgotLastLocation();
        return initialAuthState;
      }

      case actionTypes.UserLoaded: {
        const { user } = action.payload;

        return { ...state, user };
      }

      case actionTypes.UserProilePicUpdated: {
        return {
          ...state,
          updateProfilePic: action.payload.type,
          profilePicUrl: action.payload.url,
        };
      }

      case actionTypes.chimeMeeting: {
        return { ...state, chimeMeeting: action.payload };
      }

      case actionTypes.updateTutorStatus: {
        return { ...state, updateTutorStatus: !state.updateTutorStatus };
      }

      case actionTypes.userStatus: {
        return { ...state, isUserLogin: action.payload };
      }

      case actionTypes.isTutorConnectedToMeeting: {
        return {
          ...state,
          isTutorConnectedToMeeting: action.payload,
          meetingID: action.meetingID,
          attendiID: action.attendiID,
        };
      }

      default:
        return state;
    }
  }
);

export const actions = {
  login: (authToken) => ({ type: actionTypes.Login, payload: { authToken } }),
  register: (authToken) => ({
    type: actionTypes.Register,
    payload: { authToken },
  }),
  logout: () => ({ type: actionTypes.Logout }),
  requestUser: (user) => ({
    type: actionTypes.UserRequested,
    payload: { user },
  }),
  fulfillUser: (user) => ({ type: actionTypes.UserLoaded, payload: { user } }),
};
export function updateProfilePicFunc(value) {
  return { type: actionTypes.UserProilePicUpdated, payload: value };
}

export function updateTutorStatusFunc(value) {
  return { type: actionTypes.updateTutorStatus };
}

export function updateChimeFunc(value) {
  return { type: actionTypes.chimeMeeting, payload: value };
}

export function updateUserStatus(value) {
  return { type: actionTypes.userStatus, payload: value };
}

export function updateIsTutorConnectedToMeeting(value) {
  return {
    type: actionTypes.isTutorConnectedToMeeting,
    payload: value.val,
    meetingID: value.meetingID,
    attendiID: value.attendiID,
  };
}

export function* saga() {}
