import React from "react";

import Paper from "@material-ui/core/Paper";
import Skeleton from "@material-ui/lab/Skeleton";
import { Link } from "react-router-dom";

import BlurOnIcon from "@material-ui/icons/BlurOn";
import ThumbUpAltSharpIcon from "@material-ui/icons/ThumbUpAltSharp";
import Checkbox from "@material-ui/core/Checkbox";
import LockOpenSharp from "@material-ui/icons/LockOpenSharp";
import BubbleChart from "@material-ui/icons/BubbleChart";

import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";

import Grid from "@material-ui/core/Grid";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { withStyles } from "@material-ui/core/styles";

const styles = {
  checkIconDisabled: {
    color: "grey !important",
  },
};

class HiveCard extends React.Component {

  render() {
    const { classes } = this.props;

    const loadingCards = (
      <Grid
        container
        spacing={0}
        style={{
          width: "100%",
          backgroundColor: "#FFFFFF",
          borderRadius: "1px !important",
          height:"100%",
        }}
        key={"Card_" + this.props.id}
      >
        <Grid item sm={6}>
          <Skeleton animation="wave" variant="rect" style={{ height: "100%" }} />
        </Grid>
        <Grid item sm={6}>
          <Paper elevation={1} style={{ margin: 4, height: 134 }}>
            <CardHeader title={"..."} />
            <CardContent style={{ paddingTop: 0 }}>
              <Typography variant="body2" color="textSecondary" component="p">
                <Skeleton animation="wave" height={12} />
                <Skeleton animation="wave" height={12} />
                <Skeleton animation="wave" height={12} />
              </Typography>
            </CardContent>
          </Paper>
          <Grid container spacing={0} style={{ marginTop: -2, paddingRight: "4px" }}>
            <Grid item sm={6}>
              <Paper elevation={1} style={{ height: 50, margin: 4, marginRight: 0, paddingTop: 5 }}>
                <Checkbox icon={<BlurOnIcon />} />
              </Paper>
              <Paper elevation={3} style={{ height: 50, margin: 4, marginRight: 0, paddingTop: 5 }}>
                <Checkbox icon={<ThumbUpAltSharpIcon />} />
              </Paper>
            </Grid>
            <Grid item sm={6}>
              <Paper elevation={1} style={{ height: 50, margin: 4, marginRight: 0, paddingTop: 5 }}>
                <Checkbox icon={<LockOpenSharp />} />
              </Paper>
              <Paper elevation={3} style={{ height: 50, margin: 4, marginRight: 0, paddingTop: 5 }}>
                <Checkbox icon={<BubbleChart />} />
              </Paper>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );

    const hiveCards = (
      <Grid
        container
        spacing={0}
        style={{
          width: "100%",
          backgroundColor: "#FFFFFF",
          borderRadius: "1px !important",
          height:"254"
        }}
        key={"Card_" + this.props.id}
      >
        <Grid item sm={6}>
          <Link to={"/hive/" + this.props.id}>
            <LazyLoadImage
              height={"100%"}
              style={{ objectFit: "cover" }}
              width={"100%"}
              src={this.props.image}
              effect="opacity"
              className="fadeIn"
              delayTime={500}
            />
          </Link>
        </Grid>

        <Grid item sm={6} style={{height: "100%"}}>
          <Paper elevation={1} style={{ margin: 4, height: 134 }}>
            <CardHeader title={this.props.title} />
            <CardContent style={{ paddingTop: 0 }}>
              <Typography variant="body2" color="textSecondary" component="p">
                {this.props.description}
              </Typography>
            </CardContent>
          </Paper>
          <Grid container spacing={0} style={{ marginTop: -2, paddingRight: "4px" }}>
            <Grid item xs={6}>
              <Paper elevation={1} style={{ height: 50, margin: 4, marginRight: 0, paddingTop: 5 }}>
                <Checkbox
                  disabled
                  icon={<BlurOnIcon />}
                  checkedIcon={<BlurOnIcon />}
                  classes={{ disabled: classes.checkIconDisabled }}
                />
                {this.props.nNodes}
              </Paper>
              <Paper elevation={3} style={{ height: 50, margin: 4, marginRight: 0, paddingTop: 5 }}>
                <Checkbox
                  disabled
                  icon={<ThumbUpAltSharpIcon />}
                  checkedIcon={<ThumbUpAltSharpIcon />}
                  classes={{ disabled: classes.checkIconDisabled }}
                />
                {this.props.nLikes}
              </Paper>
            </Grid>
            <Grid item xs={6}>
              <Paper elevation={1} style={{ height: 50, margin: 4, marginRight: 0, paddingTop: 5 }}>
                <Checkbox
                  disabled
                  icon={<LockOpenSharp />}
                  checkedIcon={<LockOpenSharp />}
                  classes={{ disabled: classes.checkIconDisabled }}
                />
                {this.props.nOnchained}
              </Paper>
              <Paper elevation={3} style={{ height: 50, margin: 4, marginRight: 0, paddingTop: 5 }}>
                <Checkbox
                  disabled
                  icon={<BubbleChart />}
                  checkedIcon={<BubbleChart />}
                  classes={{ disabled: classes.checkIconDisabled }}
                />
                {this.props.nPulses}
              </Paper>
            </Grid>
          </Grid>
        </Grid>
    
      </Grid>
    );

    const showCards = this.props.loading ? loadingCards : hiveCards;

    return <div>{showCards}</div>;
  }
}

export default withStyles(styles)(HiveCard);
