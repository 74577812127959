import React from "react";
import Button from "@material-ui/core/Button";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Typography from "@material-ui/core/Typography";
import Skeleton from "@material-ui/lab/Skeleton";
import TextField from "@material-ui/core/TextField";
import Slider from "@material-ui/core/Slider";
import Snackbar from "@material-ui/core/Snackbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import ReplySection from "./ReplySection";
import moment from "moment";
import SimpleBar from "simplebar-react";
import "simplebar/dist/simplebar.min.css";
import Grid from "@material-ui/core/Grid";
import PostImage from "./postImage";
import SecurityIcon from "@material-ui/icons/Security";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import ExpandMore from "@material-ui/icons/ExpandMore";
import IndeterminateCheckBoxSharpIcon from "@material-ui/icons/IndeterminateCheckBoxSharp";
import ExpandLess from "@material-ui/icons/ExpandLess";
import AddBoxSharp from "@material-ui/icons/AddBoxSharp";
import { withSnackbar } from "notistack";
import CircularProgress from "@material-ui/core/CircularProgress";

const firebase = require("firebase");
let bsv = require("bsv");

class RightCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      displayType: "",
      showIndex: false,
      numberPulses: 26,
      moneyPulse: 3,
      pulseFee: 0.01,
      archiveAmount: 0.43,
      amount: 0,
      snackMessage: "",
      snackOpen: false,
      reply: "",
      comment: "",
      commentId: "",
      voteCount: 0,
      showPulseLoader: false,
    };
  }
  createComment(type) {
    let user = firebase.auth().currentUser;
    const { id, hiveID, handleDisplay, hdPublicKey } = this.props;
    const { comment, reply, commentId } = this.state;

    let nodeComment = {
      userID: user.uid,
      userName: user.displayName,
      nodeID: id,
      hiveID: hiveID,
      timeStamp: firebase.database.ServerValue.TIMESTAMP,
      hdPublicKey: hdPublicKey,
      voteCount: 0,
    };

    let createNodesPath;

    if (type === "reply") {
      nodeComment["replyText"] = reply;
      createNodesPath = "nodes/" + id + "/comments" + commentId + "/reply";
    } else {
      createNodesPath = "nodes/" + id + "/comments";
      nodeComment["comment"] = comment;
    }

    let newPostKey = firebase
      .database()
      .ref(createNodesPath)
      .push().key;

    let hiveCardsPath;

    type === "reply"
      ? (hiveCardsPath = "hiveCards/" + hiveID + "/node/" + id + "/comments/" + commentId + "/reply/" + newPostKey)
      : (hiveCardsPath = "hiveCards/" + hiveID + "/node/" + id + "/comments/" + newPostKey);

    let commentContent = {};
    commentContent[hiveCardsPath] = nodeComment;
    firebase
      .database()
      .ref()
      .update(commentContent);

    let snackMessage;
    type === "reply" ? (snackMessage = "Reply submitted") : (snackMessage = "Comment submitted");

    this.setState({ snackMessage, snackOpen: true });
    handleDisplay("comments");
    if (type === "reply") {
      this.setState({ reply: "", displayType: "" });
    }
  }

  updateVoteScore = (commentId, like, hasVote, isUpvote, replyId) => {
    let vote;
    hasVote ? (isUpvote ? (vote = -1) : (vote = 1)) : (vote = 0);
    const { id, hiveID } = this.props;
    let voteCount;
    let path;
    if (replyId) {
      voteCount = (this.props.comments[commentId].reply[replyId].voteCount || 0) + (like ? vote + 1 : vote - 1);
      path = "hiveCards/" + hiveID + "/node/" + id + "/comments/" + commentId + "/reply/" + replyId;
    } else {
      voteCount = (this.props.comments[commentId].voteCount || 0) + (like ? vote + 1 : vote - 1);
      path = "hiveCards/" + hiveID + "/node/" + id + "/comments/" + commentId;
    }
    let voteContent = {
      voteCount: voteCount,
    };
    firebase
      .database()
      .ref(path)
      .update(voteContent);
  };

  createNewVoteId = (replyId, hiveID, id, commentId) => {
    let createNodesPath;
    replyId ? (createNodesPath = "nodes/" + id + "/comments/" + commentId + "/reply/" + replyId + "/vote") : (createNodesPath = "nodes/" + id + "/comments" + commentId + "/vote");
    return firebase
      .database()
      .ref(createNodesPath)
      .push().key;
  };

  onVote = (commentId, voteId, like, replyId, hasVote, isUpvote) => {
    const { user, id, hiveID } = this.props;

    if (!voteId) {
      voteId = this.createNewVoteId(replyId, hiveID, id, commentId);
    }

    let hiveCardsPath;
    replyId
      ? (hiveCardsPath = "hiveCards/" + hiveID + "/node/" + id + "/comments/" + commentId + "/reply/" + replyId + "/vote/" + voteId)
      : (hiveCardsPath = "hiveCards/" + hiveID + "/node/" + id + "/comments/" + commentId + "/vote/" + voteId);

    let vote = {};
    vote[hiveCardsPath] = {
      userID: user.uid,
      timeStamp: firebase.database.ServerValue.TIMESTAMP,
      upvote: like ? true : false,
    };
    firebase
      .database()
      .ref()
      .update(vote);
    this.updateVoteScore(commentId, like, hasVote, isUpvote, replyId);
  };

  replyFocus = (id) => {
    const { handleDisplay } = this.props;
    handleDisplay("write");
    this.setState({ displayType: "reply", commentId: id });
  };

  moderate = (data, commentId) => {
    let contents = {};
    contents["hiveCards/" + data.hiveID + "/node/" + data.nodeID + "/comments/" + commentId + "/moderate"] = !data.moderate;
    firebase
      .database()
      .ref()
      .update(contents);
  };

  //   componentDidUpdate(prevProps){
  // if(prevProps.){}
  //   }

  renderCommentView = (item, commentId, voteCount) => {
    // if (item.reply && !this.state.showIndex) {
    //   this.setState({ showIndex: commentId });
    // }
    const { user } = this.props;
    if (!user) {
      return;
    }
    const userId = user.uid;
    let hasVote,
      isUpvote,
      voteUserId,
      voteId = false;
    if (item.hasOwnProperty("vote")) {
      Object.keys(item.vote).forEach((key) => {
        voteUserId = item.vote[key].userID;
        if (userId === voteUserId) {
          hasVote = true;
          voteId = key;
          isUpvote = item.vote[key].upvote;
        }
      });
    }
    return (
      <div style={{ flexDirection: "row" }}>
        <VoteComponent
          voteCount={voteCount}
          hasVote={hasVote}
          isUpvote={isUpvote}
          user={this.props.user}
          hiveID={this.props.hiveID}
          id={this.props.id}
          commentId={commentId}
          voteId={voteId}
          comments={this.props.comments}
          onVote={this.onVote}
        />
        {/* {hasVote && isUpvote ? (
          <AddBoxSharp onClick={() => this.onVote(commentId, voteId, true, false, hasVote, isUpvote)} />
        ) : (
          <ExpandLess style={{ pointer: "cursor" }} onClick={() => this.onVote(commentId, voteId, true, false, hasVote, isUpvote)} />
        )}
        <span> {voteCount} </span>
        {hasVote && !isUpvote ? (
          <IndeterminateCheckBoxSharpIcon onClick={() => this.onVote(commentId, voteId, false, false, hasVote, isUpvote)} />
        ) : (
          <ExpandMore style={{ pointer: "cursor" }} onClick={() => this.onVote(commentId, voteId, false, false, hasVote, isUpvote)} />
        )} */}
        <Button onClick={() => this.replyFocus(commentId)}> Reply </Button>
        {/* //moderate */}
        {this.props.canModerate && (
          <FormControlLabel
            control={
              <Checkbox
                icon={<SecurityIcon color={item.moderate === true ? "secondary" : "inherit"} />}
                checkedIcon={<SecurityIcon color="primary" />}
                checked={false}
                value="checkedH"
                onClick={() => this.moderate(item, commentId)}
              />
            }
          />
        )}
      </div>
    );
  };

  sortByVotes = (comments, isReply) => {
    return Object.keys(comments)
      .sort((a, b) => {
        return comments[b].voteCount - comments[a].voteCount;
      })
      .map((sortedKey) => {
        isReply ? (comments[sortedKey].replyId = sortedKey) : (comments[sortedKey].commentId = sortedKey);
        return comments[sortedKey];
      });
  };

  getAddrFromPubKey(hdPublicKey) {
    let hdPK = bsv.HDPublicKey.fromString(hdPublicKey);
    let publicKey = hdPK.deriveChild("m/1");
    let address = bsv.Address.fromPublicKey(publicKey.publicKey).toString();
    return address;
  }

  handelReleasePulse = async () => {
    this.setState({ showPulseLoader: true });
    //checking user account
    let getMetrices = firebase.functions().httpsCallable("getMetrices");
    let userWalletInfo = await getMetrices();
    //fetching price data
    let bsvPrice = await firebase
      .database()
      .ref("stats/market_price_usd")
      .once("value")
      .then((snapshot) => {
        return snapshot.val();
      });
    let userWalletBalanceInDollar = ((userWalletInfo.data.metrices.balance * bsvPrice) / 100000000).toFixed(2);
    let pulseCost = this.state.moneyPulse * this.state.numberPulses;
    console.log("userWalletInfo", pulseCost, userWalletBalanceInDollar);

    if (pulseCost === 0) {
      this.props.enqueueSnackbar("Please select a valid pulse amount!", { variant: "error" });
      this.setState({ showPulseLoader: false });
    } else if (userWalletBalanceInDollar > pulseCost) {
      //pushing amount to m/45
      // let tx = [];
      // let activeNode = this.props.hiveNodes.filter((x) => x.nodeID === this.props.id);
      // let l2LinkNode = this.props.hiveNodes.filter((x) => activeNode[0] && activeNode[0].linkedNode && x.nodeID === activeNode[0].linkedNode);
      // activeNode.map((x) => tx.push({ address: this.getAddrFromPubKey(x.hdPublicKey), value: 1400 }));
      // l2LinkNode.map((x) => tx.push({ address: this.getAddrFromPubKey(x.hdPublicKey), value: 1000 }));

      // this.props.hiveNodes
      //   .filter((x) => l2LinkNode[0] && l2LinkNode[0].linkedNode && x.nodeID === l2LinkNode[0].linkedNode)
      //   .map((x) =>
      //     tx.push({
      //       address: this.getAddrFromPubKey(x.hdPublicKey),
      //       value: 800,
      //     })
      //   );
      // this.props.hiveNodes
      //   .filter((x) => x.linkedNode === this.props.id)
      //   .map((x) =>
      //     tx.push({
      //       address: this.getAddrFromPubKey(x.hdPublicKey),
      //       value: 600,
      //     })
      //   );

      //pushing data to database

      this.props.handleDisplay("comments");

      let user = firebase.auth().currentUser;
      let newPulseKey = firebase
        .database()
        .ref("pulses")
        .push().key;
      let updates = {};
      let pulseData = {
        total: this.state.numberPulses,
        remaining: this.state.numberPulses,
        value: this.state.moneyPulse,
        pulseValue: this.state.moneyPulse / this.state.numberPulses,
        active: true,
        nodeID: this.props.id,
        hiveID: this.props.hiveID,
        userID: user.uid,
        nodeCreatorUserID: this.props.nodeCreatorUserID,
        pulseId: newPulseKey,
      };
      let pulseActivity = {
        timeStamp: firebase.database.ServerValue.TIMESTAMP,
        type: "pulse",
        pulseId: newPulseKey,
        hiveID: this.props.hiveID,
        nodeID: this.props.id,
      };
      let pulseInHivecrds = {
        timeStamp: firebase.database.ServerValue.TIMESTAMP,
        pulseId: newPulseKey,
        hiveID: this.props.hiveID,
        nodeID: this.props.id,
        userID: user.uid,
        active: true,
      };
      updates["/pulses/" + newPulseKey] = pulseData;
      updates["/activities/" + user.uid + "/" + newPulseKey] = pulseActivity;
      updates["/hiveCards/" + this.props.hiveID + "/node/" + this.props.id + "/pulses/" + newPulseKey] = pulseInHivecrds;

      firebase
        .database()
        .ref()
        .update(updates);

      this.props.enqueueSnackbar(this.state.numberPulses + " tx Pulse Unleashed!", { variant: "success" });

      this.setState({
        pulse: true,
        // snackMessage: this.state.numberPulses + " tx Pulse Unleashed!",
        // snackOpen: true,
        showPulseLoader: false,
        amount: parseFloat(this.state.moneyPulse) + parseFloat(this.state.numberPulses) * this.state.pulseFee,
      });
    } else {
      this.props.enqueueSnackbar("You didn't have enough balance to do this transction", { variant: "error" });
      this.setState({ showPulseLoader: false });
    }
  };

  updateOnchain = async () => {
    //getting Type
    // let hiveType = await firebase
    //   .database()
    //   .ref("/hiveCards/" + this.props.hiveID + "/type")
    //   .once("value")
    //   .then((a) => a.val());
    // //updating pulses
    // if (hiveType === "Open") {
    //   firebase
    //     .database()
    //     .ref("hives/" + this.props.hiveID)
    //     .child("nOnchained")
    //     .transaction((onchain) => {
    //       console.log("onchain", onchain);
    //       return (onchain || 0) + 1;
    //     });
    // }
    // firebase
    //   .database()
    //   .ref("hiveCards/" + this.props.hiveID)
    //   .child("nOnchained")
    //   .transaction((onchain) => {
    //     console.log("onchain", onchain);
    //     return (onchain || 0) + 1;
    //   });

    //updating DB
    if (!this.props.archived) {
      firebase.auth().onAuthStateChanged((user) => {
        let contents = {};
        contents["/hiveCards/" + this.props.hiveID + "/node/" + this.props.id + "/archived"] = true;
        contents["/archiveCheck/" + this.props.id] = {
          nodeID: this.props.id,
          hiveID: this.props.hiveID,
          timeStamp: firebase.database.ServerValue.TIMESTAMP,
          userID: user.uid,
          url: this.props.link,
        };

        firebase
          .database()
          .ref()
          .update(contents);
      });
    }
  };

  render() {
    const { displayType, showIndex, numberPulses, moneyPulse, pulseFee } = this.state;
    const { comments, id, hiveID, user, handleDisplay, nodeComment, image, archiveAmount, display } = this.props;
    let commentsSorted = this.sortByVotes(comments);
    const commentItem = commentsSorted.map((comment, index) => (
      <>
        {(() => {
          if (this.props.canModerate) {
            return (
              <ListItem alignItems="flex-start" key={index}>
                <PostImage voteCount={comment.voteCount} userID={comment.userID} />
                <ListItemText
                  primary={
                    <React.Fragment>
                      <Typography component="span" variant="body2" style={{ display: "inline", color: "black" }} color="textPrimary">
                        <Grid container direction="row" style={{ width: "350px" }}>
                          <div style={{ marginRight: 10 }}>{comment.userName ? comment.userName : "placeholder"}</div>
                          <p style={{ fontSize: 8, marginTop: 3 }}>{moment(comment.timeStamp).fromNow()}</p>
                        </Grid>
                      </Typography>
                      <p style={{ color: "black" }}>{comment.comment}</p>

                      {this.renderCommentView(comment, comment.commentId, comment.voteCount)}
                      {/* {showIndex === comment.commentId ? ( */}
                      {comment && comment.reply && !comment.moderate ? (
                        <ReplySection
                          canModerate={this.props.canModerate}
                          sortByVotes={this.sortByVotes}
                          onVote={this.onVote}
                          id={id}
                          hiveID={hiveID}
                          user={user}
                          comment={comment}
                          commentId={comment.commentId}
                        />
                      ) : null}
                    </React.Fragment>
                  }
                />
              </ListItem>
            );
          } else if (comment.moderate !== true) {
            return (
              <ListItem alignItems="flex-start" key={index}>
                <PostImage voteCount={comment.voteCount} userID={comment.userID} />
                <ListItemText
                  primary={
                    <React.Fragment>
                      <Typography component="span" variant="body2" style={{ display: "inline", color: "black" }} color="textPrimary">
                        <Grid container direction="row" style={{ width: "350px" }}>
                          <div style={{ marginRight: 10 }}>{comment.userName ? comment.userName : "placeholder"}</div>
                          <p style={{ fontSize: 8, marginTop: 3 }}>{moment(comment.timeStamp).fromNow()}</p>
                        </Grid>
                      </Typography>
                      <p style={{ color: "black" }}>{comment.comment}</p>

                      {this.renderCommentView(comment, comment.commentId, comment.voteCount)}
                      {/* {showIndex === comment.commentId ? ( */}
                      {comment && comment.reply && !comment.moderate ? (
                        <ReplySection
                          canModerate={this.props.canModerate}
                          sortByVotes={this.sortByVotes}
                          onVote={this.onVote}
                          id={id}
                          hiveID={hiveID}
                          user={user}
                          comment={comment}
                          commentId={comment.commentId}
                        />
                      ) : null}
                    </React.Fragment>
                  }
                />
              </ListItem>
            );
          }
        })()}
      </>
    ));

    const commentsList = (
      <List
        style={{
          width: "100%",
          paddingRight: 20,
          paddingLeft: 5,
          paddingBottom: 0,
          paddingTop: 0,
        }}
      >
        {commentItem}
      </List>
    );

    const commentsArea =
      nodeComment !== false ? (
        <div>
          <SimpleBar style={{ height: "285px" }} direction={"ltr"}>
            {commentsList}
          </SimpleBar>
          <div style={{ textAlign: "right", marginBottom: 0 }}>
            <Button
              onClick={() => {
                handleDisplay("write");
                this.setState({ displayType: "comment" });
              }}
            >
              Comment
            </Button>
          </div>
        </div>
      ) : (
        <div>
          <div style={{ textAlign: "right", marginBottom: 0 }}>
            <Button
              onClick={() => {
                handleDisplay("write");
                this.setState({ displayType: "comment" });
              }}
            >
              Comment
            </Button>
          </div>
        </div>
      );

    let pulse = (
      <div
        style={{
          textAlign: "center",
          padding: 10,
          paddingRight: 40,
          paddingLeft: 40,
        }}
      >
        <Typography style={{ textAlign: "left" }} id="discrete-slider" gutterBottom>
          Number of Pulses
        </Typography>
        <Slider
          value={numberPulses}
          min={5}
          step={1}
          max={100}
          onChange={(x, y) => this.setState({ numberPulses: y })}
          valueLabelDisplay="auto"
          aria-labelledby="non-linear-slider"
        />
        <Typography style={{ textAlign: "left" }} id="discrete-slider" gutterBottom>
          Amount (in $)
        </Typography>

        <Slider value={moneyPulse} min={0} step={0.5} max={15} onChange={(x, y) => this.setState({ moneyPulse: y })} valueLabelDisplay="auto" aria-labelledby="non-linear-slider" />
        <TextField style={{ marginTop: 7 }} label="Description (optional)" variant="outlined" fullWidth id="mui-theme-provider-outlined-input" />
        <div style={{ marginTop: 20 }}>
          <Button
            variant="outlined"
            color="primary"
            style={{
              marginRight: 4,
              marginTop: 1,
              padding: 15,
              pointerEvents: "none",
            }}
          >
            {(moneyPulse + numberPulses * pulseFee).toFixed(2)}$
          </Button>
          <Button variant="contained" disabled color="secondary" onClick={() => console.log("this.handelReleasePulse")} style={{ padding: 15, width: 135, height: "100%" }}>
            {!this.state.showPulseLoader ? "Release Pulse [inactive]" : <CircularProgress size={20} color="#ffffff" />}
          </Button>

          <Button
            variant="outlined"
            color="primary"
            onClick={() => {
              handleDisplay("comments");
              this.setState({ pulse: false });
            }}
            style={{ marginLeft: 4, marginTop: 1, padding: 15 }}
          >
            X
          </Button>
        </div>
      </div>
    );

    let onchain = (
      <div style={{ textAlign: "center", padding: 10 }}>
        <Typography id="discrete-slider" gutterBottom>
          Archive Link onChain
        </Typography>
        <img src={image} style={{ maxWidth: "100%", height: 150 }} alt="" />
        <div style={{ marginTop: 21 }}>
          <a href={"https://etched.page/?url=" + this.props.link} target="_blank">
            <Button
              variant="contained"
              color="secondary"
              onClick={() => {
                handleDisplay("comments");
                this.setState({
                  archived: true,
                  snackMessage: "Webpage Archived on Bitcoin!",
                  snackOpen: true,
                  amount: archiveAmount,
                });

                //updating onchain
                this.updateOnchain();
              }}
              style={{ padding: 15, width: 165 }}
            >
              Archive Webpage
            </Button>
          </a>
          <Button
            variant="outlined"
            color="primary"
            onClick={() => {
              handleDisplay("comments");
              this.setState({ archived: false });
            }}
            style={{ marginLeft: 4, marginTop: 1, padding: 15 }}
          >
            X
          </Button>
        </div>
      </div>
    );

    let loadingDisplay = (
      <div style={{ textAlign: "center", padding: 10, marginTop: 10 }}>
        <Skeleton width="90%" />
        <Skeleton width="90%" animation={false} />
        <Skeleton width="90%" animation="wave" />
        <br /> <br />
        <Skeleton width="90%" />
        <Skeleton width="90%" animation={false} />
        <Skeleton width="90%" animation="wave" />
      </div>
    );

    let write = (
      <div style={{ textAlign: "center", padding: 10 }}>
        {displayType === "comment" ? (
          <TextField
            style={{ marginTop: 7 }}
            inputProps={{
              minLength: 5,
              maxLength: 800,
            }}
            label={"Comment"}
            variant="outlined"
            onChange={(x) => this.setState({ comment: x.target.value })}
            fullWidth
            multiline
            rows="8"
            id="mui-theme-provider-outlined-input"
            autoFocus
          />
        ) : (
          <TextField
            style={{ marginTop: 7 }}
            inputProps={{
              minLength: 5,
              maxLength: 800,
            }}
            label={"Reply"}
            variant="outlined"
            onChange={(x) => this.setState({ reply: x.target.value })}
            fullWidth
            multiline
            rows="8"
            id="mui-theme-provider-outlined-input"
            autoFocus
          />
        )}
        <Button
          variant="contained"
          onClick={() => {
            handleDisplay("comments");
            this.setState({ displayType: "" });
          }}
          style={{
            margin: 20,
            height: 48,
            marginRight: 2,
            padding: 15,
            backgroundColor: "#F88379",
          }}
        >
          <CloseIcon fontSize="small" />
        </Button>
        <Button
          variant="contained"
          color="secondary"
          onClick={() => this.createComment(displayType === "comment" ? "comment" : "reply")}
          style={{ margin: 20, marginLeft: 2, padding: 15 }}
        >
          {displayType}
        </Button>
      </div>
    );

    let content;
    switch (display) {
      case "loading":
        content = loadingDisplay;
        break;
      case "comments":
        content = commentsArea;
        break;
      case "write":
        content = write;
        break;
      case "pulse":
        content = pulse;
        break;
      case "onchain":
        content = onchain;
        break;
      default:
        return <></>;
    }

    return (
      <div>
        <Snackbar
          anchorOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          open={this.state.snackOpen}
          autoHideDuration={5000}
          onClose={() => this.setState({ snackOpen: false })}
          message={this.state.snackMessage}
          action={
            <React.Fragment>
              <Button style={{ color: "red" }} size="medium">
                {/* {this.state.amount.toFixed(2)}$ */}
              </Button>
              <IconButton size="small" aria-label="close" color="inherit" onClick={() => this.setState({ snackOpen: false })}>
                <CloseIcon fontSize="small" />
              </IconButton>
            </React.Fragment>
          }
        />
        {content}
      </div>
    );
  }
}

function VoteComponent(props) {
  const [voteCount, setvoteCount] = React.useState(props.voteCount);
  const [hasVote, sethasVote] = React.useState(props.hasVote);
  const [isUpvote, setisUpvote] = React.useState(props.isUpvote);
  const [disableBtns, setdisableBtns] = React.useState(false);

  const updateLocalState = (commentId, voteId, like, replyId, hasVotein, isUpvotein) => {
    const { user, id, hiveID } = props;
    setdisableBtns(true);
    //update state

    let votecountlocal;
    hasVotein ? (isUpvotein ? (votecountlocal = -1) : (votecountlocal = 1)) : (votecountlocal = 0);
    let voteCountcheck;
    voteCountcheck = (voteCount || 0) + (like ? votecountlocal + 1 : votecountlocal - 1);
    setisUpvote(like);
    setvoteCount(voteCountcheck);
    !hasVote && sethasVote(true);
    setdisableBtns(false);
    //updating db
    setTimeout(() => {
      props.onVote(commentId, voteId, like, replyId, hasVotein, isUpvotein);
    }, 50);
  };

  return (
    <>
      {hasVote && isUpvote ? (
        <IconButton
          disabled={true}
          // onClick={() => {
          //   updateLocalState(props.commentId, props.voteId, true, false, hasVote, isUpvote);
          // }}
        >
          <AddBoxSharp />
        </IconButton>
      ) : (
        <IconButton
          disabled={disableBtns}
          style={{ cursor: "pointer" }}
          onClick={() => {
            updateLocalState(props.commentId, props.voteId, true, false, hasVote, isUpvote);
          }}
        >
          <ExpandLess />
        </IconButton>
      )}
      <div style={{ display: "inline-block", textAlign: "center", width: 10 }}> {voteCount} </div>
      {hasVote && !isUpvote ? (
        <IconButton
          disabled={true}
          // onClick={() => {
          //   updateLocalState(props.commentId, props.voteId, false, false, hasVote, isUpvote);
          // }}
        >
          <IndeterminateCheckBoxSharpIcon />
        </IconButton>
      ) : (
        <IconButton
          disabled={disableBtns}
          style={{ cursor: "pointer" }}
          onClick={() => {
            updateLocalState(props.commentId, props.voteId, false, false, hasVote, isUpvote);
          }}
        >
          <ExpandMore />
        </IconButton>
      )}
    </>
  );
}

export default withSnackbar(RightCard);
