/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from '@material-ui/core';

class GDPR extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			open: true
		};
	}

	render() {
		return (
			<div> 
			{!this.props.user &&
			this.state.open && (
			<div
				style={{ width: '100%', textAlign: 'left!important', backgroundColor: 'black', marginTop: 10, height: 125 }}
			>
				
					<div
						style={{
							fontSize: 12,
							padding: 10,
						}}
					>
						<span style={{ marginRight: 5, color: 'white', marginTop: 10, textAlign: 'left!important' }}>
							We use cookies to personalise content to provide social media features and analyse our
							traffic, read more about it in our <Link to="/terms">terms</Link>.
						</span>
						
						<br/>
						<Button
							variant="outlined"
							style={{ color: 'white', float: 'left', marginTop: 5 }}
							color="primary"
							onClick={() => this.setState({ open: false })}
						>
							{' '}
							
							Got it!{' '}
						</Button>
					</div>
			</div>
			)}
			</div>
		);
	}
}

export default GDPR;
