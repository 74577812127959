import React, { Suspense } from "react";
import { Redirect, Route, Switch } from "react-router-dom";

import { LayoutSplashScreen } from "../../../_metronic";
import AuthPage from "../auth/AuthPage.js";
import Introduction from "../Introduction/Introduction";

import About from "./About/About";
import Explore from "./Explore";
import Hive from "./Hive";
import HiveCreation from "./HiveCreation";
import Profile from "./Profile";
import Terms from "./Terms";
import ReportPage from "./ReportPage"

// const Hive = lazy(() => import('./Hive'));

export default function HomePage() {
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        {
          <Redirect exact from="/" to="/explore" />
        }
        <Route path="/auth" component={AuthPage} />
        <Route path="/explore" component={Explore} />
        <Route path="/terms" component={Terms} />
        <Route path="/about" component={About} />
        <Route path="/create" component={HiveCreation} />
        <Route path="/introduction" component={Introduction} />
        <Route path="/profile" component={Profile} />
        <Route path="/report" component={ReportPage} />
        <Route path="/hive/:hiveID" component={Hive} />
        <Redirect to="/error/error-v1" />
      </Switch>
    </Suspense>
  );
}
